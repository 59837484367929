/**
 * Copy text to clipboard.
 * @param text The text to copy
 * @param textAreaRef A ref to a textarea, e.g. `<textarea readOnly ref={copyTextAreaRef} hidden value={profileLink} />`
 * @param setSnackbar The callback to a setSnackbar function with the parameters (type: String, message: String)
 * @returns {Promise<void>}
 */

export const copyText = async (text, textAreaRef, setSnackbar, t) => {
  textAreaRef.current.select()
  if (navigator && navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text)
      setSnackbar('success', t('linkCopied'))
    } catch (err) {
      setSnackbar('error', 'Something went wrong: ' + err)
    }
  } else {
    if (document.execCommand('copy')) {
      setSnackbar('success', t('linkCopied'))
    } else {
      setSnackbar('error', 'Something went wrong')
    }
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function decapitalizeFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}
