import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, setModalPage } from '../../../actions/modals'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import apiClient from '../../../shared-components/utils/ApiClient'
import TicketStatsItem from '../../event/TicketStatsItem'
import { ArrowBack, ChevronLeft, Edit } from '@material-ui/icons'
import { setCurrentEvent, setLocBeforeEdit } from '../../../actions/event'
import GuestManagementQuestions from './GuestManagementQuestions'
import { guestManagementBreakpoint } from './GuestManagementModal'
import cn from 'clsx'
import GuestManagementScroller from './GuestManagementScroller'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 4),
    gap: theme.spacing(2),
    overflowY: 'auto',
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.gray[200],
  },
  primaryButton: {
    background: theme.palette.primary[100],
    color: theme.palette.primary[800],
    padding: theme.spacing(1, 2, 1, 1.5),
    borderRadius: 100,
    '&:hover': {
      background: theme.palette.primary[200],
    },
    alignSelf: 'flex-end',
  },
  primaryButtonLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  titleContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: 64,
  },
  titleBorder: {
    borderBottom: '1px solid ' + theme.palette.grey[200],
  },
  title: {
    padding: theme.spacing(0, 5),
  },
  closeButton: {
    color: theme.palette.text.primary,
    position: 'absolute',
    left: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  guestListHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: 64,
    flexShrink: 0,
    padding: theme.spacing(2, 4, 2, 4),
    [theme.breakpoints.down(guestManagementBreakpoint)]: {
      borderBottom: '1px solid ' + theme.palette.grey[200],
    },
  },
  backButton: {
    position: 'absolute',
    left: theme.spacing(3),
    top: '50%',
    transform: 'translateY(-50%)',
    minWidth: 0,
    borderRadius: 100,
    padding: 8,
    [theme.breakpoints.down(guestManagementBreakpoint)]: {
      left: theme.spacing(1),
    },
  },
  guestListRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const GuestManagementJoinOptions = ({}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(
    theme.breakpoints.down(guestManagementBreakpoint),
  )
  const router = useRouter()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState({})
  const [openQuestions, setOpenQuestions] = useState(null)
  const [openGuestList, setOpenGuestList] = useState(null)

  const loadStats = async () => {
    setLoading(true)
    const stats = await apiClient.event.getTicketStats(event.id)
    setStats(stats)
    setLoading(false)
  }

  useEffect(() => {
    if (!event.id) {
      return
    }
    open && loadStats()
  }, [event])

  const editClicked = () => {
    dispatch(closeModal('guestManagement'))
    dispatch(setLocBeforeEdit(router.asPath))
    dispatch(setCurrentEvent(null))
    router.push(`/edit/${event.code}`)
  }

  const onQuestionsClicked = (ticketOption) => {
    setOpenQuestions(ticketOption)
  }

  const onGuestListClicked = (ticketOption) => {
    setOpenGuestList(ticketOption)
  }

  const onBack = () => {
    dispatch(setModalPage('guestManagement', 'menu'))
  }

  if (!stats?.ticketOptions && loading)
    return (
      <>
        {isMobile && (
          <div className={cn(classes.titleContainer, classes.titleBorder)}>
            <IconButton
              color='inherit'
              className={classes.closeButton}
              onClick={onBack}
            >
              <ArrowBack />
            </IconButton>
            <Typography noWrap className={classes.title} variant='h6'>
              {t('guestManagement.joinOptions')}
            </Typography>
          </div>
        )}
        <div className={classes.root}>
          <CircularProgress />
        </div>
      </>
    )

  if (openQuestions != null) {
    return (
      <GuestManagementQuestions
        joinOption={openQuestions}
        onBack={() => setOpenQuestions(null)}
      />
    )
  }

  if (openGuestList != null) {
    return (
      <div className={classes.guestListRoot}>
        <div className={classes.guestListHeader}>
          <Button
            className={classes.backButton}
            onClick={() => setOpenGuestList(null)}
          >
            {!isMobile && <ChevronLeft />}
            {isMobile && <ArrowBack />}
          </Button>
          <Typography
            variant={isMobile ? 'h6' : 'body1'}
            style={{ fontWeight: 500, maxWidth: '80%' }}
            noWrap
          >
            {openGuestList.name === ''
              ? t('guestListTicket')
              : openGuestList.name}
          </Typography>
        </div>

        <GuestManagementScroller
          event={event}
          status={'JOINED'}
          tab={'JOINED'}
          sortType={'NAME'}
          joinOptionId={openGuestList.id}
        />
      </div>
    )
  }

  return (
    <>
      {isMobile && (
        <div className={cn(classes.titleContainer, classes.titleBorder)}>
          <IconButton
            color='inherit'
            className={classes.closeButton}
            onClick={onBack}
          >
            <ArrowBack />
          </IconButton>
          <Typography noWrap className={classes.title} variant='h6'>
            {t('guestManagement.joinOptions')}
          </Typography>
        </div>
      )}
      <div className={classes.root}>
        <Button
          classes={{
            label: classes.primaryButtonLabel,
          }}
          className={classes.primaryButton}
          onClick={editClicked}
        >
          <Edit />
          {t('guestManagement.editJoinOptions')}
        </Button>

        {stats.ticketOptions && (
          <>
            {stats.ticketOptions.map((ticketOption) => {
              return (
                <TicketStatsItem
                  key={ticketOption.id}
                  onQuestionsClicked={() => onQuestionsClicked(ticketOption)}
                  onGuestListClicked={() => onGuestListClicked(ticketOption)}
                  ticketOption={ticketOption}
                />
              )
            })}
          </>
        )}
      </div>
    </>
  )
}

export default GuestManagementJoinOptions
