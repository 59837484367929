import React, { useCallback, useEffect } from 'react'
import { useCurrentEvent } from 'utils/hooks'
import {
  useChainedCommands,
  useExtensionEvent,
  useRemirrorContext,
} from '@remirror/react'
import { extractHref, LinkExtension } from 'remirror/extensions'

// This component resets the content of a Remirror node everytime the descriptionMarkdown updates
// This is necessary, as otherwise the Remirror node isn't aware its content has changed and doesn't rerender
const EventDescriptionMarkdownUpdater = ({}) => {
  const { setContent } = useRemirrorContext()
  const chain = useChainedCommands()
  const event = useCurrentEvent()

  useExtensionEvent(
    LinkExtension,
    'onClick',
    useCallback((e, data) => {
      const ref = extractHref({
        url: data.href,
        defaultProtocol: 'https:',
      })
      window.open(ref, '_blank')
      return true
    }, []),
  )

  useEffect(() => {
    if (event.descriptionMarkdown && event.descriptionMarkdown.length > 0) {
      // Clear description and set content to markdown

      setContent({
        type: 'doc',
        content: [],
      })
      chain.insertMarkdown(event.descriptionMarkdown).run()
    }
  }, [event.descriptionMarkdown])

  return <div />
}

export default EventDescriptionMarkdownUpdater
