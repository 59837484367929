import { Button, makeStyles, SvgIcon, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  openInviteGuestsModal,
  openScanner,
  setModalPage,
} from '../../../actions/modals'
import { getCurrentEvent } from '../../../selectors/event'
import { PeopleAlt, PersonAdd } from '@material-ui/icons'
import {
  mdiAccountCheck,
  mdiAccountClock,
  mdiBellCheck,
  mdiCurrencyEur,
  mdiFire,
  mdiQrcodeScan,
  mdiTicket,
} from '@mdi/js'
import cn from 'clsx'
import React from 'react'
import ScannerModal from '../../../shared-components/event/ScannerModal'
import { guestManagementBreakpoint } from './GuestManagementModal'
import { EURO } from '../../EventCreation/TicketOption'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../../shared-components/buttons/BashButton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.5),
    gap: 10,
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.gray[200],
    [theme.breakpoints.down(guestManagementBreakpoint)]: {
      background: theme.palette.grey[100],
      height: theme.spacing(1),
    },
  },
  pageButton: {
    borderRadius: 8,
    padding: theme.spacing(1.5),
    width: '100%',
  },
  pageButtonActive: {
    background: theme.palette.grey[150] + ' !important',
  },
  pageButtonLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'start',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  buttonIcon: {
    width: 20,
    height: 20,
  },
  topButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  topButton: {
    background: theme.palette.primary[100],
    color: theme.palette.primary[800],
    padding: theme.spacing(1, 2, 1, 1.5),
    borderRadius: 100,
    '&:hover': {
      background: theme.palette.primary[200],
    },
  },
  topButtonLabelDisabled: {
    opacity: 0.6,
  },
  topButtonLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    '& svg': {
      width: 20,
      height: 20,
    },
  },
}))

const GuestManagementMenuButton = ({ page, icon, count, disabled }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const dispatch = useDispatch()
  const activePage = useSelector((state) => state.modals.page.guestManagement)
  const pageForCopy = page === 'preRegister' ? 'waitingList' : page

  const onClick = () => {
    dispatch(setModalPage('guestManagement', page))
  }

  return (
    <Button
      className={cn(
        classes.pageButton,
        page === activePage && classes.pageButtonActive,
      )}
      classes={{
        label: classes.pageButtonLabel,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {`${t(`guestManagement.${pageForCopy}`)}${count != null && (typeof count !== 'number' || count > 0) ? ` (${count})` : ''}`}
    </Button>
  )
}

const GuestManagementMenu = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const page = useSelector((state) => state.modals.page.guestManagement)
  const event = useSelector(getCurrentEvent)

  const joinOptionsDisabled = !event.hasJoinOptions
  const showApproval =
    !joinOptionsDisabled && event.joinOptions?.some((jo) => jo.approvalRequired)
  const showWaitlist =
    !joinOptionsDisabled &&
    (event.statusCounts?.waitingList > 0 ||
      !event.joinOptions?.some((jo) => jo.available))
  const showHype = event.privacyType === 'PUBLIC'
  const showRevenue =
    !joinOptionsDisabled &&
    event.joinOptions?.some((jo) => jo.chooseYourOwnPrice || jo.price > 0)

  const isEventix = event.joinOptions?.every(
    (option) => option.idInfo?.source === 'EVENTIX',
  )

  const inviteClicked = () => {
    dispatch(openInviteGuestsModal())
  }
  const checkInClicked = () => {
    dispatch(openScanner())
  }

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <div className={classes.topButtons}>
          <BashButton
            type={BashButtonType.PRIMARY_LIGHT}
            onClick={inviteClicked}
            extraView={<PersonAdd />}
            viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
          >
            {t('guestManagement.invite')}
          </BashButton>

          <BashButton
            type={BashButtonType.PRIMARY_LIGHT}
            onClick={checkInClicked}
            extraView={
              <SvgIcon>
                <path d={mdiQrcodeScan} />
              </SvgIcon>
            }
            viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
            className={classes.topButtonLabelDisabled}
          >
            {t('guestManagement.checkInGuests')}
          </BashButton>
        </div>
      </div>

      <div className={classes.divider} />

      <div className={classes.section}>
        <GuestManagementMenuButton
          page={'guestList'}
          count={
            (joinOptionsDisabled
              ? event.statusCounts.going
              : event.statusCounts.totalGoing) +
            event.statusCounts.maybe +
            event.statusCounts.cant +
            (event.type === 'PINNING'
              ? event.statusCounts?.gaveAvailability
              : 0)
          }
          icon={<PeopleAlt />}
        />
      </div>

      <div className={classes.divider} />

      <div className={classes.section}>
        {showApproval && (
          <GuestManagementMenuButton
            page={'approvalRequested'}
            count={event.statusCounts.requested}
            icon={
              <SvgIcon>
                <path d={mdiAccountCheck} />
              </SvgIcon>
            }
          />
        )}

        <GuestManagementMenuButton
          page={'invitePending'}
          count={event.statusCounts.invited}
          icon={
            <SvgIcon>
              <path d={mdiAccountClock} />
            </SvgIcon>
          }
        />

        {showWaitlist && (
          <GuestManagementMenuButton
            page={'preRegister'}
            count={event.statusCounts.waitingList}
            icon={
              <SvgIcon>
                <path d={mdiBellCheck} />
              </SvgIcon>
            }
          />
        )}

        {showHype && (
          <GuestManagementMenuButton
            page={'hype'}
            count={event.statusCounts.interested}
            icon={
              <SvgIcon>
                <path d={mdiFire} />
              </SvgIcon>
            }
          />
        )}
      </div>
      <div className={classes.divider} />
      <div className={classes.section}>
        <GuestManagementMenuButton
          disabled={joinOptionsDisabled}
          page={joinOptionsDisabled ? 'joinOptionsDisabled' : 'joinOptions'}
          count={
            event.statusCounts?.guestListTickets > 0
              ? event.joinOptions?.length + 1
              : event.joinOptions?.length
          }
          icon={
            <SvgIcon>
              <path d={mdiTicket} />
            </SvgIcon>
          }
        />
        {showRevenue && (
          <Tooltip
            title={isEventix ? 'View revenue on the Eventix dashboard' : ''}
          >
            <span
              style={{
                width: '100%',
                cursor: isEventix ? 'not-allowed' : 'default',
              }}
            >
              <GuestManagementMenuButton
                disabled={isEventix}
                page={'revenue'}
                count={
                  event.ticketStats?.totalRevenue != null &&
                  event.ticketStats?.totalRevenue > 0
                    ? EURO.format(event.ticketStats.totalRevenue / 100)
                    : null
                }
                icon={
                  <SvgIcon>
                    <path d={mdiCurrencyEur} />
                  </SvgIcon>
                }
              />
            </span>
          </Tooltip>
        )}
      </div>

      <ScannerModal />
    </div>
  )
}

export default GuestManagementMenu
