import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'clsx'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1)
  },
  scrollContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    overflowY: 'auto'
  }
}))

const ExtendedDialog = ({ open, onClose, title, subtitle, startElement, endElement, children, scrollContainerClassName, className, dialogActions }) => {
  const classes = useStyles()
  return (
    <Dialog
      classes={{
        paper: className
      }}
      aria-labelledby='confirm-action-dialog' onClose={onClose} open={open}
    >
      <DialogTitle disableTypography>
        <Typography className={classes.title} variant='h5'>
          {title}
        </Typography>
        {subtitle && (
          <Typography color='textSecondary' variant='body2'>
            {subtitle}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        {startElement}
        {children && <div className={cn(classes.scrollContainer, scrollContainerClassName)}>
          {children}
        </div>}
        {endElement}
      </DialogContent>
      {dialogActions && <DialogActions style={{ paddingBottom: 16, paddingRight: 16 }}>
        {dialogActions}
      </DialogActions>}
    </Dialog>
  )
}

export default (ExtendedDialog)
