import {
  CircularProgress,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { ChevronLeft, ChevronRight, Close, Delete } from '@material-ui/icons'
import cn from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getVideoUrl } from 'shared-components/utils/media'
import useKeyPress from '../../utils/useKeyPress'

const useStyles = makeStyles((theme) => ({
  root: {
    outline: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  container: {
    outline: 0,
    width: '100vw',
    height: '100vh',
    background: theme.palette.background['backdrop'],
    // backdropFlter: 'blur(2px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeImage: {
    width: '70vw',
    height: '70vh',
    paddingBottom: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  rightClickField: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '50%',
    height: '100%',
    cursor: 'pointer',
  },
  leftClickField: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '50%',
    height: '100%',
    cursor: 'pointer',
  },
  closeButton: {
    color: theme.palette.text.primary,
    zIndex: 10,
  },
  deleteButton: {
    color: theme.palette.text.primary,
  },
  bottomImages: {
    position: 'absolute',
    width: '100%',
    overflow: 'scroll',
    bottom: 24,
    left: 16,
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  bottomImage: {
    width: '80px',
    height: '80px',
    objectFit: 'cover',
    flexShrink: 0,
    background: '#F2F5FD',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    borderRadius: theme.spacing(1),
  },
  activeBottomImage: {
    borderWidth: '1px',
    borderColor: theme.palette.gray[300],
    borderStyle: 'solid',
  },
  rightButton: {
    right: theme.spacing(2),
  },
  leftButton: {
    left: theme.spacing(2),
  },
  arrowButton: {
    position: 'absolute',
    top: '50%',
    color: theme.palette.gray[600],
    border: `2px solid ${theme.palette.gray[600]}`,
    borderRadius: 100,
    padding: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.gray[800],
    },
  },
  header: {
    height: 64,
    background: theme.palette.background.paper,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    padding: 16,
    alignItems: 'center',
    zIndex: 3,
  },
  avatar: {
    height: 40,
    widht: 40,
    marginRight: theme.spacing(1),
  },
  messageInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  messageSender: {
    fontWeight: 'bold',
  },
  messageTime: {
    color: theme.palette.text.secondary,
  },
  imageIndex: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      position: 'initial',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  progress: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    zIndex: -1,
  },
  responseBox: {
    position: 'absolute',
    bottom: '80px',
    zIndex: 4,
    right: 'auto',
    left: 'auto',
  },
  message: {
    position: 'absolute',
    marginTop: theme.spacing(1),
  },
  eventImage: {
    maxHeight: '70vh',
    objectFit: 'cover',
    overflow: 'hidden',
  },
  eventVideo: {
    width: '100%',
  },
}))

export const ViewMediaModal = ({
  open,
  onClose,
  media,
  activeImageIndex,
  setActiveImageIndex,
  useKeypress = true,
  onDeleteImage,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const [lastDirection, setLastDirection] = useState(-1)
  const activeRef = useRef()
  const rightArrowDown = useKeyPress('ArrowRight')
  const leftArrowDown = useKeyPress('ArrowLeft')
  const { t } = useTranslation('common')

  const handleLeftClick = (e) => {
    if (e) {
      e.stopPropagation()
    }
    if (activeImageIndex > 0) {
      setActiveImageIndex(activeImageIndex - 1)
      setLastDirection(-1)
    } else {
      setActiveImageIndex(media.length - 1)
      setLastDirection(-1)
    }
  }

  const handleRightClick = (e) => {
    if (e) {
      e.stopPropagation()
    }
    if (activeImageIndex < media.length - 1) {
      setActiveImageIndex(activeImageIndex + 1)
      setLastDirection(1)
    } else {
      setActiveImageIndex(0)
      setLastDirection(1)
    }
  }

  const onImageClick = (index) => {
    setLastDirection(index > activeImageIndex ? 1 : -1)
    setActiveImageIndex(index)
  }

  useEffect(() => {
    activeRef &&
      activeRef.current &&
      activeRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [activeImageIndex])

  useEffect(() => {
    if (useKeypress) {
      rightArrowDown && handleRightClick()
      leftArrowDown && handleLeftClick()
    }
  }, [rightArrowDown, leftArrowDown])

  return (
    <Modal open={open} onClose={onClose} className={classes.root}>
      <div>
        <div className={classes.header}>
          <IconButton onClick={onClose} className={classes.closeButton}>
            <Close />
          </IconButton>
          <Typography className={classes.imageIndex} variant='subtitle1'>
            {activeImageIndex + 1} {t('of')} {media.length}
          </Typography>

          {onDeleteImage != null && (
            <IconButton
              onClick={onDeleteImage}
              className={classes.deleteButton}
            >
              <Delete />
            </IconButton>
          )}
        </div>
        <div className={classes.container} onClick={() => onClose()}>
          <div className={classes.activeImage}>
            <CircularProgress
              className={classes.progress}
              size={20}
              color='inherit'
            />
            {media[activeImageIndex]?.type === 'VIDEO' && (
              <div
                className={classes.videoContainer}
                onClick={(e) => e.stopPropagation()}
              >
                <video
                  src={getVideoUrl(media[activeImageIndex])}
                  className={cn(classes.eventImage, classes.eventVideo)}
                  autoPlay
                  playsInline
                  loop
                  controls
                />
              </div>
            )}
            {media[activeImageIndex]?.type === 'IMAGE' && (
              <img
                src={
                  media[activeImageIndex].content.imageUrls?.original ??
                  media[activeImageIndex].model?.original ??
                  media[activeImageIndex].content.imageUrls?.lg ??
                  media[activeImageIndex].model?.lg
                }
                alt='Event image'
                className={classes.eventImage}
                onClick={(e) => e.stopPropagation()}
              />
            )}
          </div>
          <div className={classes.bottomImages}>
            {media.map((m, index) => (
              <div
                key={index}
                onClick={(e) => {
                  e.stopPropagation()
                  onImageClick(index)
                }}
              >
                {m.type === 'VIDEO' && (
                  <>
                    {!m.content.thumbnail && (
                      <video
                        className={cn(
                          classes.bottomImage,
                          index === activeImageIndex &&
                            classes.activeBottomImage,
                        )}
                        src={getVideoUrl(m)}
                        muted
                      />
                    )}
                    {m.content.thumbnail && (
                      <img
                        className={cn(
                          classes.bottomImage,
                          index === activeImageIndex &&
                            classes.activeBottomImage,
                        )}
                        src={m.content.thumbnail.url}
                      />
                    )}
                  </>
                )}
                {m.type === 'IMAGE' && (
                  <img
                    className={cn(
                      classes.bottomImage,
                      index === activeImageIndex && classes.activeBottomImage,
                    )}
                    src={m.content.imageUrls.lg}
                    alt='image'
                  />
                )}
              </div>
            ))}
          </div>
          {media.length > 1 && (
            <>
              <IconButton
                onClick={handleLeftClick}
                className={cn(classes.arrowButton, classes.leftButton)}
              >
                <ChevronLeft />
              </IconButton>

              <IconButton
                onClick={handleRightClick}
                className={cn(classes.arrowButton, classes.rightButton)}
              >
                <ChevronRight />
              </IconButton>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}
