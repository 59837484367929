import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import cn from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 6px',
    background: theme.palette.red.light,
    borderRadius: 4,
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  text: {
    color: theme.palette.red[95],
  },
}))

const BetaBadge = ({ className, ...props }) => {
  const classes = useStyles()

  return (
    <div className={cn(classes.root, className)} {...props}>
      <Typography className={classes.text} variant='caption'>
        Beta
      </Typography>
    </div>
  )
}

const useProStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 8px',
    background: '#F3ECFD',
    borderRadius: 4,
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  text: {
    color: '#600BC1',
  },
}))

export const ProBadge = ({ className }) => {
  const classes = useProStyles()

  return (
    <div className={cn(classes.root, className)}>
      <Typography className={classes.text} variant='caption'>
        Pro
      </Typography>
    </div>
  )
}

export default BetaBadge
