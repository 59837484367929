import React from 'react'

const Icon = (props) => (
  <svg
    width='459'
    height='480'
    viewBox='0 0 459 480'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M0 96C0 50.7452 0 28.1177 14.0589 14.0589C28.1177 0 50.7452 0 96 0H99C144.255 0 166.882 0 180.941 14.0589C195 28.1177 195 50.7452 195 96V384C195 429.255 195 451.882 180.941 465.941C166.882 480 144.255 480 99 480H96C50.7452 480 28.1177 480 14.0589 465.941C0 451.882 0 429.255 0 384V96Z'
      fill='currentColor'
    />
    <path
      d='M309.462 480C292.284 480 283.695 480 276.72 477.827C261.66 473.134 249.866 461.34 245.173 446.28C243 439.305 243 430.716 243 413.538L243 330.462C243 313.284 243 304.695 245.173 297.72C249.866 282.66 261.66 270.866 276.72 266.173C283.695 264 292.284 264 309.462 264L351 264C410.647 264 459 312.353 459 372V372C459 431.647 410.647 480 351 480L309.462 480Z'
      fill='currentColor'
    />
    <path
      d='M309.462 480C292.284 480 283.695 480 276.72 477.827C261.66 473.134 249.866 461.34 245.173 446.28C243 439.305 243 430.716 243 413.538L243 330.462C243 313.284 243 304.695 245.173 297.72C249.866 282.66 261.66 270.866 276.72 266.173C283.695 264 292.284 264 309.462 264L351 264C410.647 264 459 312.353 459 372V372C459 431.647 410.647 480 351 480L309.462 480Z'
      fill='currentColor'
      fillOpacity='0.05'
    />
    <path
      d='M459 108C459 167.647 410.647 216 351 216C291.353 216 243 167.647 243 108C243 48.3532 291.353 0 351 0C410.647 0 459 48.3532 459 108Z'
      fill='currentColor'
    />
  </svg>
)

export default Icon
