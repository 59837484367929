import {
  CircularProgress,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import apiClient from '../../../shared-components/utils/ApiClient'
import { EURO } from '../../EventCreation/TicketOption'
import { decapitalizeFirstLetter } from '../../../shared-components/utils/copy'
import cn from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: theme.spacing(3, 4),
    gap: theme.spacing(3),
    overflowY: 'auto',
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.gray[200],
  },
  item: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2),
    borderRadius: 8,
    border: `1px solid ${theme.palette.gray[200]}`,
    justifyContent: 'space-between',
  },
  itemDisabled: {
    color: theme.palette.grey[600],
    '& *': {
      color: theme.palette.grey[600] + ' !important',
    },
  },
  itemLeft: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  itemText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  textMedium: {
    color: theme.palette.gray[800],
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    borderTop: `1px solid ${theme.palette.gray[600]}`,
    paddingTop: theme.spacing(2.5),
  },
  totalRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  eventixLogo: {
    width: 20,
    height: 20,
    color: theme.palette.primary.main,
  },
}))

const GuestManagementRevenue = ({}) => {
  const classes = useStyles()
  const theme = useTheme()

  const router = useRouter()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState({})

  const loadStats = async () => {
    setLoading(true)
    const stats = await apiClient.event.getTicketStats(event.id)
    setStats(stats)
    setLoading(false)
  }

  useEffect(() => {
    if (!event.id) {
      return
    }
    open && loadStats()
  }, [event])

  const getPriceText = (jo) => {
    if (jo.chooseYourOwnPrice) {
      t('newCreation.ticketsAdvanced.nameYourOwnPrice')
    } else {
      if (!jo.price || jo.price === 0) {
        return t('free')
      } else {
        return EURO.format(jo.price / 100)
      }
    }
  }

  const getTotalSoldText = () => {
    if (stats.totalCapacity != null) {
      return `${stats.totalSold} / ${stats.totalCapacity}`
    } else return stats.totalSold
  }

  if (!stats?.ticketOptions && loading)
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    )

  return (
    <div className={classes.root}>
      {stats.joinOptions && (
        <>
          {stats.joinOptions
            .filter((jo) => jo.name !== '')
            .map((jo) => {
              return (
                <Tooltip
                  title={
                    jo.idInfo?.source === 'EVENTIX'
                      ? 'Manage revenue on Eventix dashboard'
                      : ''
                  }
                >
                  <div
                    className={cn(
                      classes.item,
                      jo.type !== 'TICKET' && classes.itemDisabled,
                    )}
                  >
                    <div className={classes.itemLeft}>
                      {jo.idInfo?.source === 'EVENTIX' && (
                        <img
                          src='/images/eventixLogo.svg'
                          className={classes.eventixLogo}
                        />
                      )}
                      <div className={classes.itemText}>
                        <Typography variant='body2' style={{ fontWeight: 500 }}>
                          {jo.name}
                        </Typography>
                        <Typography
                          variant='body2'
                          className={classes.textMedium}
                        >
                          {getPriceText(jo)}
                        </Typography>
                      </div>
                    </div>
                    {jo.type === 'TICKET' && (
                      <div
                        className={classes.itemText}
                        style={{ alignItems: 'flex-end' }}
                      >
                        <Typography variant='body2'>
                          {`${jo.amountSold ?? 0}${jo.capacity ? ` / ${jo.capacity}` : ''} ${decapitalizeFirstLetter(t('joined'))}`}
                        </Typography>
                        <Typography
                          variant='body2'
                          className={classes.textMedium}
                        >
                          {EURO.format(jo.totalRevenue / 100)}
                        </Typography>
                      </div>
                    )}
                  </div>
                </Tooltip>
              )
            })}

          <div className={classes.totalContainer}>
            <div className={classes.totalRow}>
              <Typography variant='body1' className={classes.textMedium}>
                {t('totalJoined')}:
              </Typography>
              <Typography variant='body1' className={classes.textMedium}>
                {getTotalSoldText()}
              </Typography>
            </div>

            <div className={classes.totalRow}>
              <Typography variant='body1' style={{ fontWeight: 500 }}>
                {t('ticketStats.totalRevenue')}:
              </Typography>
              <Typography variant='body1' style={{ fontWeight: 500 }}>
                {EURO.format(stats.totalRevenue / 100)}
              </Typography>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default GuestManagementRevenue
