import React, { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  useTheme,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeEmbedModal,
  openEmbedModal,
  openTicketsModal,
} from '../../../actions/modals'
import { useRouter } from 'next/router'
import { getActiveGuestCode, getCurrentEvent } from '../../../selectors/event'
import ExtendedDialog from '../../dialog/ExtendedDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import Switch from '@material-ui/core/Switch/Switch'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import { cancelEvent, setDraft, setLocBeforeEdit } from '../../../actions/event'
import { locationToMpLocation } from './locationToMpLocation'
import useMixpanelTrackEvent from '../../../utils/useMixpanelTrackEvent'
import apiClient from '../../../shared-components/utils/ApiClient'
import { setCurrentScrapeBotId } from 'actions/scraper'
import scraperApiClient from '../../../scraper/ScraperApiClient'
import ContentBlock from '../../../shared-components/event/ContentBlock'
import SocialButton from '../../common/SocialButton'
import BottomSheet from '../../common/BottomSheet'
import AnalyticsCard from '../AnalyticsCard'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { openRsvpOnStep } from 'actions/rsvp'
import { RemoveMeOrTicketSheet } from '../rsvp/RemoveMeOrTicketSheet'
import { QRCode } from 'react-qrcode-logo'
import FlatButton from 'shared-components/buttons/FlatButton'
import { Download } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  whiteIconButton: {
    background: 'white',
    boxShadow: theme.shadows[2],
    color: theme.palette.primary.main,
  },
  greyIconButton: {
    width: '48px',
    height: '48px',
  },
  greyIconButtonSmall: {
    width: '40px',
    height: '40px',
  },
  redText: {
    color: theme.palette.red.main,
  },
  switch: {
    margin: theme.spacing(1, 2, 1, 2),
  },
  switchDivider: {
    // margin: theme.spacing(0, -3)
  },
  scrollContainer: {
    margin: theme.spacing(0, -3),
    padding: 0,
  },
  cancelProgress: {
    marginLeft: theme.spacing(1),
  },
  lightButton: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[800],
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tinyButton: {
    width: 32,
    height: 32,
    backgroundColor: 'none',
    color: theme.palette.text.primary,
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  menuList: {
    padding: 0,
    '& > *': {
      padding: theme.spacing(2),
    },
  },
  red: {
    color: theme.palette.red.main,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  qrcodeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 24,
    marginRight: 24,
  },
  downloadQrcodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  qrcodeButton: {
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[800],
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
  },
}))

const BuyMoreItem = forwardRef(({ onClose, location }, ref) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const event = useSelector(getCurrentEvent)
  const [open, setOpen] = useState(false)

  const onClick = () => {
    dispatch(openTicketsModal())
    onClose()
  }

  return (
    <>
      <MenuItem ref={ref} onClick={onClick}>
        {t('buyMoreTickets')}
      </MenuItem>
    </>
  )
})

const RemoveMeItem = forwardRef(({ onClose, location }, ref) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const router = useRouter()
  const event = useSelector(getCurrentEvent)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const activeGuestCode = useSelector(getActiveGuestCode)
  const ownerOrg = event.hosts?.find((h) => h.role === 'OWNER')?.model

  const onClick = () => {
    setOpen(true)
    onClose()
  }

  const onRemove = async () => {
    setLoading(true)
    await apiClient.rsvp.remove(event.id, activeGuestCode)
    router.push('/home')
  }

  const myPaidTickets = event.joinOptions?.find(
    (e) => e.myTickets?.length > 0 && e.price && e.price > 0,
  )

  return (
    <>
      <MenuItem ref={ref} onClick={onClick} className={classes.red}>
        {t('removeMeFromEvent')}
      </MenuItem>
      <RemoveMeOrTicketSheet
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          onRemove()
          setOpen(false)
        }}
        loading={loading}
        hostName={ownerOrg?.name}
        refundPolicy={ownerOrg?.refundPolicy}
        type={myPaidTickets ? 'remove-me-with-tickets' : 'remove-me'}
      />
    </>
  )
})

const EmbedItem = forwardRef(({ onClose, location }, ref) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const classes = useStyles()
  const event = useSelector(getCurrentEvent)
  const handleOpenEmbed = () => dispatch(openEmbedModal())
  const handleCloseEmbed = () => dispatch(closeEmbedModal())
  const openEmbed = useSelector((state) => state.modals.open.embed)
  const user = useSelector((state) => state.user.user)

  const onClick = () => {
    handleOpenEmbed()
    onClose()
  }

  const downloadCode = () => {
    const canvas = document.getElementById('qrCode')
    if (canvas) {
      const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
      let downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = `${event.name ?? 'qrCode'}.png`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }

  const trackEvent = useMixpanelTrackEvent(event)

  const onTrack = () => trackEvent('Copy Embed Html Event')

  const eventUrl = `https://bash.social/e/${event.code}${
    user.id ? `?u=${user.id}` : ''
  }`

  return (
    <>
      <MenuItem ref={ref} onClick={onClick}>
        {t('Embed your event')}
      </MenuItem>
      <BottomSheet
        alignStart
        title={t('Embed your event')}
        open={openEmbed}
        onClose={() => handleCloseEmbed()}
      >
        <ContentBlock style={{ border: 'none', marginBottom: 0, marginTop: 0 }}>
          <div className={classes.qrcodeContainer}>
            <div style={{ position: 'absolute', display: 'none' }}>
              <QRCode
                value={eventUrl} // here you should keep the link/value(string) for which you are generation promocode
                size={800} // the dimension of the QR code (number)
                logoImage='/images/logo.svg' // URL of the logo you want to use, make sure it is a dynamic url
                logoHeight={164}
                logoWidth={164}
                logoOpacity={1}
                logo
                enableCORS={true} // enabling CORS, this is the thing that will bypass that DOM check
                // qrStyle='fluid' // type of qr code, wether you want dotted ones or the square ones
                id={'qrCode'}
                logoPadding={44}
                ecLevel={'M'}
              />
            </div>
            <QRCode
              value={eventUrl} // here you should keep the link/value(string) for which you are generation promocode
              size={168} // the dimension of the QR code (number)
              logoImage='/images/logo.svg' // URL of the logo you want to use, make sure it is a dynamic url
              logoHeight={32}
              logoWidth={32}
              logoOpacity={1}
              enableCORS={true} // enabling CORS, this is the thing that will bypass that DOM check
              logoPadding={8}
              ecLevel={'M'}
            />
            <div className={classes.downloadQrcodeContainer}>
              <Typography variant='body2'>{t('downloadQrcode')}</Typography>
              <FlatButton
                className={classes.qrcodeButton}
                startIcon={<Download />}
                onClick={downloadCode}
              >
                {t('download')}
              </FlatButton>
            </div>
          </div>
          <Divider className={classes.divider} />
          <SocialButton
            onTrack={onTrack}
            imageUrl='https://storage.googleapis.com/bash-email-assets/join-event-on-BASH.png'
            aTagUrl={eventUrl}
            width={166}
            shorten
          />
        </ContentBlock>
      </BottomSheet>
    </>
  )
})

const queryClient = new QueryClient()
const AnalyticsItem = forwardRef(({ onClose, location }, ref) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const event = useSelector(getCurrentEvent)
  const [open, setOpen] = useState(false)

  const onClick = () => {
    setOpen(true)
    onClose()
  }

  return (
    <>
      <MenuItem ref={ref} onClick={onClick}>
        {t('insights')}
      </MenuItem>
      <QueryClientProvider client={queryClient}>
        <BottomSheet
          alignStart
          title={t('insights')}
          open={open}
          onClose={() => setOpen(false)}
        >
          <AnalyticsCard isModal />
        </BottomSheet>
      </QueryClientProvider>
    </>
  )
})

const DuplicateItem = forwardRef(({ onClose, location }, ref) => {
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const dispatch = useDispatch()
  const router = useRouter()
  const trackEvent = useMixpanelTrackEvent()

  const onClick = () => {
    dispatch(
      setDraft({
        ...event,
        id: null,
        chatDigest: null,
        duplicateOptions: {
          duplicatedFromEventId: event.id,
          reuseImage: true,
        },
      }),
    )
    dispatch(setLocBeforeEdit(router.asPath))
    dispatch(setCurrentScrapeBotId(null))
    router.push('/create', undefined, { shallow: true })
    onClose()
  }

  return (
    <MenuItem ref={ref} onClick={onClick}>
      {t('duplicateEvent')}
    </MenuItem>
  )
})

const CancelItem = forwardRef(({ onClose, location }, ref) => {
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [notifyChecked, setNotifyChecked] = useState(true)
  const loading = useSelector((state) => state.event.patchEventPending)
  const trackEvent = useMixpanelTrackEvent()
  const router = useRouter()

  const isDelete = event.state === 'CANCELED'
  const menuItem = isDelete
    ? t('deleteEvent.menuItem')
    : t('cancelEvent.menuItem')
  const title = isDelete ? t('deleteEvent.title') : t('cancelEvent.title')
  const subtitle = isDelete
    ? t('deleteEvent.subtitle')
    : t('cancelEvent.subtitle')
  // if (event.state === 'CANCELED') return null

  const onClick = async () => {
    if (isDelete) {
      router.push('/home')
      trackEvent('Delete Bash', {
        'Location in App': location,
        'Action Type': 'overflow',
      })
      // await dispatch(deleteEvent(event.id, event.code))
      await apiClient.event.delete(event.id)
      // router.
    } else {
      trackEvent('Cancel Bash', {
        'Location in App': location,
        'Action Type': 'overflow',
      })
      dispatch(cancelEvent(event, notifyChecked))
    }

    setDialogOpen(false)
    onClose()
  }

  const dialogActions = (
    <>
      <Button disabled={loading} onClick={() => setDialogOpen(false)}>
        {t('nevermind')}
      </Button>
      <Button disabled={loading} onClick={onClick} className={classes.redText}>
        {isDelete ? t('delete') : t('cancel')}
        {loading && (
          <CircularProgress
            className={classes.cancelProgress}
            key='loading'
            size={20}
          />
        )}
      </Button>
    </>
  )

  return (
    <>
      <MenuItem
        className={classes.red}
        ref={ref}
        onClick={() => setDialogOpen(true)}
      >
        {menuItem}
      </MenuItem>
      <ExtendedDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={title}
        subtitle={subtitle}
        dialogActions={dialogActions}
        scrollContainerClassName={classes.scrollContainer}
      >
        {!isDelete && (
          <>
            <Divider className={classes.switchDivider} />
            <FormControlLabel
              className={classes.switch}
              control={
                <Switch
                  checked={notifyChecked}
                  onChange={(e) => setNotifyChecked(e.target.checked)}
                  name='sendNotifSwitch'
                  color='primary'
                />
              }
              label={
                <Typography variant='caption'>
                  {t('updateAttendeesModal.updateAttendees')}
                </Typography>
              }
            />
            <Divider className={classes.switchDivider} />
          </>
        )}
      </ExtendedDialog>
    </>
  )
})

const RejectItem = forwardRef(({ onClose }, ref) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const [openReject, setOpenReject] = React.useState(false)
  const [reason, setReason] = React.useState('')

  const handleClickOpenReject = () => {
    onClose()
    setOpenReject(true)
  }

  const onReject = async () => {
    await scraperApiClient.scrapers.rejectBot(event.scrapeBotId, reason)
    handleCloseReject()
  }

  const handleCloseReject = () => {
    setOpenReject(false)
  }

  return (
    <>
      <MenuItem ref={ref} onClick={handleClickOpenReject}>
        Reject scraper
      </MenuItem>
      <Dialog open={openReject} onClose={handleCloseReject}>
        <DialogTitle>Reject bot</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Can you shortly explain what went wrong?
          </DialogContentText>

          <TextField
            autoFocus
            placeholder='https://bash.social/e/abcdefg'
            margin='dense'
            id='prompt'
            fullWidth
            variant='standard'
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReject}>Cancel</Button>
          <Button onClick={onReject}>Reject</Button>
        </DialogActions>
      </Dialog>
    </>
  )
})

const MergeItem = forwardRef(({ onClose }, ref) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const [openMerge, setOpenMerge] = React.useState(false)
  const [url, setUrl] = React.useState('')

  const handleClickOpenMerge = () => {
    onClose()
    setOpenMerge(true)
  }

  const onMerge = async () => {
    await scraperApiClient.events.merge({
      mergeEvent: {
        url: url,
      },
      keepEvent: {
        id: event.id,
      },
    })
    handleCloseMerge()
  }

  const handleCloseMerge = () => {
    setOpenMerge(false)
  }

  return (
    <>
      <MenuItem ref={ref} onClick={handleClickOpenMerge}>
        Merge other event into this one
      </MenuItem>
      <Dialog open={openMerge} onClose={handleCloseMerge}>
        <DialogTitle>Merge other event into this event</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Delete other event and merge host and guests into this event.
          </DialogContentText>
          <TextField
            placeholder='https://bash.social/e/abcdefg'
            autoFocus
            margin='dense'
            id='prompt'
            fullWidth
            variant='standard'
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            label='BASH URL'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMerge}>Cancel</Button>
          <Button onClick={onMerge}>Merge</Button>
        </DialogActions>
      </Dialog>
    </>
  )
})

const UpdateQuestionsItem = forwardRef(({ onClose, location }, ref) => {
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const dispatch = useDispatch()
  const router = useRouter()
  const trackEvent = useMixpanelTrackEvent()

  const onClick = () => {
    dispatch(openRsvpOnStep('questions'))
    onClose()
  }

  return (
    <MenuItem ref={ref} onClick={onClick}>
      {t('updateQuestions')}
    </MenuItem>
  )
})

const MenuAction = {
  cancel: CancelItem,
  duplicate: DuplicateItem,
  reject: RejectItem,
  merge: MergeItem,
  analytics: AnalyticsItem,
  embed: EmbedItem,
  buyMore: BuyMoreItem,
  removeMe: RemoveMeItem,
  updateQuestions: UpdateQuestionsItem,
}

export const ActionOverflow = ({
  items,
  grey = true,
  location,
  small,
  tiny,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [menuOpen, setMenuOpen] = useState(null)

  return (
    <>
      <Backdrop
        style={{
          zIndex: 1000,
          backgroundColor: theme.palette.background['backdrop'],
        }}
        open={!!menuOpen}
      />
      <IconButton
        className={tiny ? classes.tinyButton : classes.lightButton}
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={(e) => {
          setMenuOpen(e.target)
          e.stopPropagation()
        }}
      >
        <MoreVertIcon fontSize={small ? 'small' : 'medium'} />
      </IconButton>
      <Menu
        id='long-menu'
        keepMounted
        anchorEl={menuOpen}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(menuOpen)}
        onClose={(e) => {
          setMenuOpen(null)
          e.stopPropagation()
        }}
        classes={{
          list: classes.menuList,
        }}
      >
        {items.map((item) => {
          const MenuActionComponent = MenuAction[item]
          return MenuActionComponent ? (
            <MenuActionComponent
              key={item}
              location={locationToMpLocation[location]}
              onClose={() => setMenuOpen(false)}
            />
          ) : null
        })}
      </Menu>
    </>
  )
}

export default ActionOverflow
