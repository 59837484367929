import mixpanel from 'shared-components/utils/mixpanel'

export const linkToMaps = location => {
  const url = 'https://maps.google.com/maps?q=' + location.replace(' ', '+')
  mixpanel.track('Outbound Link Maps', { url })
  window.open(url, '_blank')
}

export const goToWebsite = () => {
  mixpanel.track('Outbound Link Website', { url: process.env.NEXT_PUBLIC_WEBSITE })
  window.open(process.env.NEXT_PUBLIC_WEBSITE, '_blank')
}
