import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import BottomSheet from '../common/BottomSheet'
import TextInput from '../../shared-components/common/TextInput'
import FlatButton from '../../shared-components/buttons/FlatButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0 !important',
  },
  input: {
    marginTop: theme.spacing(-2),
  },
  saveButton: {
    marginTop: theme.spacing(2),
  },
}))

const LinkModal = ({ open, onClose, onConfirm, linkUrl, setLinkUrl }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const [text, setText] = useState(linkUrl)

  useEffect(() => {
    if (open) {
      setText(linkUrl)
    }
  }, [open])

  const onSave = () => {
    onConfirm(linkUrl, text)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      onSave()
    }
  }

  return (
    <BottomSheet
      open={open}
      onClose={onClose}
      title='Link'
      className={classes.root}
    >
      <TextInput
        label='Text'
        value={text}
        onChange={(e) => setText(e.target.value)}
        customClass={classes.input}
      />

      <TextInput
        label='URL'
        value={linkUrl}
        onChange={(e) => setLinkUrl(e.target.value)}
        placeholder={'https://your.link'}
        autoFocus
        onFocus={(e) => e.target.select()}
        onKeyDown={handleKeyDown}
      />

      <FlatButton onClick={onSave} className={classes.saveButton}>
        {t('save')}
      </FlatButton>
    </BottomSheet>
  )
}

export default LinkModal
