import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { Button, makeStyles, Typography } from '@material-ui/core'

import {
  openGuestModal,
  openInviteGuestsModal,
  openModalOnPage,
} from 'actions/modals'
import AvatarGroup from '../avatar/AvatarGroup'
import GuestAvatarBadge from '../avatar/GuestAvatarBadge'

import dynamic from 'next/dynamic'
import { getCurrentEvent, getHasResponded } from '../../selectors/event'
import cn from 'clsx'
import EventSection from '../../components/event/EventSection'
import { useIsLoggedIn } from 'utils/hooks'

const GuestModal = dynamic(
  () => import('shared-components/event/guestList/GuestModal'),
)
const InviteGuestsModal = dynamic(
  () => import('components/modals/InviteGuestsModal'),
)

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  root: {
    flexDirection: 'column',
    flexGrow: 1,
    height: 'fit-content',
    margin: 0,
    marginTop: theme.spacing(4),
  },
  topRow: {
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginBottom: theme.spacing(2),
  },
  stats: {
    justifyContent: 'space-around',
  },
  avatarGroup: {
    marginTop: theme.spacing(0),
  },
  goingText: {
    marginTop: theme.spacing(1),
  },
  expectedCircle: {
    alignSelf: 'center',
  },
  inviteMoreButton: {
    marginTop: theme.spacing(1),
  },
  inviteList: {
    paddingBottom: 0,
  },
  blur: {
    filter: 'blur(3px)',
  },
  lockedContainer: {
    cursor: 'pointer',

    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'calc(100% + 32px)',
    height: 'calc(100% + 4px);',
    zIndex: 20,
    margin: theme.spacing(0, -2),
    background: 'rgba(255, 255, 255, 0.8)',
  },
  lockedTitle: {
    marginTop: theme.spacing(1),
  },
  lockedCaption: {
    marginTop: theme.spacing(0.5),
  },
  lockIcon: {
    width: 24,
    height: 24,
  },
  blockEvents: {
    pointerEvents: 'none',
  },
  hypeScore: {
    fontWeight: 900,
    marginBottom: theme.spacing(1),
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  infoIcon: {
    height: 14,
    width: 14,
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },

  impressions: {
    color: theme.palette.text.secondary,
    marginLeft: 4,
    cursor: 'pointer',
  },
  arrow: {
    color: '#424242',
    marginTop: theme.spacing(-0.3),
    width: 24,
    height: 24,
  },
  respondedContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  guestList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  guestListMobile: {
    marginTop: theme.spacing(1.5),
  },
  guestListText: {
    fontWeight: 500,
  },
  emptyContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'center',
    padding: theme.spacing(1.5),
    background: theme.palette.primary[100],
    borderRadius: 8,
    cursor: 'default',
  },
  emptyButton: {
    color: theme.palette.primary[800],
    flexShrink: 0,
    padding: theme.spacing(1, 1.5),
  },
  text: {
    fontWeight: 400,
    '& > span': {
      fontWeight: 500,
    },
  },
}))

const Attendees = ({ desktopMode }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const isLoggedIn = useIsLoggedIn()
  const hasResponded = useSelector(getHasResponded)
  const guestsToUse = event.guests?.filter((e) => e.status !== 'CANT')

  if (!guestsToUse) return null

  const respondedCount =
    event.type === 'PINNING'
      ? event.statusCounts?.gaveAvailability
      : event.statusCounts?.joined +
        event.statusCounts?.going +
        event.statusCounts?.maybe +
        event.statusCounts?.cant

  const totalJoinedCount = Math.max(
    event.statusCounts?.joined,
    event.statusCounts?.ticketsSold,
  )

  const clickedWhileLocked = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    e.nativeEvent.stopPropagation()
    e.preventDefault()
    dispatch(openGuestModal())
  }

  const onMoreClicked = () => {
    if (event.host) {
      dispatch(openModalOnPage('guestManagement', 'guestList'))
    } else {
      dispatch(openGuestModal())
    }
  }

  const onAddGuestsClicked = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(openInviteGuestsModal())
  }

  if (event.hasJoinOptions !== true) return null
  if (event.guestsCanSeeGuests === false && !event.host) return null

  console.log(hasResponded)

  const showBlocker =
    !hasResponded &&
    (event.host ?? false) === false &&
    event.privacyType === 'PUBLIC'

  console.log(showBlocker)

  const getText = () => {
    const firstName =
      guestsToUse[0]?.firstName ??
      guestsToUse[0]?.name?.split(' ')[0] ??
      guestsToUse[0]?.user?.firstName ??
      guestsToUse[0]?.user?.name?.split(' ')[0]
    const secondName =
      guestsToUse[1]?.firstName ??
      guestsToUse[1]?.name?.split(' ')[0] ??
      guestsToUse[1]?.user?.firstName ??
      guestsToUse[1]?.user?.name?.split(' ')[0]

    if (guestsToUse.length === 1 && firstName)
      return {
        stringId: event.host
          ? 'respondedNamesLong.one'
          : 'respondedNamesLongAndMore.one',
        values: { firstName: firstName },
      }
    if (respondedCount > 2 && firstName && secondName)
      return {
        stringId: event.host
          ? 'respondedNamesLong.others'
          : 'respondedNamesLongAndMore.others',
        values: {
          firstName: firstName,
          secondName: secondName,
          count: respondedCount - 2,
        },
      }
    if (guestsToUse.length > 1 && firstName && secondName)
      return {
        stringId: event.host
          ? 'respondedNamesLong.two'
          : 'respondedNamesLongAndMore.two',
        values: { firstName: firstName, secondName: secondName },
      }

    return null
  }

  const text = getText()

  return (
    <>
      <EventSection
        title={
          t('guestList') +
          (totalJoinedCount > 0 ? ` · ${totalJoinedCount}` : '')
        }
        desktopMode={desktopMode}
        onMoreClicked={onMoreClicked}
        blocker={showBlocker}
        fullClickArea
        hasContent={true}
      >
        {guestsToUse?.length > 0 && (
          <div
            className={cn(classes.guestList)}
            onClick={showBlocker ? clickedWhileLocked : undefined}
          >
            <AvatarGroup
              max={12}
              className={
                event.type === 'OPEN'
                  ? undefined
                  : cn(classes.avatarGroup, !showBlocker && classes.blockEvents)
              }
              size='medium'
              respondedCount={respondedCount}
              smallGap
              noOverflow
            >
              {guestsToUse.map((g) => (
                <GuestAvatarBadge
                  hideBadge={true}
                  key={g.id}
                  guest={g}
                  size='small32'
                  showBlocker={showBlocker}
                />
              ))}
            </AvatarGroup>
            {/* {event.type === 'INVITE' && respondedCount > 0 && (
              <Typography className={classes.guestListText} variant='body2'>
                {`${respondedCount} ${
                  respondedCount === 1
                    ? t('shared:going_solo')
                    : t('shared:going_multiple')
                }`}
              </Typography>
            )}
            {event.type === 'PINNING' && respondedCount > 0 && (
              <Typography className={classes.guestListText} variant='body2'>
                {`${respondedPinningCount} ${
                  respondedPinningCount === 1
                    ? t('shared:responded_solo')
                    : t('shared:responded_multiple')
                }`}
              </Typography>
            )} */}
            {text && (
              <Typography variant='body2' className={cn(classes.text)}>
                <Trans
                  t={t}
                  i18nKey={text.stringId}
                  values={text.values}
                  components={{ 1: <span /> }}
                />
              </Typography>
            )}
          </div>
        )}

        {(!guestsToUse || guestsToUse?.length === 0) && event.host && (
          <div
            className={classes.emptyContainer}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <Typography variant='body2' style={{ lineHeight: '21px' }}>
              {t('attendeesEmptyText')}
            </Typography>

            <Button
              onClick={onAddGuestsClicked}
              className={classes.emptyButton}
            >
              {t('attendeesEmptyButton')}
            </Button>
          </div>
        )}
      </EventSection>
    </>
  )
}

export default Attendees
