import React from 'react'
import classNames from 'clsx'
import { Avatar, makeStyles } from '@material-ui/core'
import { getGuestName } from 'shared-components/utils/eventDisplay'
import { useDispatch, useSelector } from 'react-redux'
import { openGuestInfo, openUserInfo } from 'actions/modals'
import { useRouter } from 'next/router'
import { getIsOrganiser } from 'selectors/event'
import { useIsLoggedIn } from '../../utils/hooks'

const useStyles = makeStyles((theme) => ({
  avatar: {
    cursor: 'pointer',
  },
  shadow: {
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.2)',
  },
  disableClick: {
    cursor: 'default',
  },
}))

const getSizeOptionBySize = (size) => {
  if (!size) return 'xl'
  if (size <= 16) return 'xs'
  if (size <= 32) return 'sm'
  if (size <= 64) return 'lg'
  if (size <= 128) return 'xl'
  return 'xxl'
}

const ClickableAvatar = (props) => {
  const {
    userOrGuest,
    organisation,
    size,
    className,
    style,
    shadow,
    avatarClass,
    disableClick,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.user.user.id)
  const isOrganiser = useSelector(getIsOrganiser)
  const router = useRouter()
  const isLoggedIn = useIsLoggedIn()

  if (!(userOrGuest || organisation)) return null
  const sizeOption = getSizeOptionBySize(size)
  let realDisableClick = disableClick
  if (userOrGuest && userOrGuest.user && userOrGuest.user.id === userId)
    realDisableClick = true
  const avatarObj =
    organisation || (userOrGuest.user ? userOrGuest.user : userOrGuest)
  const guestName = getGuestName(avatarObj)
  const avatarUrls = avatarObj.avatarUrls ||
    avatarObj.avatar?.urls || { xl: avatarObj.avatarUrl }

  const onClick = (e) => {
    if (realDisableClick) return
    e.preventDefault()
    e.stopPropagation()
    if (organisation) {
      router.push(`/${organisation.username}`)
    } else if (userOrGuest.status) {
      if (isOrganiser || !userOrGuest.user) {
        dispatch(openGuestInfo(userOrGuest))
      } else {
        if (isLoggedIn) {
          router.push(`/users/${userOrGuest.user.id}`)
        } else {
          dispatch(openUserInfo(userOrGuest.user))
        }
      }
    } else {
      if (isLoggedIn) {
        router.push(`/users/${userOrGuest.id}`)
      } else {
        dispatch(openUserInfo(userOrGuest))
      }
    }
  }
  return (
    <Avatar
      onClick={onClick}
      alt={guestName}
      src={
        sizeOption in avatarUrls
          ? avatarUrls[sizeOption]
          : Object.values(avatarUrls)[0]
      }
      style={{ width: size, height: size, ...style }}
      className={classNames(className, avatarClass, classes.avatar, {
        [classes.shadow]: shadow,
        [classes.disableClick]: realDisableClick,
      })}
    />
  )
}

export default ClickableAvatar
