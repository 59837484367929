import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from 'selectors/event'
import { appContext } from 'shared-components/event/App'

export const useIsLoggedIn = () =>
  useSelector((state) => !!state.user?.user?.id)

export const useCurrentEvent = () => {
  const eventFromContext = useContext(appContext).event
  const eventFromRedux = useSelector(getCurrentEvent)
  return eventFromContext || eventFromRedux
}

export const useScraperBorderStyle = (key) => {
  const event = useSelector((state) => state.event.events.draft)
  const scraperStats = useSelector((state) => state.scraper.stats.data)
  const isScraperEvent = event.draft != null
  const wrongProbability = scraperStats[key]

  if (!isScraperEvent || wrongProbability == null || scraperStats.total < 10) {
    return {}
  }
  if (wrongProbability > 0.8) {
    return { border: '2px solid red' }
  }
  if (wrongProbability > 0.5) {
    return { border: '2px solid orange' }
  }
  if (wrongProbability > 0.2) {
    return { border: '2px solid yellow' }
  }
  if (wrongProbability > 0.05) {
    return {}
  }
  return { border: '2px solid green' }
}

export const useContainerDimensions = (myRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current?.offsetWidth,
      height: myRef.current?.offsetHeight,
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef])

  return dimensions
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
