import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from '../../selectors/event'
import EventSection from './EventSection'
import EventDescription from '../../shared-components/event/EventDescription'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  noMargin: {
    margin: 0,
  },
  tagsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  tag: {
    padding: theme.spacing(1, 1.5),
    background: theme.palette.grey[200],
    borderRadius: '4px',
  },
}))

const EventDescriptionCard = ({ desktopMode, previewType }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)

  if ((!event.description || event.description.trim === '') && !event.sourceUrl)
    return null

  const descriptionParts = event.description.split('\n\n--')
  const filteredDescription = descriptionParts[0]

  return (
    <EventSection title={t('eventDescription')} desktopMode={desktopMode}>
      <EventDescription
        variant='body2'
        description={filteredDescription}
        showFullDescription={previewType != null}
      />

      {/*{event.displayTags && event.displayTags.length > 0 && (*/}
      {/*  <div className={classes.tagsContainer}>*/}
      {/*    {event.displayTags.slice(0, 2).map((tag, index) => (*/}
      {/*      <Typography variant='caption' className={classes.tag}>*/}
      {/*        {tag.name}*/}
      {/*      </Typography>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*)}*/}

      {event.tags && event.tags.length > 0 && (
        <div className={classes.tagsContainer}>
          {event.tags.slice(0, 10).map((tag, index) => (
            <Typography key={index} variant='caption' className={classes.tag}>
              {tag}
            </Typography>
          ))}
        </div>
      )}
    </EventSection>
  )
}

export default EventDescriptionCard
