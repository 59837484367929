import { SvgIcon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'
import VerifiedIcon from '../../svg/CheckDecagram'
import { useTranslation } from 'react-i18next'
import { formatShortNumber } from 'utils/eventFunctions'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: theme.spacing(1),
    flexShrink: 0,
  },
  border: {
    border: '1px solid ' + theme.palette.grey[200],
    borderRadius: '8px',
    padding: theme.spacing(1, '10px'),
  },
  avatar: {
    width: '32px',
    height: '32px',
    borderRadius: '100px',
  },
  name: {
    fontWeight: 500,
    lineHeight: '1rem',
  },
  nameMaxWidth: {
    maxWidth: '120px',
  },
  verifiedIcon: {
    width: 16,
    height: 16,
  },
  button: {
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary.main,
    height: 32,
    marginLeft: theme.spacing(0.5),
  },
  following: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[600],
  },
  followerCount: {
    color: theme.palette.grey[600],
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}))

const HostCard = ({
  host,
  noBorder,
  onFollowClick,
  following,
  hasMultiple,
  noFollow = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const avatarUrl =
    host.model?.avatarUrls?.sm ||
    host.model?.user?.avatarUrls?.sm ||
    host.model?.avatarUrls?.lg ||
    host.model?.user?.avatarUrls?.lg
  const name = host.model?.name || host.model?.user?.name
  const verified = host.type === 'ORGANISATION' && host.model?.verified

  const showFollowButton =
    host.type === 'ORGANISATION' &&
    host.model?.followStatus !== 'ACTIVE' &&
    !noFollow

  const getLink = () => {
    if (host.type === 'ORGANISATION') {
      if (!!host.model?.username) {
        return `/${host.model?.username}`
      } else {
        return `/pages/${host.model?.id}`
      }
    } else if (host.type === 'USER') {
      return `/users/${host.model?.id}`
    } else {
      return '#'
    }
  }

  console.log(host)

  return (
    <Link href={getLink()} legacyBehavior>
      <div className={clsx(classes.root, !noBorder && classes.border)}>
        <img className={classes.avatar} src={avatarUrl} alt='profile picture' />
        <div className={classes.info}>
          <div
            className={clsx(
              classes.nameContainer,
              hasMultiple && classes.nameMaxWidth,
            )}
          >
            <Typography noWrap className={clsx(classes.name)} variant='body2'>
              {name}
            </Typography>
            {verified && (
              <SvgIcon
                component={VerifiedIcon}
                className={classes.verifiedIcon}
                color='secondary'
              />
            )}
          </div>
          {host.model?.followerCount > 10 &&
            host.model?.followerCount > host.model?.guestCount && (
              <Typography className={classes.followerCount} variant='caption'>
                {formatShortNumber(
                  host.model?.followerCount + (following ? 1 : 0),
                )}{' '}
                {t('shared:followers')}
              </Typography>
            )}
          {host.model?.guestCount > 9 &&
            host.model?.guestCount > host.model?.followerCount && (
              <Typography className={classes.followerCount} variant='caption'>
                {formatShortNumber(
                  host.model?.guestCount + (following ? 1 : 0),
                )}{' '}
                {t('shared:attendees')}
              </Typography>
            )}
        </div>
        {showFollowButton && (
          <BashButton
            type={BashButtonType.PRIMARY_LIGHT}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onFollowClick(host)
            }}
            height={32}
          >
            {following ? t('following') : t('follow')}
          </BashButton>
        )}
      </div>
    </Link>
  )
}

export default HostCard
