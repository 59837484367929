import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Check, Close, MoreVert } from '@material-ui/icons'
import cn from 'clsx'
import Typography from '@material-ui/core/Typography'
import apiClient from '../../utils/ApiClient'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../../selectors/event'
import GuestInfoSheet from '../../../components/modals/GuestInfoSheet'
import { AddFriendButton } from './GuestModal'
import { editEvent } from '../../../actions/event'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useIsLoggedIn } from 'utils/hooks'

const useStyles = makeStyles((theme) => ({
  guestItemRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.gray[200]}`,
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 100,
  },
  name: {
    fontWeight: 500,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifySelf: 'flex-end',
  },
  requestButton: {
    width: 32,
    height: 32,
    borderRadius: 100,
    minWidth: 0,
    minHeight: 0,
    border: '1px solid',
  },
  requestButtonLabel: {
    width: 'auto',
  },
  approveButton: {
    color: theme.palette.primary.main,
  },
  rejectButton: {
    color: theme.palette.red.main,
  },
  textDisabled: {
    color: theme.palette.gray[600],
  },
  disabled: {
    pointerEvents: 'none',
  },
  greyedOut: {
    opacity: 0.5,
  },
}))

export const GuestItem = ({ guest, setGuest }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const event = useSelector(getCurrentEvent)
  const router = useRouter()
  const { t } = useTranslation('common')
  const [localGuest, setLocalGuest] = useState(guest)
  const guestToUse = setGuest != null ? guest : (localGuest ?? guest)
  const setGuestToUse = setGuest ? setGuest : setLocalGuest
  const [approving, setApproving] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  const [detailOpen, setDetailOpen] = useState(false)
  const isLoggedIn = useIsLoggedIn()

  const hasMultipleJoinOptions = (event.joinOptions?.length ?? 0) > 1

  const secondaryText = () => {
    if (!event.host) return null

    const parts = [
      hasMultipleJoinOptions && guestToUse.tickets?.[0]?.name != null
        ? guestToUse.tickets[0].name?.slice(0, 68)
        : null,
      (guestToUse.totalTicketCount ?? 1) > 1
        ? `${t('groupOf')} ${guestToUse.totalTicketCount}`
        : null,
    ].filter(Boolean)
    return parts.join(' · ')
  }

  const onGuestClicked = () => {
    if (event.host) {
      setDetailOpen(true)
    } else if (guestToUse?.user?.id && isLoggedIn) {
      router.push(`/users/${guestToUse?.user?.id}`)
    }
  }

  const onApprove = async () => {
    setApproving(true)
    const result = await apiClient.guest.approve(guest.id)
    setGuestToUse((prev) => ({
      ...prev,
      status: 'JOINED',
    }))
    dispatch(
      editEvent({
        ...event,
        statusCounts: {
          ...event.statusCounts,
          going: event.statusCounts?.going + 1,
          joined: event.statusCounts?.joined + 1,
          requested: event.statusCounts?.requested - 1,
        },
      }),
    )
    setApproving(false)
  }

  const onReject = async () => {
    setRejecting(true)
    const result = await apiClient.guest.reject(guest.id)
    setGuestToUse((prev) => ({
      ...prev,
      status: 'REQUEST_DENIED',
    }))
    dispatch(
      editEvent({
        ...event,
        statusCounts: {
          ...event.statusCounts,
          requested: event.statusCounts?.requested - 1,
        },
      }),
    )
    setRejecting(false)
  }

  const secondary = secondaryText()

  return (
    <>
      <div
        onClick={onGuestClicked}
        className={cn(
          classes.guestItemRoot,
          (event.host || guestToUse.user?.id) && isLoggedIn && classes.pointer,
          !guestToUse.user && !guestToUse.name && classes.greyedOut,
        )}
      >
        <img
          src={guestToUse.user?.avatarUrls?.lg ?? guestToUse.avatarUrls?.lg}
          className={cn(classes.avatar)}
          alt='Avatar'
        />
        <div
          className={cn(
            classes.text,
            guestToUse.status === 'REQUEST_DENIED' && classes.textDisabled,
          )}
        >
          <Typography variant='subtitle1' className={classes.name}>
            {guestToUse.user?.name ??
              guestToUse.name ??
              guestToUse.emailAddress}
          </Typography>
          {secondary && (
            <Typography variant='caption' style={{ marginTop: 4 }}>
              {secondary}
            </Typography>
          )}
        </div>
        <div className={classes.right}>
          {guestToUse.status === 'REQUESTED' && (
            <>
              <Button
                className={cn(classes.requestButton, classes.rejectButton)}
                classes={{ label: classes.requestButtonLabel }}
                onClick={onReject}
              >
                {rejecting ? (
                  <CircularProgress color='red' size={20} />
                ) : (
                  <Close />
                )}
              </Button>

              <Button
                className={cn(classes.requestButton, classes.approveButton)}
                classes={{ label: classes.requestButtonLabel }}
                onClick={onApprove}
              >
                {approving ? <CircularProgress size={20} /> : <Check />}
              </Button>
            </>
          )}

          {guestToUse.status === 'JOINED' && guest.status === 'REQUESTED' && (
            <Typography variant='caption'>{t('approved')}</Typography>
          )}
          {guestToUse.status === 'REQUEST_DENIED' && (
            <Typography variant='caption'>{t('rejected')}</Typography>
          )}

          {guestToUse.status !== 'REQUESTED' && !setGuest && (
            <AddFriendButton guest={guestToUse} />
          )}

          {guestToUse.status !== 'REQUESTED' && event.host && !setGuest && (
            <IconButton
              className={classes.moreButton}
              size='small'
              onClick={() => setDetailOpen(true)}
            >
              <MoreVert />
            </IconButton>
          )}
        </div>
      </div>
      <GuestInfoSheet
        onClose={() => setDetailOpen(false)}
        open={detailOpen}
        activeInfoGuest={guestToUse}
        setGuest={setGuest ?? setLocalGuest}
      />
    </>
  )
}

const GuestList = ({ guests }) => {
  return guests.map((g) => <GuestItem key={g.id} guest={g} />)
}

export default GuestList
