import React from 'react'

const CantOutline = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect x='1' y='1' width='22' height='22' rx='2' fill='white' />
    <path fillRule='evenodd' clipRule='evenodd' d='M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM15.231 16.5L16.5 15.231L13.269 12L16.5 8.769L15.231 7.5L12 10.731L8.769 7.5L7.5 8.769L10.731 12L7.5 15.231L8.769 16.5L12 13.269L15.231 16.5Z' fill='#EE6C4D' />
  </svg>
)

export default CantOutline
