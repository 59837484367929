import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useCurrentEvent } from 'utils/hooks'
import { useDownloadApp } from 'shared-components/utils/downloadApp'
import MultimediaViewer from './media/MultimediaViewer'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.light,
    overflow: 'hidden',
    display: 'flex',
    border: theme.palette.gray.main + ' solid 1px',
    '@media not all and (min-width:900px)': {
      marginTop: theme.spacing(2),
    },
    position: 'relative',
    borderRadius: 8,
  },
  rootNoImage: {
    minHeight: '200px',
  },
  image: {
    width: '100%',
    maxHeight: '480px',
    objectFit: 'cover',
  },
  imageCover: {
    objectFit: 'cover',
  },
  perk: {
    borderRadius: theme.spacing(0, 2, 2, 0),
    background: '#29061B',
    position: 'absolute',
    top: theme.spacing(1.5),
    left: 1,
    color: 'white',
    padding: '6px 16px 6px 12px',
    border: '1px solid ' + theme.palette.secondary.light,
  },
  '@keyframes shimmer': {
    '50%': {
      '-webkit-mask-position': 'left',
    },
    '100%': {
      '-webkit-mask-position': 'left',
    },
  },
  shimmer: {
    display: 'inline-block',
    '-webkit-mask':
      'linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%',
    backgroundRepeat: 'no-repeat',
    animation: '$shimmer 8s infinite',
    animationDelay: '2s',
  },
  imageWId: {
    backgroundColor: theme.palette.secondary.light,
  },
}))

const EventImage = ({}) => {
  const classes = useStyles()
  const event = useCurrentEvent()

  const { onDownloadClick, QRCodeModal } = useDownloadApp({
    utm_medium: 'perk-button',
    event,
    usingHref: false,
  })

  return (
    <>
      <MultimediaViewer />
      <QRCodeModal />
    </>
  )
}

export default EventImage
