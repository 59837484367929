type MediaType = 'VIDEO' | 'IMAGE' | 'EMPTY'
type MediaContent = Video | Image

type Media = {
  id: number
  type: MediaType
  position: number
  content: MediaContent
}

type ImageStatus = 'PENDING' | 'FINAL' | 'REPLACING'

type Image = {
  id: number
  format: string
  status: ImageStatus
  uploadUrl: string | null
  imageUrls: Record<string, string>
}

type VideoStatus = 'PENDING' | 'CREATED' | 'FAILED' | 'SKIPPED'
type UrlAndStatus = {
  url: string | null
  status: VideoStatus
}

type Video = {
  id: number
  status: VideoStatus
  originalUrl: string | null
  thumbnail: UrlAndStatus
  fsdVideo: UrlAndStatus
  hdVideo: UrlAndStatus
  uploadUrl: string | null
  updatedAt: string
  ratio: number | null
}

export const getVideoUrl = (media: Media) => {
  if (media.type !== 'VIDEO') return null
  const content = media.content as Video

  return content.hdVideo.url ?? content.fsdVideo.url ?? content.originalUrl
}
