import React from 'react'

const MapMarkerLock = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M18 9C18 5.13 14.86 2 11 2C9.14348 2 7.36301 2.7375 6.05025 4.05025C4.7375 5.36301 4 7.14348 4 9C4 14.25 11 22 11 22C11 22 12.4316 20.415 14 18.193V16C14 13.9978 15.4711 12.3392 17.3913 12.046C17.7692 10.9892 18 9.95336 18 9ZM11.9567 11.3097C11.6534 11.4353 11.3283 11.5 11 11.5C10.337 11.5 9.70107 11.2366 9.23223 10.7678C9.00009 10.5356 8.81594 10.26 8.6903 9.95671C8.56466 9.65339 8.5 9.3283 8.5 9C8.5 8.33696 8.76339 7.70107 9.23223 7.23223C9.70107 6.76339 10.337 6.5 11 6.5C11.3283 6.5 11.6534 6.56466 11.9567 6.6903C12.26 6.81594 12.5356 7.00009 12.7678 7.23223C12.9999 7.46438 13.1841 7.73998 13.3097 8.04329C13.4353 8.34661 13.5 8.6717 13.5 9C13.5 9.3283 13.4353 9.65339 13.3097 9.95671C13.1841 10.26 12.9999 10.5356 12.7678 10.7678C12.5356 10.9999 12.26 11.1841 11.9567 11.3097Z' fill='currentcolor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M21 16V15.5C21 14.837 20.7366 14.2011 20.2678 13.7322C19.7989 13.2634 19.163 13 18.5 13C17.837 13 17.2011 13.2634 16.7322 13.7322C16.2634 14.2011 16 14.837 16 15.5V16C15.7348 16 15.4804 16.1054 15.2929 16.2929C15.1054 16.4804 15 16.7348 15 17V21C15 21.2652 15.1054 21.5196 15.2929 21.7071C15.4804 21.8946 15.7348 22 16 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V17C22 16.7348 21.8946 16.4804 21.7071 16.2929C21.5196 16.1054 21.2652 16 21 16ZM20 16H17V15.5C17 15.1022 17.158 14.7206 17.4393 14.4393C17.7206 14.158 18.1022 14 18.5 14C18.8978 14 19.2794 14.158 19.5607 14.4393C19.842 14.7206 20 15.1022 20 15.5V16Z' fill='currentcolor' />
  </svg>
)

export default MapMarkerLock
