import React from 'react'
import { useTranslation } from 'react-i18next'
import TextBadge from './TextBadge'

const HostBadge = ({ ...props }) => {
  const { t } = useTranslation('common')
  return (
    <TextBadge {...props}>
      {t('host')}
    </TextBadge>
  )
}

export default HostBadge
