import React from 'react'
import Head from 'next/head'
import DOMPurify from 'isomorphic-dompurify'

export default function StructuredData ({ data }) {
  return (
    <Head>
      <script
        key='structured-data'
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(JSON.stringify(data)) }}
      />
    </Head>
  )
}
