import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIcon, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import cn from 'clsx'
import VerifiedIcon from 'svg/CheckDecagram'
import ClickableAvatar from 'shared-components/avatar/ClickableAvatar'
import { openUserInfo } from '../../actions/modals'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { useIsLoggedIn } from '../../utils/hooks'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1.5, 0),
    cursor: 'pointer',
  },
  rootLarge: {
    margin: theme.spacing(2, 0),
  },
  avatar: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },
  avatarLarge: {
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(2),
  },
  avatarSmall: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
  },
  textSection: {
    marginRight: 'auto',
    overflow: 'hidden',
    textAlign: 'left',
  },
  name: {
    fontWeight: '500',
  },
  clickable: {
    cursor: 'pointer',
  },
  verifiedIcon: {
    width: 16,
    height: 16,
    marginLeft: 4,
  },
  pointer: {
    cursor: 'pointer',
  },
}))

const ProfileListItem = ({
  profile,
  buttonSection,
  mutualFriendCount,
  onClick,
  showFriendsFollowing,
  small,
  className,
  large,
  noWrap,
  bottomText,
  disableClick = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const isLoggedIn = useIsLoggedIn()

  const onNavigate = () => {
    onClick && onClick(profile)
  }

  const followingFriends = () => {
    if (!showFriendsFollowing) return null
    const friendsFollowing = profile.friendsFollowing
    if (friendsFollowing && friendsFollowing.length > 2) {
      return (
        t('searchResults.followedBy') +
        friendsFollowing[0].name +
        ', ' +
        friendsFollowing[1].name +
        ', ' +
        friendsFollowing[2].name
      )
    } else if (friendsFollowing && friendsFollowing.length > 1) {
      return (
        t('searchResults.followedBy') +
        friendsFollowing[0].name +
        ', ' +
        friendsFollowing[1].name
      )
    } else if (friendsFollowing && friendsFollowing.length > 0) {
      return t('searchResults.followedBy') + friendsFollowing[0].name
    } else {
      return null
    }
  }

  const onDetail = () => {
    if (isLoggedIn) {
      router.push(`/users/${profile.id}`)
    } else {
      dispatch(openUserInfo(profile))
    }
  }

  return (
    <div
      onClick={onNavigate}
      className={cn(className, classes.root, {
        [classes.clickable]: onClick,
        [classes.rootLarge]: large,
      })}
    >
      <ClickableAvatar
        disableClick={disableClick || onClick}
        className={cn(classes.avatar, {
          [classes.avatarLarge]: large,
          [classes.avatarSmall]: small,
        })}
        userOrGuest={profile}
      />
      <div
        onClick={disableClick || onClick ? undefined : onDetail}
        className={cn(classes.textSection, !disableClick && classes.pointer)}
      >
        <Typography
          noWrap={noWrap}
          style={{ display: 'flex', marginBottom: 2, alignItems: 'center' }}
          variant={small ? 'caption' : 'body2'}
          color={small ? 'textSecondary' : 'textPrimary'}
        >
          {profile.name}{' '}
          {profile.verified && (
            <SvgIcon
              component={VerifiedIcon}
              className={classes.verifiedIcon}
              color='secondary'
            />
          )}
        </Typography>
        {!!mutualFriendCount && (
          <Typography variant='caption' color='textSecondary'>
            {mutualFriendCount} {t('mutualFriends')}
          </Typography>
        )}
        {showFriendsFollowing && (
          <Typography variant='caption' color='textSecondary'>
            {followingFriends()}
          </Typography>
        )}
        {bottomText && (
          <Typography variant='caption' color='textSecondary'>
            {bottomText}
          </Typography>
        )}
      </div>
      {buttonSection}
    </div>
  )
}

export default ProfileListItem
