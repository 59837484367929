import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles, Typography } from '@material-ui/core'
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
} from '@material-ui/icons'
// import ManageAccounts from '@material-ui/icons/ManageAccounts'
import apiClient from 'shared-components/utils/ApiClient'
import FullScreenSheet from './FullScreenSheet'
import { GuestItem } from '../../shared-components/event/guestList/GuestList'
import MixpanelLinkify from '../../shared-components/common/MixpanelLinkify'

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    height: '100%',
    maxHeight: 500,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(0, 2, 2, 2),
    overflowY: 'auto',
  },
  answerContainer: {
    padding: theme.spacing(2),
    borderRadius: 8,
    border: `1px solid ${theme.palette.gray[200]}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  question: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.gray[200],
  },
  answer: {
    lineHeight: '1.3125rem',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    lineHeight: '1.3125rem',
  },
  optionsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}))

const GuestAnswersSheet = ({ guest, setGuest, open, onClose }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const dispatch = useDispatch()
  const [answers, setAnswers] = useState([])
  const [loading, setLoading] = useState(false)

  const loadAnswers = async () => {
    setLoading(true)
    const results = await apiClient.event.getGuestResponses(guest?.id)
    setAnswers(results)
    setLoading(false)
  }

  const getQuestionTitle = (q) => {
    if (q.type === 'EMAIL_ADDRESS') return t('questions.emailAddress')
    if (q.type === 'PHONE_NUMBER') return t('questions.phoneNumberQuestion')
    return q.caption
  }

  useEffect(() => {
    if (open) {
      setAnswers([])
      loadAnswers()
    }
  }, [open, guest.id])

  const getOtherOption = (answer) => {
    return answer.response?.model?.options?.find(
      (option) => !answer.question.properties?.options?.includes(option),
    )
  }

  return (
    <FullScreenSheet
      className={classes.dialogRoot}
      open={open}
      onClose={onClose}
      title={guest?.user?.name ?? guest?.name}
    >
      <div className={classes.root}>
        <div className={classes.profileContainer}>
          <GuestItem guest={guest} setGuest={setGuest} />
        </div>

        {answers.map((a) => (
          <div className={classes.answerContainer} key={a.id}>
            <Typography variant='body2' className={classes.question}>
              {a.response.type === 'BOOLEAN' && (
                <>
                  {a.response.model.boolean === true && (
                    <CheckBoxOutlined color='primary' />
                  )}
                  {a.response.model.boolean !== true && (
                    <CheckBoxOutlineBlank />
                  )}
                </>
              )}
              {getQuestionTitle(a.question)}
            </Typography>
            {a.response.type !== 'BOOLEAN' && (
              <div className={classes.divider} />
            )}
            {['TEXT', 'CONTACT_INFO', 'NUMBER', 'DATE'].includes(
              a.response.type,
            ) && (
              <Typography variant='body2' className={classes.answer}>
                <MixpanelLinkify>
                  {a.response.type === 'TEXT' && a.response.model.text}
                  {a.response.type === 'CONTACT_INFO' && a.response.model.value}
                  {a.response.type === 'NUMBER' && a.response.model.number}
                  {a.response.type === 'DATE' && a.response.model.date}
                </MixpanelLinkify>
              </Typography>
            )}
            {['CHECKBOX', 'RADIO', 'CHECKBOX_OTHER', 'RADIO_OTHER'].includes(
              a.question.type,
            ) && (
              <div className={classes.optionsList}>
                {a.question.properties.options.map((o) => (
                  <div className={classes.option}>
                    {a.response.model.options.includes(o) && (
                      <>
                        {['RADIO', 'RADIO_OTHER'].includes(a.question.type) && (
                          <RadioButtonCheckedOutlined color='primary' />
                        )}
                        {['CHECKBOX', 'CHECKBOX_OTHER'].includes(
                          a.question.type,
                        ) && <CheckBoxOutlined color='primary' />}
                      </>
                    )}
                    {!a.response.model.options.includes(o) && (
                      <>
                        {['RADIO', 'RADIO_OTHER'].includes(a.question.type) && (
                          <RadioButtonUncheckedOutlined />
                        )}
                        {['CHECKBOX', 'CHECKBOX_OTHER'].includes(
                          a.question.type,
                        ) && <CheckBoxOutlineBlank />}
                      </>
                    )}
                    <Typography variant='body2'>{o}</Typography>
                  </div>
                ))}
                {getOtherOption(a) != null && (
                  <div className={classes.option}>
                    <>
                      {['RADIO', 'RADIO_OTHER'].includes(a.question.type) && (
                        <RadioButtonCheckedOutlined color='primary' />
                      )}
                      {['CHECKBOX', 'CHECKBOX_OTHER'].includes(
                        a.question.type,
                      ) && <CheckBoxOutlined color='primary' />}
                    </>
                    <Typography variant='body2'>{`${t('event:other')}: ${getOtherOption(a)}`}</Typography>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </FullScreenSheet>
  )
}

export default GuestAnswersSheet
