import { Button, makeStyles, Typography } from '@material-ui/core'
import { AddBox, Edit, PeopleAlt, PersonAdd } from '@material-ui/icons'
import { mdiChatOutline } from '@mdi/js'
import { setLocBeforeEdit } from 'actions/event'
import { setCurrentScrapeBotId } from 'actions/scraper'
import cn from 'clsx'
import ActionOverflow from 'components/event/actions/ActionOverflow'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getIsEventsAdmin } from 'selectors/event'
import useMixpanelTrackEvent from 'utils/useMixpanelTrackEvent'
import {
  openCreatePostModal,
  openGuestManagement,
  openInviteGuestsModal,
  openScanner,
} from '../../actions/modals'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  containerMobile: {
    padding: theme.spacing(0, 2),
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    background: theme.palette.grey[100],
    flexDirection: 'row',
    width: '100%',
    maxWidth: theme.containerMaxWidth,
    justifyContent: 'space-between',
    borderRadius: theme.spacing(1),
    gap: theme.spacing(1),
  },
  mobileMode: {
    flexDirection: 'column',
    // overflow: 'auto',
    alignItems: 'flex-start',
    margin: theme.spacing(2),
    marginBottom: theme.spacing(0),
    padding: 0,
  },
  title: {
    color: theme.palette.grey[800],
    fontWeight: '700',
  },
  titleMobile: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    overflowX: 'auto', // Enable horizontal scrolling
    justifyContent: 'center',
  },
  innerContainer: {
    display: 'flex',
    whiteSpace: 'nowrap', // Prevent buttons from wrapping
    alignItems: 'center',
    '& > *': {
      flexShrink: 0, // Prevent buttons from shrinking
    },
    gap: theme.spacing(1),
  },
  scannerButton: {
    opacity: 0.5,
    color: theme.palette.grey[800],
    borderRadius: 10000,
    padding: theme.spacing(1, 1.5),
  },
  colorPrimary800: {
    color: theme.palette.grey[800],
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    borderRadius: 10000,
    padding: theme.spacing(1, 1.5),
  },
  buttonContainerMobile: {
    justifyContent: 'flex-start',
    overflowX: 'auto',
    padding: theme.spacing(0, 2),
    paddingBottom: theme.spacing(1),
    width: '100%',
  },
}))

const EditEventHeader = ({ event, desktopMode }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const isEventsAdmin = useSelector(getIsEventsAdmin)
  const containerRef = useRef(null)

  const trackEvent = useMixpanelTrackEvent(event)
  const router = useRouter()

  const onEditClicked = () => {
    trackEvent('Edit Bash')
    dispatch(setLocBeforeEdit(router.asPath))
    dispatch(setCurrentScrapeBotId(null))
    router.push(`/edit/${event.code}`)
  }

  const inviteClicked = () => {
    dispatch(openInviteGuestsModal())
  }

  const messageClicked = () => {
    dispatch(openCreatePostModal())
  }

  const scannerClicked = () => {
    dispatch(openScanner())
  }

  const manageGuestsClicked = () => {
    dispatch(openGuestManagement('guestList'))
  }

  return (
    <div
      className={cn(classes.container, !desktopMode && classes.containerMobile)}
    >
      <div className={cn(classes.root, !desktopMode && classes.mobileMode)}>
        <Typography
          variant='overline'
          className={cn(classes.title, !desktopMode && classes.titleMobile)}
        >
          {t('manageThisEvent')}
        </Typography>
        <div
          ref={containerRef}
          className={cn(
            classes.buttonContainer,
            !desktopMode && classes.buttonContainerMobile,
          )}
        >
          <div className={classes.innerContainer}>
            <Button
              className={classes.colorPrimary800}
              onClick={inviteClicked}
              startIcon={<PersonAdd />}
            >
              {t('invite')}
            </Button>

            <Button
              className={classes.colorPrimary800}
              onClick={onEditClicked}
              startIcon={<Edit />}
            >
              {t('shared:edit')}
            </Button>
            {/*<AddPhotoButton noStyle />*/}

            <Button
              className={classes.colorPrimary800}
              onClick={messageClicked}
              startIcon={
                <AddBox>
                  <path d={mdiChatOutline} />
                </AddBox>
              }
            >
              Post
            </Button>

            <Button
              onClick={manageGuestsClicked}
              className={classes.colorPrimary800}
              startIcon={<PeopleAlt />}
            >
              {t('manageGuests')}
            </Button>

            <ActionOverflow
              location='web_event_page'
              items={
                isEventsAdmin
                  ? [
                      'analytics',
                      'embed',
                      'duplicate',
                      ...(event.scrapeBotId ? ['reject'] : []),
                      'merge',
                      'cancel',
                    ]
                  : event.host
                    ? ['analytics', 'embed', 'duplicate', 'cancel']
                    : []
              }
              small
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditEventHeader
