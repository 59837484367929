import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React, { useState } from 'react'
import { useRouter } from 'next/router'
import apiClient from '../../../shared-components/utils/ApiClient'
import cn from 'clsx'
import Typography from '@material-ui/core/Typography'
import { Check, Close } from '@material-ui/icons'
import GuestInfoSheet from '../GuestInfoSheet'
import dayjs from 'dayjs'
import { getGuestName } from '../../../shared-components/utils/eventDisplay'
import { updateStatusCounts } from './GuestManagementChangeStatus'

const useStyles = makeStyles((theme) => ({
  guestItemRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0),
    margin: theme.spacing(0, 4),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.gray[200]}`,
    },
  },
  colorGray600: {
    color: theme.palette.gray[600],
  },
  pointer: {
    cursor: 'pointer',
  },
  avatarContainer: {
    position: 'relative',
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 100,
  },
  avatarCheckContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 40,
    height: 40,
    borderRadius: 100,
    background: 'rgba(0,82,37,0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarCheck: {
    color: '#fff',
  },
  avatarTransparent: {
    opacity: 0.5,
  },
  name: {
    fontWeight: 500,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifySelf: 'flex-end',
  },
  requestButton: {
    width: 32,
    height: 32,
    borderRadius: 100,
    minWidth: 0,
    minHeight: 0,
    border: '1px solid',
  },
  requestButtonLabel: {
    width: 'auto',
  },
  approveButton: {
    color: theme.palette.primary.main,
    width: 'auto',
    padding: theme.spacing(0, 2),
  },
  rejectButton: {
    color: theme.palette.red.main,
  },
  textDisabled: {
    color: theme.palette.gray[600],
  },
  disabled: {
    pointerEvents: 'none',
  },
}))

const GuestManagementItem = ({ guest, showRightDetail, listStatus }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const event = useSelector(getCurrentEvent)
  const router = useRouter()
  const { t } = useTranslation('common')
  const [localGuest, setLocalGuest] = useState(guest)
  const guestToUse = localGuest
  const setGuestToUse = setLocalGuest
  const [approving, setApproving] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  const [detailOpen, setDetailOpen] = useState(false)

  const showChecked =
    guestToUse.status === 'JOINED' &&
    guestToUse.activeTicketCount === 0 &&
    guestToUse.totalTicketCount > 0

  const secondaryText = () => {
    const timestamp = dayjs(getTimestamp()).fromNow(false)
    let ticketName =
      guestToUse.joinOption?.idInfo?.source === 'EVENTIX'
        ? 'Eventix ticket'
        : guestToUse.tickets
          ? (guestToUse.tickets?.[0]?.name ?? t('addedByOrganiser'))
          : null
    if (guestToUse.totalTicketCount > 1 || guestToUse.tickets?.length > 1) {
      const toAdd = `(${guestToUse.status === 'REQUESTED' ? guestToUse.tickets?.length : guestToUse.totalTicketCount})`
      if (!ticketName) return toAdd
      return ticketName + ` ${toAdd} • ${timestamp}`
    } else {
      return ticketName ? ticketName + ` • ${timestamp}` : timestamp
    }
  }

  const getTimestamp = () => {
    let dateToUse = guestToUse.lastActivityAt
    if (listStatus === 'WAITLIST') {
      dateToUse = guestToUse.waitingListUpdatedAt
    } else if (listStatus === 'HYPE') {
      dateToUse = guestToUse.hypeUpdatedAt
    } else if (listStatus === 'NONE') {
      dateToUse = guestToUse.createdAt
    }
    return dateToUse
  }

  const rightText = () => {
    if (guestToUse.status === 'JOINED' && guestToUse.totalTicketCount > 1) {
      return `${guestToUse.totalTicketCount - guestToUse.activeTicketCount}/${guestToUse.totalTicketCount}`
    } else if (showRightDetail) {
      if (event.type === 'PINNING' && guestToUse.gaveDateOptions === true) {
        return t('going')
      }
      if (['JOINED', 'GOING'].includes(guestToUse.status)) {
        return t('going')
      }
      if (guestToUse.status === 'REQUESTED') {
        return null
      }
      if (guestToUse.status === 'REQUEST_DENIED') {
        return null
      }
      if (guestToUse.status === 'MAYBE') {
        return `‘${t('maybe')}’`
      }
      if (guestToUse.status === 'CANT') {
        return `‘${t('cant')}’`
      }
      if (!guestToUse.status) {
        if (guestToUse.hype) {
          return t('hyped')
        } else if (guestToUse.waitingList) {
          return t('waitList')
        } else {
          return t('invited')
        }
      }
    }

    return null
  }

  const onGuestClicked = () => {
    setDetailOpen(true)
  }

  const onApprove = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setApproving(true)
    const result = await apiClient.guest.approve(guest.id)
    setGuestToUse((prev) => ({
      ...prev,
      status: 'JOINED',
      totalTicketCount: prev.tickets?.length,
    }))
    updateStatusCounts(event, dispatch, guestToUse, 'REQUESTED', 'JOINED')
    setApproving(false)
  }

  const onReject = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setRejecting(true)
    const result = await apiClient.guest.reject(guest.id)
    setGuestToUse((prev) => ({
      ...prev,
      status: 'REQUEST_DENIED',
    }))
    updateStatusCounts(
      event,
      dispatch,
      guestToUse,
      'REQUESTED',
      'REQUEST_DENIED',
    )
    setRejecting(false)
  }

  const secondary = secondaryText()
  const right = rightText()

  return (
    <>
      <div
        onClick={onGuestClicked}
        className={cn(
          classes.guestItemRoot,
          classes.pointer,
          (showChecked || localGuest.status !== guest.status) &&
            classes.colorGray600,
        )}
      >
        <div className={classes.avatarContainer}>
          <img
            src={guestToUse.user?.avatarUrls?.lg ?? guestToUse.avatarUrls?.lg}
            className={cn(
              classes.avatar,
              localGuest.status !== guest.status && classes.avatarTransparent,
            )}
            alt='Avatar'
          />
          {showChecked && (
            <div className={classes.avatarCheckContainer}>
              <Check className={classes.avatarCheck} />
            </div>
          )}
        </div>
        <div
          className={cn(
            classes.text,
            guestToUse.status === 'REQUEST_DENIED' && classes.textDisabled,
          )}
        >
          <Typography variant='subtitle1' className={classes.name}>
            {getGuestName(guestToUse) ?? guestToUse.emailAddress}
          </Typography>
          {secondary && (
            <Typography variant='caption' style={{ marginTop: 4 }}>
              {secondary}
            </Typography>
          )}
          {/*{showTimestamp && (*/}
          {/*  <Typography variant='caption' style={{ marginTop: 4 }}>*/}
          {/*    {dayjs(getTimestamp()).fromNow(false)}*/}
          {/*  </Typography>*/}
          {/*)}*/}
        </div>
        <div className={classes.right}>
          {guestToUse.status === 'REQUESTED' && (
            <>
              <Button
                className={cn(classes.requestButton, classes.rejectButton)}
                classes={{ label: classes.requestButtonLabel }}
                onClick={onReject}
              >
                {rejecting ? (
                  <CircularProgress color='red' size={20} />
                ) : (
                  <Close />
                )}
              </Button>

              <Button
                className={cn(classes.requestButton, classes.approveButton)}
                classes={{ label: classes.requestButtonLabel }}
                onClick={onApprove}
                startIcon={
                  approving ? <CircularProgress size={20} /> : undefined
                }
              >
                {t('accept')}
              </Button>
            </>
          )}

          {guestToUse.status === 'JOINED' && guest.status === 'REQUESTED' && (
            <Typography variant='caption'>{t('approved')}</Typography>
          )}
          {guestToUse.status === 'REQUEST_DENIED' && (
            <Typography variant='caption'>{t('rejected')}</Typography>
          )}

          {right != null && <Typography variant='caption'>{right}</Typography>}
        </div>
      </div>
      <GuestInfoSheet
        onClose={() => setDetailOpen(false)}
        open={detailOpen}
        activeInfoGuest={guestToUse}
        setGuest={setLocalGuest}
      />
    </>
  )
}

export default GuestManagementItem
