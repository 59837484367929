import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import InfiniteScroll from 'react-infinite-scroller'
import apiClient from '../../shared-components/utils/ApiClient'
import FullScreenSheet from '../modals/FullScreenSheet'
import { getCurrentEvent } from '../../selectors/event'
import SocialProofItem from '../user/SocialProofItem'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // marginTop: theme.spacing(19)
  },
  scrollContainer: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'flex-start',
    width: '100%',
    margin: '0 auto',
    maxHeight: 500,
    '& > *': {
      width: '100%',
      gap: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 400,
    },
  },
  progress: {
    margin: theme.spacing(2, 'auto', 0, 'auto'),
  },
}))

const SOCIAL_PROOF_SIZE = 10

const SocialProofModal = ({ desktopMode, open, onClose }) => {
  const router = useRouter()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const scrollRef = useRef()

  const [currentPage, setCurrentPage] = useState(0)

  const loadMore = async () => {
    if (!hasMore || loading || !event.id) return
    setLoading(true)
    try {
      const response = await apiClient.event.getFriendActivity(
        event.id,
        currentPage,
        SOCIAL_PROOF_SIZE,
      )
      if (response && response.length > 0) {
        setItems((prev) => [...prev, ...response])
        setHasMore(response.length === SOCIAL_PROOF_SIZE)
        setCurrentPage(currentPage + 1)
      } else {
        setHasMore(false)
      }
    } catch (error) {
      console.error('Error fetching more activity:', error)
    } finally {
      setLoading(false)
    }
  }

  const changeItem = (id, changes) => {
    setItems((prev) =>
      prev.map((p) => (p.id === id ? { ...p, ...changes } : p)),
    )
  }

  useEffect(() => {
    if (!open) {
      setItems([])
      setHasMore(true)
      setCurrentPage(0)
    }
  }, [open])

  return (
    <FullScreenSheet title={t('friendResponses')} open={open} onClose={onClose}>
      <div
        ref={scrollRef}
        id='scrollableDiv'
        className={classes.scrollContainer}
      >
        <InfiniteScroll
          useWindow={false}
          pageStart={-1}
          loadMore={loadMore}
          getScrollParent={() => scrollRef.current}
          hasMore={!loading && open && hasMore}
          loader={
            <CircularProgress
              key='loader'
              className={classes.progress}
              size={40}
            />
          }
        >
          {items.map((item, index) => (
            <SocialProofItem onChange={changeItem} item={item} key={index} />
          ))}
          {loading && (
            <CircularProgress
              key='loader'
              className={classes.progress}
              size={40}
            />
          )}
        </InfiniteScroll>
      </div>
    </FullScreenSheet>
  )
}

export default SocialProofModal
