import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { KeyboardArrowDown, Lock, OpenInNew } from '@material-ui/icons'
import cn from 'clsx'
import BetaBadge from 'components/EventCreation/BetaBadge'
import { appContext } from '../../shared-components/event/App'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
    padding: 0,
    overflow: 'hidden',
    borderRadius: 8,
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  pointer: {
    cursor: 'pointer',
  },
  boxed: {
    border: '1px solid ' + theme.palette.grey[200],
    padding: theme.spacing(0, 2, 2, 2),
  },
  rootMobile: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 0,
    paddingBottom: theme.spacing(2),
  },
  header: {
    fontWeight: 700,
  },
  headerRow: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: theme.palette.background.paper,
    alignItems: 'center',
  },
  headerPadding: {
    paddingBottom: theme.spacing(1.5),
  },
  arrow: {
    color: '#424242',
    width: 24,
    height: 24,
  },
  openInNew: {
    color: '#424242',
    width: 16,
    height: 16,
    marginRight: '2px',
  },
  lock: {
    color: '#424242',
    width: 20,
    height: 20,
    marginRight: '2px',
  },
  badgeRow: {
    display: 'flex',
    alignItems: 'center',
  },
  dividerBottom: {
    borderBottom: 'solid 1px ' + theme.palette.grey[200],
    marginBottom: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
  },
}))

const EventSection = ({
  title,
  onMoreClicked,
  children,
  desktopMode,
  arrowRight,
  withBetaBadge,
  boxed,
  hasExternalLink,
  customContent,
  fullClickArea = false,
  hasContent = true,
  blocker = false,
  headerClassName,
  customTitle,
  ...props
}) => {
  const classes = useStyles()
  const { inWidget } = useContext(appContext)

  return (
    <div
      className={cn(
        classes.root,
        !desktopMode && !inWidget && classes.rootMobile,
        boxed && classes.boxed,
        fullClickArea && onMoreClicked && classes.pointer,
      )}
      onClick={fullClickArea ? onMoreClicked : undefined}
      {...props}
    >
      <div
        className={cn(
          classes.headerRow,
          headerClassName,
          hasContent && classes.headerPadding,
          !fullClickArea && onMoreClicked && classes.pointer,
          // desktopMode && classes.dividerBottom,
          classes.dividerBottom,
        )}
        onClick={!fullClickArea ? onMoreClicked : undefined}
      >
        {!customTitle && (
          <div className={classes.badgeRow}>
            <Typography className={classes.title} variant='subtitle2'>
              {title}
            </Typography>
            {withBetaBadge && <BetaBadge />}
          </div>
        )}
        {customTitle != null && customTitle}

        {!blocker && !hasExternalLink && (
          <KeyboardArrowDown
            className={classes.arrow}
            color='primary'
            size={24}
            style={{
              transform: arrowRight ? 'rotate(-90deg)' : 'none',
              opacity: onMoreClicked ? 1 : 0,
            }}
          />
        )}
        {!blocker && hasExternalLink && (
          <OpenInNew className={classes.openInNew} color='primary' size={16} />
        )}

        {blocker && <Lock className={classes.lock} size={16} />}

        {customContent != null && customContent}
      </div>
      {children}
    </div>
  )
}

export default EventSection
