import * as React from 'react'
import Transition from 'react-transition-group/Transition'

// default css props
const defaultStyle = {
  position: 'fixed',
  transform: 'translateY(30px) translateX(-50%)',
  opacity: 0,
  overflow: 'hidden',
  left: '50%',
  width: '100%'
}

// custom css props in 4 state: entering, entered, exiting, exited
const styles = {
  entering: {
    opacity: 1,
    transform: 'translateY(0) translateX(-50%)',
  },
  entered: {
    opacity: 1,
    transform: 'translateY(0) translateX(-50%)',
  },
}

class FullScreenSheetTransition extends React.Component {
  static defaultProps = {
    in: true,
    duration: 0
  };

  render() {
    const {
      children,
      style: styleProp,
      ...other
    } = this.props
    const style = {
      ...styleProp,
      ...(React.isValidElement(children)
        ? (children.props).style
        : {})
    }
    return (
      <Transition timeout={100} {...other}>
        {(state, childProps) => {
          return React.cloneElement(children, {
            style: {
              ...defaultStyle,
              transition: 'transform 150ms ease, opacity 100ms ease',
              // transitionProperty: 'transform, opacity',
              willChange: 'transform, opacity',
              ...styles[state],
              ...style
            },
            ...childProps
          })
        }}
      </Transition>
    )
  }
}

export default FullScreenSheetTransition