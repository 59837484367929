import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { ChevronLeft, Close } from '@material-ui/icons'
import FullScreenSheetTransition from './FullScreenSheetTransition'
import useTheme from '@material-ui/core/styles/useTheme'
import cn from 'clsx'

const useStyles = makeStyles((theme) => ({
  backdropRoot: {
    backgroundColor: theme.palette.background['backdrop'],
  },
  titleContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: 64,
    flexShrink: 0,
  },
  titleBorder: {
    borderBottom: '1px solid ' + theme.palette.grey[200],
  },
  title: {
    padding: theme.spacing(0, 5),
  },
  closeButton: {
    color: theme.palette.text.primary,
    position: 'absolute',
    left: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  right: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  paper: {
    paddingBottom: 'calc(0px + env(safe-area-inset-bottom))',
    transition: 'padding 200ms ease',
    maxWidth: 480,
    width: '100%',
    background: theme.palette.background.paper,
    border: theme.palette.background.modalBorder,
  },
  smallOnMobileContainer: {
    // top: theme.spacing(8),
    borderRadius: 8,
    alignItems: 'flex-start',
  },
  smallOnMobilePaper: {
    width: '95% !important',
    height: 'auto !important',
    borderRadius: 8,
    top: theme.spacing(8),
  },
}))

const FullScreenSheet = ({
  title,
  onClose,
  onBack,
  open,
  children,
  className,
  titleBorder = true,
  hideTitle = false,
  smallOnMobile = false,
  rightContent,
  smallTitle = false,
  ...props
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      transitionDuration={150}
      BackdropProps={{
        classes: {
          root: classes.backdropRoot,
        },
      }}
      classes={{
        paper: cn(
          classes.paper,
          className,
          fullScreen && smallOnMobile && classes.smallOnMobilePaper,
        ),
        container:
          fullScreen && smallOnMobile && classes.smallOnMobileContainer,
      }}
      // TransitionComponent={FadeTransition}
      TransitionComponent={FullScreenSheetTransition}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      {...props}
    >
      {!hideTitle && (title || fullScreen) && (
        <div
          className={cn(
            classes.titleContainer,
            titleBorder && classes.titleBorder,
          )}
        >
          {onClose && (
            <IconButton
              color='inherit'
              className={classes.closeButton}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          )}
          {onBack && (
            <IconButton
              color='inherit'
              className={classes.closeButton}
              onClick={onBack}
            >
              <ChevronLeft />
            </IconButton>
          )}
          <Typography
            noWrap
            className={classes.title}
            variant={smallTitle ? 'subtitle1' : 'h6'}
          >
            {title ?? ''}
          </Typography>
          {rightContent != null && (
            <div className={classes.right}>{rightContent}</div>
          )}
        </div>
      )}
      {children}
    </Dialog>
  )
}

export default FullScreenSheet
