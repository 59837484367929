import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../../selectors/event'
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import apiClient from '../../../shared-components/utils/ApiClient'
import CreatePostModal from './CreatePostModal'
import { useRouter } from 'next/router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { closeChatModal, openCreatePostModal } from '../../../actions/modals'
import PostsMessage from '../posts/PostsMessage'
import EventSection from '../EventSection'
import { Add, KeyboardArrowDown } from '@material-ui/icons'
import AddRsvpMessageBox from '../posts/AddRsvpMessageBox'
import { setPostToAdd } from '../../../actions/rsvp'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  progress: {
    margin: theme.spacing(2),
  },
  rsvpMessageBox: {
    marginBottom: theme.spacing(2),
  },
  emptyContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyAction: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  addPostButton: {},
  noPaddingBottom: {
    paddingBottom: theme.spacing(0.5),
  },
  advancedBoxButton: {
    padding: theme.spacing('6px', 0),
  },
}))

const POSTS_SIZE = 5

const PostsSection = ({ desktopMode }) => {
  const router = useRouter()
  const classes = useStyles()
  const query = router.query
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingNew, setLoadingNew] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const allOpen = useSelector((state) => state.modals.open.chat)
  const [shouldFocusBox, setShouldFocusBox] = useState(false)
  const [filter, setFilter] = useState(null)
  const postToAdd = useSelector((state) => state.rsvp.postToAdd)
  const [titleAnchor, setTitleAnchor] = useState(null)

  const showRsvpPostBox =
    ['JOINED', 'GOING', 'MAYBE', 'CANT'].includes(event.myGuest?.status) &&
    (event.myGuest?.name || event?.myGuest.user) &&
    !event.myGuest?.hasRsvpPosted

  const [currentPage, setCurrentPage] = useState(0)

  const loadMore = async () => {
    if (!hasMore || loading || !event.id) return
    setLoading(true)
    try {
      const response = await apiClient.event.getPosts(
        event.id,
        event.code,
        currentPage,
        POSTS_SIZE,
        event.code,
        filter,
      )
      if (response && response.length > 0) {
        setItems((prev) => [...prev, ...response])
        setHasMore(response.length === POSTS_SIZE)
        setCurrentPage(currentPage + 1)
      } else {
        setHasMore(false)
      }
    } catch (error) {
      console.error('Error fetching more posts:', error)
      setHasMore(false)
    } finally {
      setLoading(false)
    }
  }

  const resetAndLoad = async () => {
    setItems([])
    setCurrentPage(0)
    setHasMore(true)
    await loadMore()
  }

  const loadNew = async () => {
    let latestPostCreatedAt = items[0]?.createdAt
    if (latestPostCreatedAt || items.length < 1) {
      try {
        const newPosts = await apiClient.event.getPosts(
          event.id,
          event.code,
          0,
          POSTS_SIZE,
          event.code,
          filter,
        )
        let freshPosts = newPosts
        if (latestPostCreatedAt) {
          freshPosts = newPosts.filter((post) =>
            dayjs(post.createdAt).isAfter(dayjs(latestPostCreatedAt)),
          )
        }
        if (freshPosts.length > 0) {
          setItems((prev) => [...freshPosts, ...prev])
        }
      } catch (error) {
        console.error('Error fetching new posts:', error)
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      await loadNew()
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [items])

  useEffect(() => {
    if (items.length < 1 && !loading) {
      loadMore()
    }
  }, [items, loading])

  useEffect(() => {
    if (query.chat) {
      dispatch(openCreatePostModal())
      // setAllOpen(true)
    }
  }, [query])

  const onSend = (message) => {
    if (items.some((m) => m.id === message.id)) {
      setItems((prev) => prev.map((m) => (m.id === message.id ? message : m)))
    } else {
      setItems((prev) => [message, ...prev])
    }
  }

  const changeMessage = (id, changes) => {
    setItems((prev) =>
      prev.map((p) => (p.id === id ? { ...p, ...changes } : p)),
    )
  }

  const onAllClose = () => {
    dispatch(closeChatModal())
    setShouldFocusBox(false)
  }

  useEffect(() => {
    if (postToAdd != null) {
      onSend(postToAdd)
      dispatch(setPostToAdd(null))
    }
  }, [postToAdd, event.myGuest])

  const showAddPostButton =
    event.host === true ||
    ['JOINED', 'GOING', 'MAYBE', 'CANT'].includes(event.myGuest?.status)

  if (items.length === 0 && !showAddPostButton) {
    return null
  }

  const onDelete = (id) => {
    setItems(items.filter((item) => item.id !== id))
  }

  return (
    <EventSection
      title={t('activity')}
      desktopMode={desktopMode}
      arrowRight={false}
      headerClassName={showAddPostButton ? classes.noPaddingBottom : undefined}
      customContent={
        showAddPostButton ? (
          <Button
            className={classes.addPostButton}
            startIcon={<Add />}
            color='primary'
            onClick={() => {
              dispatch(openCreatePostModal())
            }}
          >
            Post
          </Button>
        ) : null
      }
      customTitle={
        <>
          <Button
            classes={{
              root: classes.advancedBoxButton,
            }}
            onClick={(e) => setTitleAnchor(e.currentTarget)}
          >
            {filter === 'HOST' ? t('postsHostsOnly') : t('activity')}
            <KeyboardArrowDown />
          </Button>
          <Menu
            id='join-options-menu'
            anchorEl={titleAnchor}
            keepMounted
            open={!!titleAnchor}
            getContentAnchorEl={null}
            onClose={() => setTitleAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{
              list: classes.menuList,
            }}
          >
            <MenuItem
              onClick={() => {
                setFilter(null)
                setTitleAnchor(null)
                resetAndLoad()
              }}
            >
              {t('all')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setFilter('HOST')
                setTitleAnchor(null)
                resetAndLoad()
              }}
            >
              {t('hostsOnly')}
            </MenuItem>
          </Menu>
        </>
      }
    >
      {showRsvpPostBox && (
        <AddRsvpMessageBox className={classes.rsvpMessageBox} onSend={onSend} />
      )}

      <div id='scrollableDiv' className={classes.scrollContainer}>
        {!hasMore && items.length === 0 && !showRsvpPostBox && (
          <div className={classes.emptyContainer}>
            <Typography variant='subtitle1'>{t('postsEmptyTitle')}</Typography>
            {(event.host ||
              ['JOINED', 'GOING', 'MAYBE', 'CANT'].includes(
                event.myGuest?.status,
              )) && (
              <Typography variant='body2'>
                <Trans
                  t={t}
                  i18nKey='postsEmptyCaption'
                  components={{
                    bold: (
                      <span
                        className={classes.emptyAction}
                        onClick={() => dispatch(openCreatePostModal())}
                      />
                    ),
                  }}
                />
              </Typography>
            )}
          </div>
        )}

        <InfiniteScroll
          dataLength={items.length}
          next={loadMore}
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            gap: 24,
          }}
          hasMore={hasMore}
          loader={<CircularProgress className={classes.progress} />}
          scrollThreshold={0.6}
        >
          {items
            .filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.id === item.id),
            )
            .filter((item) => item.poster?.model?.name?.length > 0)
            .map((item) => (
              <PostsMessage
                msg={item}
                onDelete={() => onDelete(item.id)}
                setPost={(post) => {
                  setItems(
                    items.map((item) => (item.id === post.id ? post : item)),
                  )
                }}
              />
            ))}
        </InfiniteScroll>
      </div>

      <CreatePostModal
        open={allOpen}
        onClose={onAllClose}
        onSendMessage={onSend}
        onChangeMessage={changeMessage}
        shouldFocusBox={shouldFocusBox}
        onDelete={onDelete}
      />
    </EventSection>
  )
}

export default PostsSection
