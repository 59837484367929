import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import dynamic from 'next/dynamic'

import LoadingScreen from 'shared-components/common/LoadingScreen'
import { makeStyles, useMediaQuery } from '@material-ui/core'

import PageContentWrapper from 'shared-components/common/PageContentWrapper'
import classNames from 'clsx'
import Head from 'next/head'
import DynamicReduxModal from 'components/modals/DynamicReduxModal'
import GetBashCard from '../../components/event/GetBashCard'
import { getIsEventsAdmin, isHostedByPage } from '../../selectors/event'
import HostsCard from '../../components/event/HostsCard'
import apiClient from '../utils/ApiClient'

import { useRouter } from 'next/router'
import { openRsvpOnStep } from '../../actions/rsvp'
import StructuredData from 'components/StructuredData'
import dayjs from 'dayjs'
import PostsSection from '../../components/event/chat/PostsSection'
import EventImage from '../../components/event/EventImage'
import EventDetailsCard from '../../components/event/EventDetailsCard'
import { setSnackbar } from '../redux/notifications/actions'
import { setUsersWithEvents } from '../../actions/event'
import { closeTicketsModal } from 'actions/modals'
import { useUser } from '../../utils/userFunctions'
import { QueryClient } from '@tanstack/react-query'

import Attendees from './Attendees'
import PageNotFound from '@pages/404'
import EventLocationCard from '../../components/event/EventLocationCard'
import EventDescriptionCard from '../../components/event/EventDescriptionCard'
import EventSocialProofCard from '../../components/event/EventSocialProofCard'
import EventLineUpCard from 'components/event/EventLineUpCard'
import BottomSheet from 'components/common/BottomSheet'
import BuyMoreTicketSelection from 'components/RsvpWizard/TicketsStep'
import EditEventHeader from './EditEventHeader'
import GuestManagementModal from '../../components/modals/guestManagement/GuestManagementModal'
import Script from 'next/script'
import { usePreviousRouteContext } from '../../context/PreviousRouteContext'

const DateOptionsModal = dynamic(
  () => import('shared-components/event/guestList/DateOptionsModal'),
)
const InviteGuestsModal = dynamic(
  () => import('components/modals/InviteGuestsModal'),
)
const ShareSheet = dynamic(() => import('components/modals/ShareSheet'))
const GuestModal = dynamic(
  () => import('shared-components/event/guestList/GuestModal'),
)
const GeneralFooter = dynamic(
  () => import('../../components/event/GeneralFooter'),
)

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    zIndex: 20,
    // [theme.breakpoints.up('922')]: {
    //   maxWidth: 600,
    // },
    // '@media (min-width:922px)': {
    //   maxWidth: 440,
    // },
  },
  leftAndRightContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginTop: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down(922)]: {
      maxWidth: 654,
    },
    '@media not all and (min-width:900px)': {
      marginTop: 0,
    },
  },
  rightContainer: {
    flexShrink: 0,
    marginLeft: theme.spacing(6),
    width: 352,
    '& > *': {},
    [theme.breakpoints.down(424)]: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  stickyContainer: {
    // position: 'sticky',
    // top: 97,
    maxWidth: '380px',
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  dropZone: {
    zIndex: 500,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  ticketsRoot: {
    width: '100%',
    maxWidth: theme.breakpoints.values.container,
    overflow: 'hidden',
    overflowY: 'auto',
    padding: '0px !important',
  },
  eventsInAppCard: {
    background: theme.palette.grey[150],
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(5),
  },
  pageContentWrapper: {
    alignItems: 'center',
  },
}))

export const appContext = React.createContext({})

const getHostName = (host) => host.model.user?.name || host.model.name
const queryClient = new QueryClient()

const PasteImage = dynamic(() => import('./PasteImage'), { ssr: false })

const App = ({
  inDrawer,
  inWidget,
  event,
  organisation,
  isRejected,
  error,
  loading,
  isFulfilled,
  customResponseBar,
  StoreButtons = null,
  useRawAvatarUrl,
  LinkComponent,
  activeGuest,
  t,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  if (organisation) event.organisation = organisation
  const [loadingUsersWithEvents, setLoadingUsersWithEvents] = useState(false)
  const [metaPixel, setMetaPixel] = useState(null)
  const spotsLeft = event.limitNumberOfGuests
    ? event.expectedNumberOfGuests - event.statusCounts?.going
    : event.expectedNumberOfGuests -
      event.statusCounts?.going -
      event.statusCounts?.maybe
  const limitReached = event.limitNumberOfGuests && spotsLeft < 1
  const desktopModeFromQueryState = useMediaQuery('(min-width:922px)')
  const desktopMode = inDrawer ? true : desktopModeFromQueryState
  const router = useRouter()
  const query = router.query
  const queryAction = query?.action

  const { user, ready, isLoggedIn } = useUser()
  const hostedByPage = useSelector(isHostedByPage)
  const isEventsAdmin = useSelector(getIsEventsAdmin)
  const ticketsOpen = useSelector((state) => state.modals.open.tickets)
  const isOrganiser =
    event.host ||
    (event.type !== 'PUBLIC' &&
      Array.isArray(event.organisers) &&
      event.organisers.some((o) => o.id != null && o.id === user.id))

  const isPublicEvent = event.privacyType === 'PUBLIC' || hostedByPage
  const isExternal = event.creationType === 'EXTERNAL'

  const onTicketsClose = () => {
    dispatch(closeTicketsModal())
  }

  const loadUsersWithEvents = async () => {
    if (event.id) {
      setLoadingUsersWithEvents(true)
      const results = await apiClient.event.getGuestsWithEvents(
        event.id,
        user?.id,
      )
      setLoadingUsersWithEvents(false)
      dispatch(setUsersWithEvents(results))
    }
  }

  useEffect(() => {
    if (!loadingUsersWithEvents && !event.usersWithEvents) {
      loadUsersWithEvents()
    }
  }, [])

  useEffect(() => {
    if (queryAction) {
      if (queryAction === 'PROFILE' && !user.hasAvatar) {
        dispatch(openRsvpOnStep('profile'))
      } else if (queryAction === 'APP') {
        dispatch(openRsvpOnStep('app'))
        dispatch(setSnackbar('success', t('common:emailVerified')))
      }
    }
  }, [queryAction])

  useEffect(() => {
    const hasConsentedToCookies = localStorage.getItem('cookies') === 'true'

    if (event.code === '5d83mQ1nNX7y' && hasConsentedToCookies) {
      setMetaPixel('4465069517052498')
    } else {
      setMetaPixel(null)
    }
  }, [event.code])

  // Add listener for cookie consent changes
  useEffect(() => {
    const handleStorageChange = () => {
      const hasConsentedToCookies = localStorage.getItem('cookies') === 'true'
      if (event.code === '5d83mQ1nNX7y' && hasConsentedToCookies) {
        setMetaPixel('4465069517052498')
      } else {
        setMetaPixel(null)
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [event.code])

  const app = {
    event,
    limitReached,
    spotsLeft,
    isPublicEvent,
    pinningDate: event.type === 'PINNING',
    StoreButtons,
    LinkComponent,
    isOrganiser,
    activeGuest,
    desktopMode,
    inWidget,
  }

  const previousLocation = usePreviousRouteContext()

  useEffect(() => {
    if (event.id) {
      const sharedByUserId = router.query.u
      apiClient.event.viewEvent(
        event.id,
        'event_page',
        sharedByUserId,
        previousLocation?.length > 0 ? previousLocation : null,
        query.utm_medium,
        query.utm_campaign,
        query.utm_source,
      )
    }
  }, [event.id])

  useEffect(() => {
    if (metaPixel && window.fbq) {
      // Initialize Meta Pixel
      window.fbq('init', metaPixel)
      window.fbq('track', 'PageView')

      // Cleanup function
      return () => {
        delete window.fbq
        // Remove the script tag
        const scriptTag = document.getElementById('meta-pixel')
        if (scriptTag) scriptTag.remove()
        // Remove noscript pixel image
        const noscriptPixel = document.querySelector(`img[src*="${metaPixel}"]`)
        if (noscriptPixel) noscriptPixel.remove()
      }
    }
  }, [metaPixel])

  if (!isFulfilled)
    return <LoadingScreen isRejected={isRejected} error={error} />
  if (error || isRejected) return <PageNotFound />

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: event.name,
    ...(event.startDate && {
      startDate: dayjs(event.startDate).format('YYYY-MM-DDTHH:MM'),
    }),
    ...(event.endDate && {
      endDate: dayjs(event.endDate).format('YYYY-MM-DDTHH:MM'),
    }),
    ...((event.location || event.address) && {
      location: {
        '@type': 'Place',
        name: event.location ?? event.address,
        ...(event.address && {
          address: {
            '@type': 'PostalAddress',
            streetAddress: event.address,
          },
        }),
      },
    }),
    ...(event.imageUrls?.lg && {
      image: [event.imageUrls.lg],
    }),
    ...(event.description && {
      description: event.description,
    }),
    ...(event.hosts &&
      event.hosts[0] && {
        performer: {
          '@type': 'PerformingGroup',
          name: getHostName(event.hosts[0]),
        },
      }),
  }

  return (
    <appContext.Provider value={app}>
      {metaPixel && (
        <>
          <Script id='meta-pixel'>
            {`
        !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
      `}
          </Script>
          <noscript>
            <img
              height='1'
              width='1'
              style='display:none'
              src={`https://www.facebook.com/tr?id=${metaPixel}&ev=PageView&noscript=1`}
            />
          </noscript>
        </>
      )}
      <Head>
        <meta
          name='description'
          content={
            event?.invitedBy ? t('common:inviteViaBASH') : 'Where Hype Starts'
          }
          key='description'
        />
        {!inDrawer && !inWidget && (
          <title>
            {event.name || t('createEvent') + ' · ' + t('bashSocialEvents')}
          </title>
        )}
        <meta
          key='ogImage'
          property='og:image'
          itemProp='image'
          content={event?.imageUrls?.lg || '/android-chrome-192x192.png'}
        />
        <meta
          key='ogDescription'
          property='og:description'
          content={
            event?.invitedBy ? t('common:inviteViaBASH') : 'Where Hype Starts'
          }
        />
        <meta
          key='ogTitle'
          property='og:title'
          content={event?.name || t('common:inviteViaBASH')}
        />
        {isPublicEvent && (
          <>
            <meta
              key='ogUrl'
              property='og:url'
              content={`${process.env.NEXT_PUBLIC_WEBSITE}${router.asPath}`}
            />
          </>
        )}
      </Head>
      {isPublicEvent && <StructuredData data={structuredData} />}
      <style jsx global>
        {`
          body {
            background: #fff !important;
          }
        `}
      </style>
      <PasteImage />

      <PageContentWrapper
        id={event.id}
        large
        className={classes.pageContentWrapper}
      >
        {!inDrawer &&
          !inWidget &&
          (event.host === true || (isEventsAdmin && hostedByPage)) && (
            <EditEventHeader event={event} desktopMode={desktopMode} />
          )}
        <div
          className={classNames(classes.leftAndRightContainer)}
          style={{
            marginTop: inDrawer || inWidget ? '0px' : undefined,
          }}
        >
          <div className={classNames(classes.mainContainer)}>
            <EventDetailsCard
              inDrawer={inDrawer}
              inWidget={inWidget}
              desktopMode={desktopMode}
            />

            {(!desktopMode || inDrawer) && (
              <EventSocialProofCard desktopMode={desktopMode} />
            )}

            {/* {event.type === 'PINNING' && (
                <SelectDate desktopMode={desktopMode} />
              )} */}

            {/* <EventQuestionsCard desktopMode={desktopMode} /> */}

            {(!desktopMode || inDrawer) &&
              event.hosts &&
              event.hosts.length > 0 && <HostsCard desktopMode={desktopMode} />}

            {(!desktopMode || inDrawer) && (
              <EventLineUpCard desktopMode={desktopMode} />
            )}

            {(!desktopMode || inDrawer) && (
              <Attendees desktopMode={desktopMode} />
            )}

            <EventLocationCard desktopMode={desktopMode} />

            <EventDescriptionCard desktopMode={desktopMode} />

            {user.id || inDrawer ? null : <GetBashCard />}
            {!isExternal && (
              <PostsSection key={event.id} desktopMode={desktopMode} />
            )}
          </div>
          {desktopMode && !inDrawer && (
            <div className={classes.rightContainer}>
              <div className={classes.stickyContainer}>
                <div className={classes.imageContainer}>
                  <EventImage desktopMode={desktopMode} />
                </div>
                {desktopMode && (
                  <EventSocialProofCard desktopMode={desktopMode} />
                )}
                {desktopMode && event.hosts && event.hosts.length > 0 && (
                  <HostsCard desktopMode={desktopMode} />
                )}
                {desktopMode && <EventLineUpCard desktopMode={desktopMode} />}
                {desktopMode && <Attendees desktopMode={desktopMode} />}
              </div>
            </div>
          )}
        </div>
        {event.type === 'PINNING' && <DateOptionsModal />}
        <DynamicReduxModal
          reduxName='inviteGuests'
          DynamicComponent={InviteGuestsModal}
        />
        <DynamicReduxModal reduxName='share' DynamicComponent={ShareSheet} />
        <DynamicReduxModal reduxName='guest' DynamicComponent={GuestModal} />
        <GuestManagementModal />
        <BottomSheet
          className={classes.ticketsRoot}
          open={ticketsOpen}
          onClose={onTicketsClose}
        >
          <BuyMoreTicketSelection />
        </BottomSheet>
      </PageContentWrapper>
      {!inDrawer && !inWidget && <GeneralFooter desktopMode={desktopMode} />}
    </appContext.Provider>
  )
}

export default withTranslation('shared')(App)
