import React from 'react'

const FireGreyCircle = (props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='16' height='16' rx='8' fill='#F0F0F0' />
    <path d='M10.83 7.6C10.715 7.45 10.575 7.32 10.445 7.19C10.11 6.89 9.73002 6.675 9.41002 6.36C8.66502 5.63 8.50002 4.425 8.97502 3.5C8.50002 3.615 8.08502 3.875 7.73002 4.16C6.43502 5.2 5.92502 7.035 6.53502 8.61C6.55502 8.66 6.57502 8.71 6.57502 8.775C6.57502 8.885 6.50002 8.985 6.40002 9.025C6.28502 9.075 6.16502 9.045 6.07002 8.965C6.04002 8.94 6.02002 8.915 6.00002 8.88C5.43502 8.165 5.34502 7.14 5.72502 6.32C4.89002 7 4.43502 8.15 4.50002 9.235C4.53002 9.485 4.56002 9.735 4.64502 9.985C4.71502 10.285 4.85002 10.585 5.00002 10.85C5.54002 11.715 6.47502 12.335 7.48002 12.46C8.55002 12.595 9.69502 12.4 10.515 11.66C11.43 10.83 11.75 9.5 11.28 8.36L11.215 8.23C11.11 8 10.83 7.6 10.83 7.6ZM9.25002 10.75C9.11002 10.87 8.88002 11 8.70002 11.05C8.14002 11.25 7.58002 10.97 7.25002 10.64C7.84502 10.5 8.20002 10.06 8.30502 9.615C8.39002 9.215 8.23002 8.885 8.16502 8.5C8.10502 8.13 8.11502 7.815 8.25002 7.47C8.34502 7.66 8.44502 7.85 8.56502 8C8.95002 8.5 9.55502 8.72 9.68502 9.4C9.70502 9.47 9.71502 9.54 9.71502 9.615C9.73002 10.025 9.55002 10.475 9.25002 10.75Z' fill='#EE6C4D' />
  </svg>
)

export default FireGreyCircle
