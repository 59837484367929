import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ProfileListItem from '../common/ProfileListItem'
import { CircularProgress, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import apiClient from 'shared-components/utils/ApiClient'

const useStyles = makeStyles((theme) => ({
  deny: {
    color: theme.palette.text.primary,
    // background: theme.palette.red.light,
    borderRadius: '100px',
    marginRight: theme.spacing(1),
  },
  remove: {
    color: theme.palette.red.main,
    background: theme.palette.gray.main,
    borderRadius: '100px',
    marginRight: theme.spacing(1),
    // '&:hover': {
    //   background: theme.palette.red.light
    // }
  },
  accept: {
    color: theme.palette.primary.main,
    background: theme.palette.gray.main,
    borderRadius: '100px',
  },
  grey: {
    background: theme.palette.gray.main,
    borderRadius: '100px',
  },
  doneIcon: {
    marginRight: theme.spacing(1),
  },
  emptyText: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  noPadding: {
    margin: theme.spacing(-2, 0),
    minHeight: '200px',
  },
  usersList: {
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  notified: {
    marginBottom: theme.spacing(2),
  },
}))

const LIKED_BY_SIZE = 20

export const LikedByModal = ({ message }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [hasMore, setHasMore] = useState(true)

  const loadMore = async () => {
    setLoading(true)
    const page = Math.floor(users.length / LIKED_BY_SIZE)
    // dispatch(fetchActivity(page, LIKED_BY_SIZE))
    const newUsers = await apiClient.event.getReadBy(
      message.id,
      page,
      LIKED_BY_SIZE,
    )
    setHasMore(newUsers.length === LIKED_BY_SIZE)
    setUsers((prev) => [...prev, ...newUsers])
    setLoading(false)
  }

  return (
    <div className={classes.usersList}>
      <InfiniteScroll
        pageStart={-1}
        loadMore={loadMore}
        hasMore={
          (users.length / LIKED_BY_SIZE) % 1 === 0 && !loading && hasMore
        }
      >
        <Typography className={classes.title} variant='subtitle2'>
          {t('likedBy')}
        </Typography>
        {users.map((follower, index) => {
          return <ProfileListItem noWrap key={follower.id} profile={follower} />
        })}
        {loading && <CircularProgress />}
      </InfiniteScroll>
      {users.length === 0 && !hasMore && (
        <Typography className={classes.emptyText} variant='subtitle2'>
          {t('usersLikedWillAppearHere')}
        </Typography>
      )}
    </div>
  )
}
