import React from 'react'
import StatusCounter from 'components/common/StatusCounter'
import { Typography } from '@material-ui/core'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const PinDateSubtitle = ({ dateOption }) => {
  const { date, guestStatuses } = dateOption
  const { t } = useTranslation('common')

  return (
    <div>
      <Typography variant='body2' style={{ margin: 0, fontWeight: 500 }}>{date ? dayjs(date).format('ddd D MMMM · HH:mm') : t('dateOption')}</Typography>
      <StatusCounter guests={guestStatuses} showInvited={false} pinning />
    </div>
  )
}

export default PinDateSubtitle
