import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import apiClient from '../../shared-components/utils/ApiClient'
import { CircularProgress, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import { getCurrentEvent } from '../../selectors/event'
import BottomSheet from '../common/BottomSheet'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    minHeight: 400,
  },
  topContainer: {
    // padding: theme.spacing(2),
    borderBottom: '1px solid ' + theme.palette.gray.main,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    // flexDirection: 'column-reverse'
  },
  scrollContainer: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
    minHeight: '300px',
  },
  question: {
    // padding: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid ' + theme.palette.gray.main,
  },
  answer: {
    padding: theme.spacing(2),
    borderBottom: '1px solid ' + theme.palette.gray.main,
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  userAvatar: {
    height: 24,
    width: 24,
    borderRadius: '100px',
  },
  userName: {
    fontWeight: 500,
  },
  response: {
    marginTop: theme.spacing(1.5),
  },
  count: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: '1px solid ' + theme.palette.gray.main,
  },
  answersTitle: {
    margin: theme.spacing(2, 2, 0, 2),
  },
}))

const ANSWERS_PAGE_SIZE = 10

const QuestionAnswersModal = ({ open, onClose, questionId }) => {
  const classes = useStyles()
  const event = useSelector(getCurrentEvent)
  const { t } = useTranslation('common')
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [counts, setCounts] = useState([])
  const [loadingCounts, setLoadingCounts] = useState(false)
  const question = event.joinOptions?.flatMap(jo => jo.questions)?.find((q) => q.id === questionId)

  const questionText = () => {
    if (question == null) return ''
    if (question.type === 'EMAIL_ADDRESS') {
      return t('questions.emailAddress')
    } else if (question.type === 'PHONE_NUMBER') {
      return t('questions.phoneNumberQuestion')
    } else {
      return question.caption
    }
  }

  useEffect(() => {
    setItems([])
    setHasMore(true)
    loadCounts()
  }, [questionId])

  const loadCounts = async () => {
    if (loadingCounts) return
    setCounts([])
    setLoadingCounts(true)
    if (['RADIO', 'CHECKBOX'].includes(question?.type)) {
      const newCounts = await apiClient.event.getQuestionResponseCounts(
        questionId,
      )
      setCounts(newCounts)
    }
    setLoadingCounts(false)
  }

  const loadMore = async () => {
    setLoading(true)
    const loadPage = Math.floor(items.length / ANSWERS_PAGE_SIZE)
    const newItems = await apiClient.event.getQuestionResponses(
      questionId,
      loadPage,
      ANSWERS_PAGE_SIZE,
    )
    // const newItems = await apiClient.organisation.getEvents(page.id, loadPage, DIGEST_SIZE)
    setHasMore(newItems.length === ANSWERS_PAGE_SIZE)
    setItems((prev) => [...prev, ...newItems])
    setLoading(false)
  }

  useEffect(() => {
    // open && loadMore()
  }, [open])

  return (
    <BottomSheet
      className={classes.root}
      open={open}
      onClose={onClose}
      title={t('questions.answers')}
    >
      {/*<div className={classes.topContainer}>*/}
      {/*  <div className={classes.titleContainer}>*/}
      {/*    <Close onClick={() => onClose()} className={classes.closeIcon} />*/}
      {/*    <Typography variant='h6'>{t('questions.answers')}</Typography>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div id='scrollableDiv' className={classes.scrollContainer}>
        <Typography variant='subtitle1' className={classes.question}>
          {questionText()}
        </Typography>
        {counts.map((count, index) => (
          <div key={index} className={classes.count}>
            <Typography variant='body2'>{`"${count.option}"`}</Typography>
            <Typography variant='body2'>{count.count}</Typography>
          </div>
        ))}
        <Typography variant='subtitle2' className={classes.answersTitle}>
          {t('questions.answers')}
        </Typography>
        <InfiniteScroll
          loadMore={loadMore}
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'visible',
            maxHeight: '600px',
          }}
          hasMore={
            (items.length / ANSWERS_PAGE_SIZE) % 1 === 0 && !loading && hasMore
          }
          loader={<div />}
          scrollableTarget='scrollableDiv'
        >
          {items.map((answer, index) => {
            return (
              <div key={index} className={classes.answer}>
                <div className={classes.userContainer}>
                  <img
                    src={
                      answer.guest?.avatarUrls?.sm ??
                      answer.guest?.user?.avatarUrls.sm
                    }
                    className={classes.userAvatar}
                  />
                  <Typography variant='body2' className={classes.userName}>
                    {answer.guest?.name ?? answer.guest?.user?.name}
                  </Typography>
                </div>
                <Typography className={classes.response} variant='body2'>
                  {answer.response?.model?.text ??
                    answer.response?.model?.value ??
                    answer.response?.model?.options?.join(', ')}
                </Typography>
              </div>
            )
          })}
          {items.length < 1 && (
            <div className={classes.answer}>
              <Typography className={classes.response} variant='body2'>
                {t('questions.noAnswers')}
              </Typography>
            </div>
          )}
          {loading && <CircularProgress />}
        </InfiniteScroll>
      </div>
    </BottomSheet>
  )
}

export default QuestionAnswersModal
