import {
  Button,
  makeStyles,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React, { useState } from 'react'
import { useRouter } from 'next/router'
import cn from 'clsx'
import { ArrowBack, ChevronLeft, ChevronRight } from '@material-ui/icons'
import { mdiExportVariant } from '@mdi/js'
import GuestManagementQuestionAnswers from './GuestManagementQuestionAnswers'
import { guestManagementBreakpoint } from './GuestManagementModal'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    overflowY: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: 64,
    padding: theme.spacing(2, 4, 2, 4),
    [theme.breakpoints.down(guestManagementBreakpoint)]: {
      borderBottom: '1px solid ' + theme.palette.grey[200],
    },
  },
  backButton: {
    position: 'absolute',
    left: theme.spacing(3),
    top: '50%',
    transform: 'translateY(-50%)',
    minWidth: 0,
    borderRadius: 100,
    padding: 8,
    [theme.breakpoints.down(guestManagementBreakpoint)]: {
      left: theme.spacing(1),
    },
  },
  exportButton: {
    position: 'absolute',
    right: theme.spacing(4),
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down(95000)]: {
      top: 0,
      position: 'relative',
      transform: 'none',
      alignSelf: 'flex-start  !important',
      right: 0,
    },
  },
  caption: {
    color: theme.palette.gray[600],
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.gray[200],
  },
  primaryButton: {
    background: theme.palette.primary[100],
    color: theme.palette.primary[800],
    padding: theme.spacing(1, 2, 1, 1.5),
    borderRadius: 100,
    '&:hover': {
      background: theme.palette.primary[200],
    },
    alignSelf: 'flex-end',
  },
  primaryButtonLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  questionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    padding: theme.spacing(0, 4, 2, 4),
  },
  question: {
    padding: theme.spacing(2),
    borderRadius: 8,
    border: '1px solid ' + theme.palette.grey[200],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'start',
  },
  questionText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    alignItems: 'flex-start',
    textAlign: 'start',
  },
  disabledText: {
    color: theme.palette.gray[600],
  },
}))

const GuestManagementQuestions = ({ joinOption, onBack }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(
    theme.breakpoints.down(guestManagementBreakpoint),
  )
  const shiftExportButton = true
  const router = useRouter()
  const event = useSelector(getCurrentEvent)
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const [openAnswers, setOpenAnswers] = useState(null)

  const questionText = (question) => {
    if (question == null) return ''
    if (question.type === 'EMAIL_ADDRESS') {
      return t('questions.emailAddress')
    } else if (question.type === 'PHONE_NUMBER') {
      return t('questions.phoneNumberQuestion')
    } else {
      return question.caption
    }
  }

  const onQuestionClick = (question) => {
    setOpenAnswers(question)
  }

  const onExportClicked = async () => {
    const link = document.createElement('a')
    link.download = `event_responses_${event.id}.csv`
    link.href = `${process.env.NEXT_PUBLIC_API_HOST}/event-forms/responses/events/${event.id}/export`
    link.click()
  }

  if (openAnswers != null) {
    return (
      <GuestManagementQuestionAnswers
        question={openAnswers}
        onBack={() => setOpenAnswers(null)}
      />
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Button className={classes.backButton} onClick={onBack}>
          {!isMobile && <ChevronLeft />}
          {isMobile && <ArrowBack />}
        </Button>
        <Typography
          variant={isMobile ? 'h6' : 'body1'}
          style={{ fontWeight: 500, maxWidth: '80%' }}
          noWrap
        >
          {`${joinOption.name} - ${t('guestManagement.questions')}`}
        </Typography>
        {!shiftExportButton && (
          <Button
            classes={{
              label: classes.primaryButtonLabel,
            }}
            className={cn(classes.primaryButton, classes.exportButton)}
            onClick={onExportClicked}
          >
            <SvgIcon>
              <path d={mdiExportVariant} />
            </SvgIcon>
            {t('guestManagement.exportAnswers')}
          </Button>
        )}
      </div>
      <div className={classes.questionsContainer}>
        <Typography className={classes.caption} variant='body2'>
          {t('guestManagement.questionsCaption')}
        </Typography>

        {shiftExportButton && (
          <Button
            classes={{
              label: classes.primaryButtonLabel,
            }}
            className={cn(classes.primaryButton, classes.exportButton)}
            onClick={onExportClicked}
          >
            <SvgIcon>
              <path d={mdiExportVariant} />
            </SvgIcon>
            {t('guestManagement.exportAnswers')}
          </Button>
        )}

        {joinOption.questions.map((question, index) => (
          <Button
            className={classes.question}
            key={question.id}
            onClick={() => onQuestionClick(question)}
          >
            <div className={classes.questionText}>
              <Typography variant='body1' style={{ fontWeight: 500 }}>
                {questionText(question)}
              </Typography>
              {question.responseCount > 0 && (
                <Typography varian='body2' className={classes.disabledText}>
                  {question.responseCount === 1
                    ? t('questions.response1')
                    : t('questions.responseCount', {
                        count: question.responseCount,
                      })}
                </Typography>
              )}
            </div>

            <ChevronRight style={{ width: 20, height: 20 }} />
          </Button>
        ))}
      </div>
    </div>
  )
}

export default GuestManagementQuestions
