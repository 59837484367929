import React from 'react'
import classNames from 'clsx'

import { useSelector } from 'react-redux'
import { getCurrentEvent } from '../../../selectors/event'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    borderRadius: 5,
    marginTop: theme.spacing(1),
    '& :not(:last-child)': {
      borderRight: '1px solid white',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  bar: {
    height: 6,
  },
  cantColor: {
    backgroundColor: theme.palette.red.main,
  },
  goingColor: {
    backgroundColor: theme.palette.secondary.main,
  },
  divider: {
    backgroundColor: 'white',
  },
  maybeColor: {
    backgroundColor: theme.palette.yellow.main,
  },
  noneColor: {
    backgroundColor: '#AAA',
  },
}))

const calcBarRatios = (dateOption, amountNotResponded) => {
  const ratios = { GOING: 0, MAYBE: 0, CANT: 0, NONE: amountNotResponded }
  let total = 0
  if (dateOption.guestStatuses) {
    total = 0 + amountNotResponded + dateOption.guestStatuses.length
    dateOption.guestStatuses.forEach((guestStatus) => {
      ratios[guestStatus.status] += 1
    })
  }
  for (const status in ratios) {
    ratios[status] = (ratios[status] / total) * 100 + '%'
  }
  return ratios
}

const PinResponseBar = ({ dateOption, onClick }) => {
  const classes = useStyles()
  const event = useSelector(getCurrentEvent)
  const amountNotResponded = event.expectedNumberOfGuests
    ? Math.max(
        event.guests.filter((g) => !g.gaveDateOptions).length,
        event.expectedNumberOfGuests - event.guests.length - 1,
      )
    : event.guests.filter((g) => !g.gaveDateOptions).length
  const barRatios = calcBarRatios(dateOption, amountNotResponded)
  return (
    <div
      className={classNames(classes.barContainer, onClick && classes.clickable)}
      onClick={onClick}
    >
      {barRatios.GOING[0] != '0' && (
        <div
          className={classNames(classes.bar, classes.goingColor)}
          style={{ width: barRatios.GOING }}
        />
      )}
      {barRatios.MAYBE[0] != '0' && (
        <div
          className={classNames(classes.bar, classes.maybeColor)}
          style={{ width: barRatios.MAYBE }}
        />
      )}
      {barRatios.CANT[0] != '0' && (
        <div
          className={classNames(classes.bar, classes.cantColor)}
          style={{ width: barRatios.CANT }}
        />
      )}
      {barRatios.NONE[0] != '0' && (
        <div
          className={classNames(classes.bar, classes.noneColor)}
          style={{ width: barRatios.NONE }}
        />
      )}
    </div>
  )
}

export default PinResponseBar
