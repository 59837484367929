import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Tooltip, Typography } from '@material-ui/core'
import { formatShortNumber } from '../../utils/eventFunctions'
import cn from 'clsx'
import { naturalLanguageJoin } from '../../utils/naturalLanguageJoin'
import { useTranslation } from 'react-i18next'
import { EmojiResponse } from './EmojiResponses'
import { useLongPress } from 'shared-components/utils/hooks'

const useStyles = makeStyles((theme) => ({
  response: {
    minWidth: 0,
    padding: '6px 8px',
    border: '1px solid ' + theme.palette.gray[300],
    borderRadius: 100,
    color: theme.palette.grey[800],
    background: theme.palette.background.paper,
    height: 28,
    flexShrink: 0,
  },
  responseLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  responseActive: {
    background: theme.palette.primary[100],
    border: `1px solid ${theme.palette.primary.main}`,
  },
  responseEmoji: {},
  responseTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    cursor: 'pointer',
  },
  tooltipEmoji: {
    padding: theme.spacing(0.5),
    borderRadius: 3,
    background: '#fff',
  },
  tooltipLabel: {
    background: theme.palette.gray[900],
    padding: theme.spacing(1.5),
  },
  tooltipArrow: {
    color: theme.palette.gray[900],
  },
}))

interface EmojiResponseItemProps {
  response: EmojiResponse
  onTooltipClicked: () => void
  onEmojiClicked: (event) => void
}

const EmojiResponseItem = ({
  response,
  onTooltipClicked,
  onEmojiClicked,
}: EmojiResponseItemProps) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const longPressEvent = useLongPress(onTooltipClicked, onEmojiClicked)

  const getHoverText = (): string => {
    const names = response.users.slice(0, 5).map((user) => user.name)
    return naturalLanguageJoin(t, names, response.users.length - names.length)
  }

  return (
    <>
      <Tooltip
        enterDelay={300}
        enterNextDelay={100}
        open={tooltipOpen}
        onClose={() => {
          setTooltipOpen(false)
        }}
        onOpen={() => setTooltipOpen(true)}
        classes={{
          tooltip: classes.tooltipLabel,
          arrow: classes.tooltipArrow,
        }}
        interactive
        // leaveDelay={300}
        arrow
        placement='top'
        title={
          <div
            onClick={() => {
              setTooltipOpen(false)
              onTooltipClicked()
            }}
            className={classes.responseTooltip}
          >
            <Typography variant='h6' className={classes.tooltipEmoji}>
              {response.emoji}
            </Typography>
            <Typography variant='body2'>{getHoverText()}</Typography>
          </div>
        }
      >
        <Button
          {...longPressEvent}
          className={cn(
            classes.response,
            response.reactedByYou && classes.responseActive,
          )}
          classes={{
            label: classes.responseLabel,
          }}
        >
          <Typography variant='body1'>{response.emoji}</Typography>
          <Typography variant='body2' style={{ fontWeight: 500 }}>
            {formatShortNumber(response.count)}
          </Typography>
        </Button>
      </Tooltip>
    </>
  )
}

export default EmojiResponseItem
