import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import apiClient from 'shared-components/utils/ApiClient'
import { getActiveGuest } from '../../../selectors/event'
import GuestManagementItem from './GuestManagementItem'
import { debounce } from 'underscore'
import GuestManagementEmpty from './GuestManagementEmpty'
import GuestManagementExternalGuestsView from './GuestManagementExternalGuestsView'

const useStyles = makeStyles((theme) => ({
  infiniteScroll: {
    padding: theme.spacing(1, 0),
    flexDirection: 'column',
  },
  circularProgress: {
    margin: theme.spacing(2, 'auto'),
  },
  moreUnknownView: {
    margin: theme.spacing(2, 4),
  },
}))

const PAGE_SIZE = 10

const getFilterParams = (status, isPinning, query, joinOptionId) => {
  if (joinOptionId != null)
    return { joinOptionId: joinOptionId, status: 'JOINED' }
  if (status === 'SEARCH') return {}
  if (status === 'HYPE') return { hype: true }
  if (status === 'WAITLIST') return { waitingList: true }
  if (status === 'NONE') return { invited: true }
  if (isPinning) {
    return status === 'GOING'
      ? { gaveDateOptions: true }
      : status === 'NONE'
        ? { gaveDateOptions: false }
        : {}
  }

  return { status }
}

const GuestManagementScroller = ({
  event,
  status,
  tab,
  query,
  sortType,
  joinOptionId,
}) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const open = useSelector((state) => state.modals.open.guestManagement)
  const refreshList = useSelector((state) => state.modals.refreshList)
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(true)
  const [pageStart, setPageStart] = useState(null)
  const [pageToLoad, setPageToLoad] = useState(0)
  const [guests, setGuests] = useState([])
  const activeGuest = useSelector(getActiveGuest)
  const scrollRef = useRef()

  const showOthers =
    joinOptionId == null &&
    ['GOING', 'JOINED'].includes(status) &&
    event.statusCounts?.externalGoing > 0 &&
    !hasMore &&
    !loading &&
    guests.length > 0

  useEffect(() => {
    setPageStart(Math.ceil(guests.length / PAGE_SIZE) - 1)
    setHasMore(true)
  }, [])

  const isPinning = event.type === 'PINNING'

  const visible = tab === status

  const loadPage = async (page, query2) => {
    if (page === 0) {
      setPageToLoad(0)
      setGuests([])
      setHasMore(false)
    }
    const queryToUse = query2 ?? query
    setLoading(true)
    const newGuests = await apiClient.event.getGuests(
      event.id,
      event.code,
      {
        page: page ?? pageToLoad,
        size: isPinning ? 100 : PAGE_SIZE,
        sortOn: sortType,
        query: status === 'SEARCH' ? (queryToUse ?? '') : undefined,
        ...getFilterParams(status, isPinning, queryToUse, joinOptionId),
      },
      activeGuest?.code,
    )
    setPageToLoad((prev) => prev + 1)
    setGuests(page === 0 ? newGuests : [...guests, ...newGuests])
    setHasMore(newGuests?.length === PAGE_SIZE)
    setLoading(false)
  }

  useEffect(() => {
    if (open && visible && status !== 'SEARCH') {
      loadPage(0, query)
    } else {
      setGuests([])
    }
  }, [visible, sortType, open])

  useEffect(() => {
    if (refreshList.name === status) {
      loadPage(0, query)
    }
  }, [refreshList.counter])

  useEffect(() => {
    if (status === 'SEARCH' && guests.length > 0) {
      loadPage(0, query)
    }
  }, [sortType])

  const searchQuery = useCallback(
    debounce((query) => {
      loadPage(0, query)
    }, 500),
    [],
  )

  useEffect(() => {
    if (status === 'SEARCH') {
      setLoading(true)
      searchQuery(query)
    }
  }, [query])

  useEffect(() => {
    setHasMore(false)
  }, [visible])

  return (
    <div
      className={classes.infiniteScroll}
      style={{
        display: visible ? 'flex' : 'none',
        overflowY: 'auto',
        maxHeight: '100%',
      }}
      ref={scrollRef}
    >
      {loading && guests.length === 0 && (
        <CircularProgress className={classes.circularProgress} />
      )}
      {pageStart !== null && (
        <InfiniteScroll
          loadMore={(page) => {
            loadPage()
          }}
          useWindow={false}
          hasMore={!loading && hasMore}
          loader={<CircularProgress key='loader' size={24} />}
        >
          {guests.length === 0 && !loading ? (
            <GuestManagementEmpty status={status} query={query} />
          ) : (
            // <EmptyState status={status} />
            <>
              {guests.map((g) => (
                <GuestManagementItem
                  showRightDetail={status === 'SEARCH'}
                  key={g.id}
                  guest={g}
                  listStatus={status}
                />
              ))}
            </>
          )}

          {showOthers && (
            <GuestManagementExternalGuestsView
              className={classes.moreUnknownView}
            />
          )}
        </InfiniteScroll>
      )}
    </div>
  )
}

export default GuestManagementScroller
