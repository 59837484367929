import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import cn from 'clsx'
import { useTranslation } from 'react-i18next'
import { Groups } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2, 3),
    gap: theme.spacing(2),
  },
  imagesContainer: {
    display: 'flex',
    alignItems: 'center',
    filter: 'blur(3.199999809265137px)',
    padding: theme.spacing(3, 2),
  },
  image: {
    objectFit: 'cover',
    width: 40,
    height: 40,
    borderRadius: 100,
    '&:not(:first-child)': {
      marginLeft: -8,
    },
  },
  text: {
    color: theme.palette.grey[800],
  },
  caption: {
    color: theme.palette.grey[600],
    fontSize: 10,
  },
}))

const MoreUnknownGuestsView = ({ className, ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cn(classes.root, className)} {...props}>
      <Groups />

      <div>
        <Typography variant='body2' className={classes.text}>
          {t('moreUnknownGuests')}
        </Typography>
        <Typography variant='body2' className={classes.caption}>
          {t('moreUnknownGuestsCaption')}
        </Typography>
      </div>
    </div>
  )
}

export default MoreUnknownGuestsView
