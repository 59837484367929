import { makeStyles } from '@material-ui/core'
import React from 'react'
import Linkify from 'react-linkify'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.main
  }
}))

const MixpanelLinkWrapper = (linkClass) => (href, text, key) => (
  <a className={linkClass} target='_blank' rel='noopener noreferrer' href={href} key={key}>
    {text}
  </a>
)

const MixpanelLinkify = ({ children }) => {
  const classes = useStyles()

  return (
    <Linkify componentDecorator={MixpanelLinkWrapper(classes.link)}>
      {children}
    </Linkify>
  )
}

export default MixpanelLinkify
