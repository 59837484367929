import React, { MouseEventHandler } from 'react'
import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import cn from 'clsx'
import { paletteSecondary } from '../theme'
import {
  ChevronLeft,
  ChevronRight,
  OpenInNew,
  OpenInNewOutlined,
  OpenInNewRounded,
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 'none',
    fontSize: '14px',
    height: 40,
    borderRadius: 100,
    padding: 0,
    minWidth: 90,
  },
  root32: {
    height: 32,
  },
  root48: {
    height: 48,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 2),
    position: 'relative',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  labelIconSmaller: {
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  label32: {
    padding: theme.spacing(1, 1.5),
  },
  labelWithChevron: {
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  viewStart: {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    left: theme.spacing(2),
    transform: 'translateY(-50%)',
    color: 'inherit',
  },
  viewStartChevron: {
    left: 10,
  },
  viewEnd: {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    right: theme.spacing(2),
    transform: 'translateY(-50%)',
    color: 'inherit',
  },
  viewEndChevron: {
    right: 10,
  },
  textStart: {
    display: 'flex',
  },
  textStartChevron: {
    marginLeft: -6,
  },
  textEnd: {
    display: 'flex',
  },
  textEndChevron: {
    marginRight: -6,
  },
  progress: {
    color: 'inherit',
    margin: theme.spacing(0, 0.5),
  },
  progressMiddle: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  disabled: {
    pointerEvents: 'none',
    background: theme.palette.grey[400] + ' !important',
    color: 'white',
    '&:hover': {
      background: theme.palette.grey[400],
    },
  },
  [BashButtonType.PRIMARY]: {
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary[800],
    },
  },
  [BashButtonType.PRIMARY_LIGHT]: {
    background: theme.palette.primary[100],
    color: theme.palette.primary[800],
    '&:hover': {
      background: theme.palette.primary[200],
    },
  },
  [BashButtonType.PRIMARY_LIGHT_BORDERED]: {
    background: theme.palette.primary[100],
    color: theme.palette.primary[800],
    border: `1px solid ${theme.palette.primary[800]}`,
    '&:hover': {
      background: theme.palette.primary[200],
    },
  },
  [BashButtonType.SECONDARY]: {
    background: paletteSecondary[800],
    color: 'white',
    '&:hover': {
      background: paletteSecondary[950],
    },
  },
  [BashButtonType.SECONDARY_LIGHT]: {
    background: theme.palette.secondary[100],
    color: theme.palette.secondary[800],
    '&:hover': {
      background: theme.palette.secondary[200],
    },
  },
  [BashButtonType.CLEAR]: {
    background: 'transparent',
    color: 'black',
    '&:hover': {
      background: theme.palette.grey[200],
    },
  },
  [BashButtonType.CLEAR_GREY]: {
    background: 'transparent',
    color: theme.palette.grey[800],
    '&:hover': {
      background: theme.palette.grey[200],
    },
  },
  [BashButtonType.CLEAR_SECONDARY]: {
    background: 'transparent',
    color: theme.palette.secondary[800],
    '&:hover': {
      background: theme.palette.secondary[100],
    },
  },
  [BashButtonType.CLEAR_RED]: {
    background: 'transparent',
    color: theme.palette.red.main,
    '&:hover': {
      background: theme.palette.red[10],
    },
  },
  [BashButtonType.GREY]: {
    background: theme.palette.grey[150],
    color: theme.palette.text.primary,
    '&:hover': {
      background: theme.palette.grey[150],
    },
  },
  [BashButtonType.GREY_LIGHT]: {
    background: theme.palette.grey[150],
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.grey[200]}`,
    '&:hover': {
      background: theme.palette.grey[200],
    },
  },
  [BashButtonType.WHITE_GREY_BORDER]: {
    background: theme.palette.background.paper,
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.grey[200]}`,
    '&:hover': {
      background: theme.palette.grey[150],
    },
  },
}))

export const enum BashButtonType {
  PRIMARY = 'PRIMARY',
  PRIMARY_LIGHT = 'PRIMARY_LIGHT',
  PRIMARY_LIGHT_BORDERED = 'PRIMARY_LIGHT_BORDERED',
  SECONDARY = 'SECONDARY',
  SECONDARY_LIGHT = 'SECONDARY_LIGHT',
  CLEAR = 'CLEAR',
  CLEAR_GREY = 'CLEAR_GREY',
  CLEAR_SECONDARY = 'CLEAR_SECONDARY',
  CLEAR_RED = 'CLEAR_RED',
  GREY = 'GREY',
  GREY_LIGHT = 'GREY_LIGHT',
  WHITE_GREY_BORDER = 'WHITE_GREY_BORDER',
}

export const enum ButtonViewAlignment {
  ALIGN_VIEW_END = 'ALIGN_VIEW_END',
  ALIGN_VIEW_START = 'ALIGN_VIEW_START',
  ALIGN_TEXT_END = 'ALIGN_TEXT_END',
  ALIGN_TEXT_START = 'ALIGN_TEXT_START',
}

export interface BashButtonProps {
  type: BashButtonType
  extraView?: any | null
  viewAlignment?: ButtonViewAlignment
  loading?: boolean
  enabled?: boolean
  onClick: MouseEventHandler
  children: any
  className?: string | null
  href?: string | null
  target?: string | null
  rel?: string | null
  height?: 48 | 40 | 32
}

function getDisplayName(Component) {
  if (!Component) return 'Unknown'
  return (
    Component.displayName ||
    Component.name ||
    (typeof Component === 'string' && Component.length > 0
      ? Component
      : 'Unknown')
  )
}

// @ts-ignore
OpenInNew.displayName = 'OpenInNew'
// @ts-ignore
OpenInNewRounded.displayName = 'OpenInNewRounded'
// @ts-ignore
OpenInNewOutlined.displayName = 'OpenInNewOutlined'
// @ts-ignore
ChevronRight.displayName = 'ChevronRight'
// @ts-ignore
ChevronLeft.displayName = 'ChevronLeft'

const BashButton = ({
  type = BashButtonType.PRIMARY,
  extraView = null,
  viewAlignment = ButtonViewAlignment.ALIGN_VIEW_END,
  loading = false,
  enabled = true,
  onClick = () => {},
  children,
  className = null,
  href = null,
  height = 40,
  ...props
}: BashButtonProps) => {
  const classes = useStyles()

  const extraIsChevron = getDisplayName(extraView?.type?.type?.render)
    ?.toLowerCase()
    ?.includes('chevron')

  const makeIconSmaller = getDisplayName(extraView?.type?.type?.render)
    ?.toLowerCase()
    ?.includes('openinnew')

  const getExtraView = () => {
    if (extraView == null) return null
    else
      return loading ? (
        <CircularProgress size={20} className={classes.progress} />
      ) : (
        extraView
      )
  }

  const extraViewToShow = getExtraView()

  const disabled = !enabled || loading

  return (
    <Button
      href={href}
      className={cn(
        classes.root,
        classes[`root${height}`],
        height === 32 && classes.root32,
        disabled ? classes.disabled : classes[type],
        className,
      )}
      classes={{
        label: cn(
          classes.label,
          height === 32 && classes.label32,
          extraIsChevron ? classes.labelWithChevron : undefined,
          makeIconSmaller && classes.labelIconSmaller,
        ),
      }}
      onClick={onClick}
      {...props}
    >
      {/* VIEW_START */}
      {extraViewToShow != null &&
        viewAlignment == ButtonViewAlignment.ALIGN_VIEW_START && (
          <div
            className={cn(
              classes.viewStart,
              extraIsChevron && classes.viewStartChevron,
            )}
          >
            {extraViewToShow}
          </div>
        )}

      {/* TEXT_START */}
      {extraViewToShow != null &&
        viewAlignment === ButtonViewAlignment.ALIGN_TEXT_START && (
          <div
            className={cn(
              classes.textStart,
              extraIsChevron && classes.textStartChevron,
            )}
          >
            {extraViewToShow}
          </div>
        )}

      <Typography
        style={{
          opacity: extraView == null && loading ? 0 : 1,
          transition: 'opacity 0.2 ease',
          fontWeight: [48, 40].includes(height) ? '600' : 500,
        }}
        variant={[48, 40].includes(height) ? 'button' : 'caption'}
      >
        {children}
      </Typography>
      {extraView == null && loading && (
        <div className={classes.progressMiddle}>
          <CircularProgress size={20} className={classes.progress} />
        </div>
      )}

      {/* TEXT_END */}
      {extraViewToShow != null &&
        viewAlignment === ButtonViewAlignment.ALIGN_TEXT_END && (
          <div
            className={cn(
              classes.textEnd,
              extraIsChevron && classes.textEndChevron,
            )}
          >
            {extraViewToShow}
          </div>
        )}

      {/* VIEW_END */}
      {extraViewToShow != null &&
        viewAlignment == ButtonViewAlignment.ALIGN_VIEW_END && (
          <div
            className={cn(
              classes.viewEnd,
              extraIsChevron && classes.viewEndChevron,
            )}
          >
            {extraViewToShow}
          </div>
        )}
    </Button>
  )
}

export default BashButton
