import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setSnackbar } from 'shared-components/redux/notifications/actions'
import FlatButton from '../../shared-components/buttons/FlatButton'

const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: theme.spacing(2),
    // padding: theme.spacing(2)
    width: '100%',
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  linkBox: {
    padding: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.7)',
    wordBreak: 'break-all',
    border: theme.palette.gray.main + ' solid 1px',
    width: '100%',
    height: 104,
    resize: 'none',
  },
  copyText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: theme.spacing(1),
  },
  copyTextContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    border: '1px solid ' + theme.palette.gray.lightOnSurface,
    cursor: 'pointer',
    transition: 'background 0.1s ease-in-out',
    '&:hover': {
      background: theme.palette.gray.lightOnSurface,
    },
  },
  copyButton: {
    borderRadius: 0,
  },
}))

const SocialButton = ({ className, imageUrl, aTagUrl, width, onTrack }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const onCopy = (e) => {
    // e.target.select()
    if (navigator.clipboard) {
      navigator.clipboard.writeText(embedHtml)
      dispatch(setSnackbar('info', t('copiedHtmlToClipboard')))
    }
    onTrack()
  }

  const embedHtml = `<a href="${aTagUrl}" target="_blank"><img src="${imageUrl}" width="${width}" height="56" alt='BASH' /></a>`
  return (
    <div className={className}>
      <img src={imageUrl} width={width} height={56} />
      <Typography variant='body2' className={classes.subtitle}>
        {t('copyAndPasteHtmlAnywhere')}
      </Typography>

      <div className={classes.copyTextContainer} onClick={onCopy}>
        <Typography
          color='textSecondary'
          className={classes.copyText}
          variant='body2'
        >
          {embedHtml}
        </Typography>
        <FlatButton color='grey' className={classes.copyButton}>
          {t('copy')}
        </FlatButton>
      </div>
    </div>
  )
}

export default SocialButton
