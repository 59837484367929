export const naturalLanguageJoin = (
  t,
  names: string[],
  othersCount: number,
): string => {
  let stringPart: string

  if (names.length === 2) {
    // Join the two names with 'and'
    stringPart = `${names[0]} ${t('shared:and')} ${names[1]}`
  } else if (names.length > 2) {
    // Join the strings with commas and add 'and' before the last item
    stringPart =
      names.slice(0, -1).join(', ') + ` ${t('shared:and')} ` + names[names.length - 1]
  } else {
    // Only one name
    stringPart = names[0]
  }

  // Handle the case where othersCount is 0
  if (othersCount === 0) {
    return stringPart
  }

  // Use "other" if count is 1, otherwise use "others"
  const othersText = othersCount === 1 ? t('other') : t('others')

  return `${stringPart} ${t('shared:and')} ${othersCount} ${othersText}`
}