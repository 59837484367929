import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import { formatShortNumber } from '../../utils/eventFunctions'
import cn from 'clsx'
import { useTranslation } from 'react-i18next'
import { EmojiResponse } from './EmojiResponses'
import BottomSheet from './BottomSheet'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(-3),
    minHeight: 300,
    // maxHeight: 500
  },
  left: {
    overflow: 'hidden',
    overflowY: 'auto',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2, 1.5),
    flexShrink: 0,
    borderRight: `1px solid ${theme.palette.gray[200]}`,
  },
  right: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    flexGrow: 1,
    overflow: 'hidden',
    overflowY: 'auto',
  },
  emojiButton: {
    background: '#fff',
    borderRadius: 8,
    padding: theme.spacing(1),
    minWidth: 0,
  },
  emojiButtonActive: {
    background: '#F5F5F5',
  },
  emojiButtonLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 100,
  },
  userEmoji: {
    marginLeft: 'auto',
  },
}))

interface EmojiResponsesModalProps {
  responses: EmojiResponse[]
  open: boolean
  onClose: () => void
  selectedResponse: EmojiResponse | null
  setSelectedResponse: (response: EmojiResponse | null) => void
}

const EmojiResponsesModal = ({
  responses,
  open,
  onClose,
  selectedResponse,
  setSelectedResponse,
  ...props
}: EmojiResponsesModalProps) => {
  const { t } = useTranslation('common')
  const classes = useStyles()

  const onResponseSelected = (response) => {
    setSelectedResponse(response)
  }

  return (
    <BottomSheet
      open={open}
      onClose={onClose}
      title={t('reactions')}
      onBack={undefined}
      disableBackdropClick={false}
      className={null}
      fullScreen={false}
      topRightContent={null}
    >
      <div className={classes.root}>
        <div className={classes.left}>
          {responses.map((r, index) => (
            <Button
              onClick={() => onResponseSelected(r)}
              className={cn(
                classes.emojiButton,
                selectedResponse?.emoji === r.emoji
                  ? classes.emojiButtonActive
                  : null,
              )}
              classes={{
                label: classes.emojiButtonLabel,
              }}
              key={index}
            >
              <Typography variant='body1'>{r.emoji}</Typography>
              <Typography variant='body2' style={{ fontWeight: 500 }}>
                {formatShortNumber(r.count)}
              </Typography>
            </Button>
          ))}
        </div>

        <div className={classes.right}>
          {selectedResponse?.users.map((u, index) => (
            <div key={index} className={classes.user}>
              <img
                src={u.avatarUrls.lg}
                alt='User Avatar'
                className={classes.avatar}
              />
              <Typography noWrap variant='body2' style={{ fontWeight: 500 }}>
                {u.name}
              </Typography>

              <Typography
                className={classes.userEmoji}
                variant='body1'
                style={{ fontWeight: 500 }}
              >
                {selectedResponse?.emoji}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </BottomSheet>
  )
}

export default EmojiResponsesModal
