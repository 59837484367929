import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const DynamicReduxModal = ({ reduxName, DynamicComponent, ...other }) => {
  const open = useSelector((state) => state.modals.open[reduxName])
  const [delayedOpen, setDelayedOpen] = useState(open)

  useEffect(() => {
    if (open) {
      setDelayedOpen(true)
    } else {
      setTimeout(() => {
        setDelayedOpen(false)
      }, 300)
    }
  }, [open])

  return delayedOpen ? <DynamicComponent /> : null
}

export default DynamicReduxModal
