import { withStyles } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase/InputBase'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import React, { forwardRef } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import classNames from 'clsx'

const styles = (theme) => ({
  inputLabel: {
    color: 'black',
    marginBottom: theme.spacing(1.5),
    fontSize: '0.875rem',
    fontWeight: '500',
    marginTop: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    marginTop: theme.spacing(3),
  },
})

const TextInputBase = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[150],
    border: 'none',
    borderRadius: 8,
    width: '100%',
    padding: '15px',
    fontSize: '16px',
    lineHeight: '24px',
    '& input': {
      padding: 0,
      height: '20px',
    },
  },
  multiline: {
    padding: theme.spacing(2) + 'px !important',
  },
}))(InputBase)

export const InputHelperText = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    display: 'none',
    marginTop: theme.spacing(1),
  },
  error: {
    display: 'block',
  },
}))(FormHelperText)

const TextInput = forwardRef(
  (
    {
      label,
      customClass,
      error,
      helperText,
      errorText,
      classes,
      rowAndFixed,
      fixedLabelSize,
      ...other
    },
    ref,
  ) => {
    return (
      <FormControl
        error={error}
        className={classNames(
          classes.fullWidth,
          rowAndFixed && classes.row,
          customClass,
        )}
      >
        {label && (
          <InputLabel
            style={{
              width: fixedLabelSize,
            }}
            className={classNames(classes.inputLabel)}
          >
            {label}
          </InputLabel>
        )}
        <TextInputBase
          ref={ref}
          {...other}
          inputProps={{ ...other.inputProps }}
        />
        <InputHelperText>{helperText || errorText}</InputHelperText>
      </FormControl>
    )
  },
)

export default withStyles(styles)(TextInput)
