import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ShareIcon from '@material-ui/icons/Reply'
import { setSnackbar } from '../redux/notifications/actions'
import FlatButton from 'shared-components/buttons/FlatButton'
import { appContext } from '../event/App'
import { mdiWhatsapp } from '@mdi/js'
import { makeStyles, SvgIcon } from '@material-ui/core'
import mixpanel from 'shared-components/utils/mixpanel'
import ColorIconButton from 'shared-components/buttons/ColorIconButton'
import useMixpanelTrackEvent from '../../utils/useMixpanelTrackEvent'
import { LinkRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { getIsMobileOrTablet } from '../../utils/isMobileOrTablet'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../buttons/BashButton'

const useShareButtonStyles = makeStyles((theme) => ({
  flip: {
    transform: 'scaleX(-1)',
  },
  shareButton: {
    // backgroundColor: theme.palette.primary.light,
    // color: theme.palette.primary[800],
    // borderColor: theme.palette.primary[800],
    height: 40,
    // '&:hover': {
    //   backgroundColor: theme.palette.primary[200],
    // },
    fontSize: '12px',
    lineHeight: '14.32px',
  },
  buttonIcon: {
    width: 16,
    height: 16,
  },
  secondary: {
    backgroundColor: 'white',
    color: theme.palette.grey[800],
    borderColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[150],
      borderColor: theme.palette.grey[200],
    },
  },
}))

export const PlainShareButton = ({ small, grey, secondary, ...other }) => {
  const classes = useShareButtonStyles()
  const { t } = useTranslation('shared')
  const color = grey ? 'grey' : 'primary'
  const iconColor = grey ? 'primary' : 'inherit'

  const [isNativeShare, setNativeShare] = useState(false)
  useEffect(() => {
    if (navigator.share && getIsMobileOrTablet()) {
      setNativeShare(true)
    }
  }, [])

  if (small) {
    return (
      <ColorIconButton color={color} {...other}>
        <ShareIcon color={iconColor} className={classes.flip} />
      </ColorIconButton>
    )
  }
  return (
    <BashButton
      type={BashButtonType.PRIMARY_LIGHT_BORDERED}
      onClick={other.onClick}
      extraView={
        isNativeShare ? (
          <ShareIcon className={clsx(classes.buttonIcon, classes.flip)} />
        ) : (
          <LinkRounded className={classes.buttonIcon} />
        )
      }
      viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
      className={clsx(classes.shareButton, secondary && classes.secondary)}
      {...other}
    >
      {isNativeShare ? t('share') : t('copyLink')}
    </BashButton>

    // <FlatButton
    //   color='primary'
    //   variant='outlined'
    //   startIcon={
    //     isNativeShare ? (
    //       <ShareIcon className={clsx(classes.buttonIcon, classes.flip)} />
    //     ) : (
    //       <LinkRounded className={classes.buttonIcon} />
    //     )
    //   }
    //   className={clsx(classes.shareButton, secondary && classes.secondary)}
    //   {...other}
    // >
    //   {isNativeShare ? t('share') : t('copyLink')}
    // </FlatButton>
  )
}

const ShareButton = ({ shareOptions, render, track, ...other }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('shared')
  const onClick = (clickEvent) => {
    if (navigator.share && getIsMobileOrTablet()) {
      track && track('generic')
      try {
        navigator.share(shareOptions)
      } catch (e) {
        console.error(e)
      }
    } else {
      if (navigator.clipboard) {
        track && track('link')
        navigator.clipboard.writeText(shareOptions.url)
        dispatch(setSnackbar('info', t('linkCopiedToClipboard')))
      } else {
        dispatch(setSnackbar('error', 'Failed to copy'))
      }
    }
  }
  if (render) return render(onClick)
  return <PlainShareButton onClick={onClick} {...other} />
}

export const EventShareButton = ({
  invite = true,
  event,
  activeGuest,
  whatsapp,
  ...other
}) => {
  const app = useContext(appContext)
  const { t } = useTranslation('common')
  const useEvent = event || app.event
  const useActiveGuest = activeGuest || app.activeGuest
  const currentGuestCode = useActiveGuest ? useActiveGuest.code : undefined

  const user = useSelector((state) => state.user.user)

  const firstHostThatIsMe = event.hosts?.find(
    (e) =>
      (e.type === 'USER' && e.model.id === user.id) ||
      (e.type === 'ORGANISATION' && e.model.manager === true),
  )

  const eventUrl = `${process.env.NEXT_PUBLIC_RSVP}/${useEvent.code}${invite && firstHostThatIsMe ? `/${firstHostThatIsMe.id}` : ''}${user.id ? `?u=${user.id}` : ''}`
  // const transKey = (useActiveGuest && useActiveGuest.status === 'MAYBE') ? 'shareEvent.interested' : 'shareEvent.going'
  const transKey = `shareEvent.${useEvent.type}`
  const shareOptions = {
    title: useEvent.name,
    url: eventUrl,
    text: t(transKey, { eventName: useEvent.name, eventUrl }),
  }
  const trackEvent = useMixpanelTrackEvent(useEvent)

  if (whatsapp) {
    return (
      <WhatsappShareButton
        message={shareOptions.text}
        onClick={() => trackEvent('Share Bash', { 'Share Medium': 'whatsapp' })}
        {...other}
      />
    )
  }
  return (
    <ShareButton
      {...other}
      shareOptions={shareOptions}
      track={(medium) => trackEvent('Share Bash', { 'Share Medium': medium })}
    />
  )
}

export const OrgShareButton = ({ organisation, ...other }) => {
  const { t } = useTranslation('shared')
  const shareOptions = {
    title: organisation.name,
    url: `${process.env.NEXT_PUBLIC_WEBSITE}/${organisation.username}`,
    text: t('followOrgOnBash', { name: organisation.name }),
  }

  return (
    <ShareButton
      {...other}
      shareOptions={shareOptions}
      track={() =>
        mixpanel.track('Share', { 'Share Object': 'public profile' })
      }
    />
  )
}

const createWhatsappURL = (message) => {
  return `https://wa.me/?text=${encodeURIComponent(message)}`
}

const WhatsappShareButton = ({ message, grey, text, ...other }) => {
  const { t } = useTranslation('common')
  return (
    <FlatButton
      color={grey ? 'grey' : 'secondary'}
      startIcon={
        <SvgIcon color={grey && 'secondary'}>
          <path d={mdiWhatsapp} />
        </SvgIcon>
      }
      href={createWhatsappURL(message)}
      target='_blank'
      rel='noreferrer'
      {...other}
    >
      {text || t('inviteViaWhatsapp')}
    </FlatButton>
  )
}
