import React from 'react'

const MaybeOutline = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M1 12C1 5.92487 5.92487 1 12 1H21C22.1046 1 23 1.89543 23 3V21C23 22.1046 22.1046 23 21 23H12C5.92487 23 1 18.0751 1 12Z' fill='white' />
    <path fillRule='evenodd' clipRule='evenodd' d='M21 5C21 3.89543 20.1046 3 19 3L12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21H19C20.1046 21 21 20.1046 21 19V5ZM17.4 11.1H6.60001V12.9H17.4V11.1Z' fill='#3F6AD4' />
    <path fillRule='evenodd' clipRule='evenodd' d='M21 5C21 3.89543 20.1046 3 19 3L12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21H19C20.1046 21 21 20.1046 21 19V5ZM17.4 11.1H6.60001V12.9H17.4V11.1Z' fill='black' fillOpacity='0.05' />
  </svg>
)

export default MaybeOutline
