import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import classNames from 'clsx'

const useStyles = makeStyles((theme) => ({
  // eslint-disable-next-line mui-unused-classes/unused-classes
  grey: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.gray.main,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.gray.main,
      },
    },
  },
  // eslint-disable-next-line mui-unused-classes/unused-classes
  primary: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))

const ColoredIconButton = ({
  children,
  className,
  color = 'grey',
  ...otherprops
}) => {
  const classes = useStyles()
  return (
    <IconButton
      color='inherit'
      className={classNames(classes[color], className)}
      {...otherprops}
    >
      {children}
    </IconButton>
  )
}

export default ColoredIconButton
