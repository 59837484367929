import React from 'react'

const EmojiPlus = (props) => (
  // <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  //   <path
  //     d="M14 5V9C7 10 4 15 3 20C5.5 16.5 9 14.9 14 14.9V19L21 12L14 5ZM16 9.83L18.17 12L16 14.17V12.9H14C11.93 12.9 10.07 13.28 8.34 13.85C9.74 12.46 11.54 11.37 14.28 11L16 10.73V9.83Z"
  //     fill="currentcolor" />
  // </svg>

  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.499 5.25V3.75H14.249V1.5H12.749V3.75H10.499V5.25H12.749V7.5H14.249V5.25H16.499Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.9197 8.77148C14.973 9.09416 15 9.42141 15 9.75C15 11.3413 14.3679 12.8674 13.2426 13.9926C12.1174 15.1179 10.5913 15.75 9 15.75C8.21207 15.75 7.43185 15.5948 6.7039 15.2933C5.97595 14.9918 5.31451 14.5498 4.75736 13.9926C3.63214 12.8674 3 11.3413 3 9.75C3 8.1587 3.63214 6.63258 4.75736 5.50736C5.88258 4.38214 7.4087 3.75 9 3.75C9.02073 3.75 9.04145 3.75011 9.06216 3.75032C9.02128 3.99413 9 4.24458 9 4.5C9 4.65189 9.00753 4.80203 9.02222 4.95005C9.01482 4.95002 9.00741 4.95 9 4.95C7.72696 4.95 6.50606 5.45571 5.60589 6.35589C4.70571 7.25606 4.2 8.47696 4.2 9.75C4.2 11.023 4.70571 12.2439 5.60589 13.1441C6.50606 14.0443 7.72696 14.55 9 14.55C10.273 14.55 11.4939 14.0443 12.3941 13.1441C13.2943 12.2439 13.8 11.023 13.8 9.75C13.8 9.49509 13.7797 9.24228 13.7401 8.99371C14.1502 8.97215 14.5458 8.89566 14.9197 8.77148ZM6.9 9.15C7.38 9.15 7.8 8.73 7.8 8.25C7.8 7.77 7.38 7.35 6.9 7.35C6.42 7.35 6 7.77 6 8.25C6 8.73 6.42 9.15 6.9 9.15ZM6.486 11.802C7.026 12.45 7.95 12.888 9 12.888C10.05 12.888 10.974 12.45 11.514 11.802L10.662 10.95C10.392 11.382 9.75 11.688 9 11.688C8.25 11.688 7.608 11.382 7.338 10.95L6.486 11.802Z'
      fill='currentColor'
    />
    <path
      d='M11.0992 9.15156C11.5792 9.15156 11.9992 8.73156 11.9992 8.25156C11.9992 7.77156 11.5792 7.35156 11.0992 7.35156C10.6192 7.35156 10.1992 7.77156 10.1992 8.25156C10.1992 8.73156 10.6192 9.15156 11.0992 9.15156Z'
      fill='currentColor'
    />
  </svg>
)

export default EmojiPlus
