import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from '../../../selectors/event'
import {
  getGuestAvatarUrls,
  getGuestName,
} from '../../../shared-components/utils/eventDisplay'
import { Button, SvgIcon, Tooltip, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import MaybeIcon from '../../../svg/status/Maybe'
import CantIcon from '../../../svg/status/Cant'
import cn from 'clsx'
import { useTranslation } from 'react-i18next'
import { mdiChevronDown, mdiEyeOffOutline } from '@mdi/js'
import { Add } from '@material-ui/icons'
import { useUser } from '../../../utils/userFunctions'
import { shuffleArray } from '../../RsvpWizard/InfoStep'
import AddRsvpMessageModal from './AddRsvpMessageModal'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../../shared-components/buttons/BashButton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2, 0, 1.5, 0),
    background: theme.palette.grey[100],
    borderRadius: 8,
  },
  topRow: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    padding: theme.spacing(0, 2),
  },
  avatar: {
    borderRadius: 100,
    width: 36,
    height: 36,
    border: `1px solid ${theme.palette.gray[200]}`,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  topText: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  bottomText: {
    color: theme.palette.gray[600],
  },
  statusIcon: {
    width: 12,
    height: 12,
  },
  statusGoing: {
    color: theme.palette.secondary.main,
  },
  statusMaybe: {
    color: theme.palette.primary.main,
  },
  statusCant: {
    color: theme.palette.red.main,
  },
  invisibleIcon: {
    marginLeft: 'auto',
    color: theme.palette.gray[600],
    width: 16,
    height: 16,
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'stretch',
    padding: theme.spacing(0, 2, 0, 7),
    overflowX: 'auto',
    scrollbarWidth: 'none',
    gap: 6,
  },
  addMessageButton: {
    flexShrink: 0,
    // padding: theme.spacing(1, 1.5, 1, '10px'),
    borderRadius: '2px 8px 8px 8px',
    // background: theme.palette.primary[100],
    // color: theme.palette.primary.main,
    // '&:hover': {
    //   background: theme.palette.primary[200],
    // },
  },
  suggestedMessage: {
    flexShrink: 0,
    padding: theme.spacing(0, 1, 0, 2),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.gray[200]}`,
    borderRadius: 10,
  },
  suggestedMessageLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0),
  },
}))

const AddRsvpMessageBox = ({ className, onSend }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const myGuest = event.myGuest
  const { user, isLoggedIn } = useUser()
  const [modalOpen, setModalOpen] = useState(false)
  const [modalText, setModalText] = useState('')
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const allGoingTexts = [
    t('suggestedMessagesGoing.1'),
    t('suggestedMessagesGoing.2'),
    t('suggestedMessagesGoing.3'),
    t('suggestedMessagesGoing.4'),
  ]
  const allMaybeTexts = [
    t('suggestedMessagesMaybe.1'),
    t('suggestedMessagesMaybe.2'),
    t('suggestedMessagesMaybe.3'),
    t('suggestedMessagesMaybe.4'),
  ]
  const allCantTexts = [
    t('suggestedMessagesCant.1'),
    t('suggestedMessagesCant.2'),
    t('suggestedMessagesCant.3'),
    t('suggestedMessagesCant.4'),
  ]

  const [goingTexts, setGoingTexts] = useState(
    shuffleArray(allGoingTexts).slice(0, 4),
  )
  const [maybeTexts, setMaybeTexts] = useState(
    shuffleArray(allMaybeTexts).slice(0, 4),
  )
  const [cantTexts, setCantTexts] = useState(
    shuffleArray(allCantTexts).slice(0, 4),
  )

  const textsToUse = () => {
    if (myGuest?.status === 'GOING') return goingTexts
    if (myGuest?.status === 'MAYBE') return maybeTexts
    if (myGuest?.status === 'CANT') return cantTexts
    return goingTexts
  }

  const onOpenModal = (text) => {
    setModalText(text)
    setModalOpen(true)
  }

  if (!myGuest) return null

  return (
    <div className={cn(classes.root, className)}>
      <div className={classes.topRow}>
        <img
          className={classes.avatar}
          src={
            isLoggedIn ? user.avatarUrls?.lg : getGuestAvatarUrls(myGuest)?.lg
          }
          alt='Guest Avatar'
        />
        <div className={classes.textContainer}>
          <div className={classes.topText}>
            <Typography variant='body2'>
              <span style={{ fontWeight: 500 }}>
                {isLoggedIn ? user.name : getGuestName(myGuest)}
              </span>
              {` ${t(`eventLogUpdate.NEW_RSVP_${myGuest.status}`)}`}
            </Typography>
            {['JOINED', 'GOING'].includes(myGuest.status) && (
              <CheckCircleIcon
                className={cn(classes.statusIcon, classes.statusGoing)}
              />
            )}
            {myGuest.status === 'MAYBE' && (
              <SvgIcon
                component={MaybeIcon}
                className={cn(classes.statusIcon, classes.statusMaybe)}
              />
            )}
            {myGuest.status === 'CANT' && (
              <SvgIcon
                component={CantIcon}
                className={cn(classes.statusIcon, classes.statusCant)}
              />
            )}
          </div>

          <Typography variant='caption' className={classes.bottomText}>
            {t('addGuestMessageCaption')}
          </Typography>
        </div>

        <Tooltip
          title={t('onlyVisibleToYou')}
          open={tooltipOpen}
          onClose={() => setTooltipOpen(false)}
          onOpen={() => setTooltipOpen(true)}
        >
          <SvgIcon
            onClick={() => setTooltipOpen(true)}
            className={classes.invisibleIcon}
          >
            <path d={mdiEyeOffOutline} />
          </SvgIcon>
        </Tooltip>
      </div>

      <div className={classes.bottomRow}>
        <BashButton
          type={BashButtonType.PRIMARY_LIGHT}
          onClick={() => onOpenModal('')}
          extraView={<Add />}
          viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
          className={classes.addMessageButton}
        >
          {t('addMessage')}
        </BashButton>

        {textsToUse().map((text, i) => (
          <Button
            className={classes.suggestedMessage}
            onClick={() => onOpenModal(text)}
            classes={{
              label: classes.suggestedMessageLabel,
            }}
          >
            <Typography variant='body2' style={{ fontWeight: 500 }}>
              {text}
            </Typography>
            <SvgIcon>
              <path d={mdiChevronDown} />
            </SvgIcon>
          </Button>
        ))}
      </div>

      <AddRsvpMessageModal
        onSend={onSend}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        text={modalText}
        setText={setModalText}
      />
    </div>
  )
}

export default AddRsvpMessageBox
