import React from 'react'
import Logo from 'svg/logo/Icon'
import { CircularProgress, makeStyles, Typography } from '@material-ui/core'
import classNames from 'clsx'
import { withTranslation } from 'react-i18next'
import { useIsLoggedIn } from 'utils/hooks'

const useStyles = makeStyles(theme => ({
  loaderRoot: {
    display: 'flex',
    height: 'calc(100vh - 56px)',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  coverAll: {
    position: 'absolute',
    top: 0,
    background: theme.palette.background.default,
    zIndex: 99,
    height: '100vh'
  },
  img: {
    marginBottom: theme.spacing(4)
  },
  text: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3)
  }
}))

function LoadingScreen ({ t, isRejected, coverAll, isOrg, isUser }) {
  const isLoggedIn = useIsLoggedIn()
  const classes = useStyles()
  return (
    <div className={classNames(classes.loaderRoot, { [classes.coverAll]: coverAll })}>
      <Logo className={classes.img} alt='BASH' height={48} />
      {!isRejected ?
        <CircularProgress color='primary' /> :
        <Typography
          className={classes.text}
          variant='subtitle2'
        >{isLoggedIn ?
            (isUser ? t('userNotFound') : isOrg ? t('orgNotFound') : t('eventNotFound')) :
            isUser ? t('userNotFoundLoggedOut') : isOrg ? t('orgNotFoundLoggedOut') : t('eventNotFoundLoggedOut')}
        </Typography>}
    </div>
  )
}

export default withTranslation('shared')(LoadingScreen)
