import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIcon, Typography } from '@material-ui/core'
import cn from 'clsx'
import {
  LocalActivity,
  Lock,
  OpenInNewOutlined,
  VerticalAlignTop,
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { mdiAccountCheck, mdiFire, mdiLock } from '@mdi/js'
import LockIcon from '@material-ui/icons/Lock'
import dayjs from 'dayjs'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 8px',
    background: theme.palette.grey[150],
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    gap: theme.spacing(0.5),
    color: theme.palette.grey[800],
  },
  red: {
    background: theme.palette.red[10],
    color: theme.palette.red[95],
    border: '1px solid ' + theme.palette.grey[20],
  },
  hot: {
    background: theme.palette.red[10],
    color: theme.palette.red.main,
    border: '1px solid ' + theme.palette.grey[20],
    padding: '4px 6px',
  },
  blue: {
    background: theme.palette.primary[100],
    color: theme.palette.primary[950],
  },
  pink: {
    background: theme.palette.pink[100],
    color: theme.palette.pink[800],
  },
  secondary: {
    background: theme.palette.secondary[100],
    color: theme.palette.secondary[950],
  },
  text: {
    fontSize: '10px',
  },
  icon: {
    width: 14,
    height: 14,
  },
  iconSmall: {
    width: 12,
    height: 12,
  },
  iconExtraSmall: {
    width: 10,
    height: 10,
  },
}))

export const TicketBadge = ({ ticketOption }) => {
  const classes = useStyles()

  const formatter = new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: ticketOption?.currency ?? 'EUR',
  })

  return (
    <div className={cn(classes.root)}>
      <LocalActivity className={classes.icon} />
      <Typography className={classes.text} variant='caption'>
        {formatter.format(ticketOption.price / 100)}
      </Typography>
    </div>
  )
}

export const PrivateBadge = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cn(classes.root, classes.blue)}>
      <Lock className={classes.icon} />
      <Typography className={classes.text} variant='caption'>
        {t('private')}
      </Typography>
    </div>
  )
}

export const ApprovalBadge = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cn(classes.root, classes.red)}>
      <SvgIcon className={classes.iconSmall} color='inherit'>
        <path d={mdiAccountCheck} />
      </SvgIcon>
      <Typography className={classes.text} variant='caption'>
        {t('approvalRequired')}
      </Typography>
    </div>
  )
}

export const HiddenOptionBadge = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cn(classes.root, classes.secondary)}>
      <LockIcon className={classes.iconSmall} color='inherit' />
      <Typography className={classes.text} variant='caption'>
        {t('hiddenOption')}
      </Typography>
    </div>
  )
}

export const AvailableUntilBadge = ({ joinOption }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cn(classes.root, classes.secondary)}>
      <Typography className={classes.text} variant='caption'>
        {t('availableUntil')}{' '}
        {dayjs(joinOption.availableTo).format('D MMM HH:mm')}
      </Typography>
    </div>
  )
}

export const AvailableFromBadge = ({ joinOption }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cn(classes.root, classes.secondary)}>
      <Typography className={classes.text} variant='caption'>
        {t('availableFromDate', {
          date: dayjs(joinOption.availableFrom).format('D MMM HH:mm'),
        })}
      </Typography>
    </div>
  )
}

export const HiddenBadge = ({ joinOption }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cn(classes.root, classes.secondary)}>
      <SvgIcon className={classes.iconSmall} color='inherit'>
        <path d={mdiLock} />
      </SvgIcon>
      <Typography className={classes.text} variant='caption'>
        {t('hidden')}
      </Typography>
    </div>
  )
}

export const ExternalBadge = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cn(classes.root, classes.blue)}>
      <Typography className={classes.text} variant='caption'>
        {t('external')}
      </Typography>
      <OpenInNewOutlined className={classes.iconExtraSmall} />
    </div>
  )
}

export const NearCapacityBadge = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cn(classes.root, classes.pink)}>
      <VerticalAlignTop className={classes.iconExtraSmall} />
      <Typography className={classes.text} variant='caption'>
        {t('nearCapacity')}
      </Typography>
    </div>
  )
}

export const HotOrganiserBadge = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cn(classes.root, classes.hot)}>
      <SvgIcon className={classes.icon}>
        <path d={mdiFire} />
      </SvgIcon>

      <Typography className={classes.text} variant='caption'>
        {t('hotOrganiser')}
      </Typography>
    </div>
  )
}

export const TrendingArtistBadge = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cn(classes.root, classes.hot)}>
      <SvgIcon className={classes.icon}>
        <path d={mdiFire} />
      </SvgIcon>

      <Typography className={classes.text} variant='caption'>
        {t('trendingArtist')}
      </Typography>
    </div>
  )
}
