import React from 'react'

const IconBordered = (props) => (

  <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M9.20001 14.4C9.20001 11.383 9.20001 9.87452 10.1373 8.93726C11.0745 8 12.583 8 15.6 8H15.8C18.817 8 20.3255 8 21.2628 8.93726C22.2 9.87452 22.2 11.383 22.2 14.4V33.6C22.2 36.617 22.2 38.1255 21.2628 39.0627C20.3255 40 18.817 40 15.8 40H15.6C12.583 40 11.0745 40 10.1373 39.0627C9.20001 38.1255 9.20001 36.617 9.20001 33.6V14.4Z' fill='currentColor' />
    <path d='M29.8308 40C28.6856 40 28.113 40 27.648 39.8551C26.644 39.5422 25.8578 38.756 25.5449 37.752C25.4 37.287 25.4 36.7144 25.4 35.5692L25.4 30.0308C25.4 28.8856 25.4 28.313 25.5449 27.848C25.8578 26.844 26.644 26.0578 27.648 25.7449C28.113 25.6 28.6856 25.6 29.8308 25.6L32.6 25.6C36.5765 25.6 39.8 28.8236 39.8 32.8C39.8 36.7765 36.5765 40 32.6 40L29.8308 40Z' fill='currentColor' />
    <path d='M39.8 15.2C39.8 19.1764 36.5764 22.4 32.6 22.4C28.6235 22.4 25.4 19.1764 25.4 15.2C25.4 11.2235 28.6235 8 32.6 8C36.5764 8 39.8 11.2235 39.8 15.2Z' fill='currentColor' />
    <rect x='0.5' y='0.5' width='47' height='47' rx='7.5' stroke='#F0F0F0' />
  </svg>

)

export default IconBordered
