import React, { useEffect, useState } from 'react'
import {
  CircularProgress,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { closeGuestModal, openSignInModal } from 'actions/modals'
import FlatButton from 'shared-components/buttons/FlatButton'
import { Lock } from '@material-ui/icons'
import StatusIcon from 'shared-components/event/StatusIcon'
import cn from 'clsx'
import InfiniteScroll from 'react-infinite-scroller'
import apiClient from 'shared-components/utils/ApiClient'
import {
  getActiveGuest,
  getCurrentEvent,
  getHasResponded,
} from '../../../selectors/event'
import mixpanel from '../../utils/mixpanel'
import { followUser } from '../../../actions/user'
import { formatShortNumber } from '../../../utils/eventFunctions'
import { useIsLoggedIn } from '../../../utils/hooks'
import FullScreenSheet from '../../../components/modals/FullScreenSheet'
import BottomSheet from '../../../components/common/BottomSheet'
import GuestList from './GuestList'
import MoreUnknownGuestsView from './MoreUnknownGuestsView'

const useEmptyStateStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    textAlign: 'center',
    justifySelf: 'center',
    margin: 'auto',
    marginTop: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginBottom: theme.spacing(2),
  },
}))

const EmptyState = ({ status }) => {
  const classes = useEmptyStateStyles()
  const { t } = useTranslation('common')
  return (
    <div className={classes.root}>
      <StatusIcon
        status={!status ? 'GOING' : status}
        className={classes.icon}
        size='large'
      />
      <Typography variant='subtitle1' className={classes.title}>
        {t('nothingHere')}
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {!status && t('noInvitesAreWaiting')}
        {status === 'CANT' && t('noOneHasRespondedWithCant')}
        {['MAYBE', 'GOING'].includes(status) &&
          t('noOneHasRespondedWithYet', {
            status: t(`shared:rsvp.${status.toLowerCase()}`).toLowerCase(),
          })}
        {status === 'REQUESTED' && t('noOneHasRequestedYet')}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 642,
  },
  tab: {
    maxWidth: 'initial',
    minWidth: 'initial',
    width: '25%',
  },
  threeTabs: {
    width: '33.33%',
  },
  tabsContainer: {
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: 500,
    },
  },
  scrollContainer: {
    marginRight: theme.spacing(-3),
    marginLeft: theme.spacing(-3),
    padding: 0,
  },
  downloadSection: {
    background: theme.palette.gray.main,
    padding: theme.spacing(3),
    margin: theme.spacing(0, -3),
  },
  continueInApp: {
    marginTop: theme.spacing(2),
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5),
  },
  addFriendButton: {
    flexShrink: 0,
    borderRadius: '8px',
  },
  extraAttendeesBlock: {
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    padding: theme.spacing(2, 0),
    marginLeft: theme.spacing(-3),
    textAlign: 'center',
    fontWeight: 500,
  },
  infiniteScroll: {},
  blur: {
    filter: 'blur(4px)',
  },
  respondButton: {
    marginTop: theme.spacing(2),
    maxWidth: '300px',
  },
  chevron: {
    transform: 'rotate(90deg)',
  },
  moreButton: {
    marginRight: theme.spacing(2),
  },
  buttonLogo: {
    width: 20,
    height: 20,
  },
  gotItButton: {
    height: 40,
  },
  lockedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  lockedTop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  lockIcon: {
    width: 24,
    height: 24,
  },
  lockedDivider: {
    width: '100%',
    height: '1px',
    background: theme.palette.grey[200],
    [theme.breakpoints.up('md')]: {
      height: 0,
    },
  },
  others: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}))

const MAX_GUESTS_SHOWN_NON_USER = 7
const PAGE_SIZE = 10

export const AddFriendButton = ({ guest }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [newState, setNewState] = useState(null)
  const isLoggedIn = useIsLoggedIn()
  const myUserId = useSelector((state) => state.user.user.id)

  if (
    !guest.user ||
    guest.user.friendStatus === 'FRIEND' ||
    guest.user.id === myUserId
  )
    return null

  const getString = () => {
    if (newState !== null) {
      return newState === 'REQUEST_SENT' ? t('requested') : t('added')
    } else {
      switch (guest.user.friendStatus) {
        case null:
        case 'NONE':
          return t('addFriend')
        case 'REQUEST_SENT':
          return t('requested')
        case 'FRIEND':
          return t('friends')
        default:
          return t('addFriend')
      }
    }
  }

  const add = async (e) => {
    e.stopPropagation()
    if (!isLoggedIn) {
      dispatch(openSignInModal(null, null, false))
      return
    }
    setLoading(true)
    mixpanel.track('Add Friend', {
      'Of User ID': guest.user.id,
      'Location in App': 'guest list',
      'Was Suggested': false,
    })
    await dispatch(followUser(guest.user.id))
    setLoading(false)
    setNewState('REQUEST_SENT')
  }

  return (
    <FlatButton
      color='primary'
      size='small'
      onClick={add}
      className={classes.addFriendButton}
      disabled={getString() !== t('addFriend') || loading}
      startIcon={loading ? <CircularProgress size={16} /> : undefined}
    >
      {getString()}
    </FlatButton>
  )
}

const kmFormatter = (num) => {
  if (num < 0 || isNaN(num)) return 0
  if (num < 1000) return num
  if (num < 1000000) return (num / 1000).toFixed(1) + 'k'
  return (num / 1000000).toFixed(1) + 'M'
}

const getFilterParams = (status, isPinning) => {
  if (isPinning) {
    return status === 'GOING'
      ? { gaveDateOptions: true }
      : status === 'NONE'
        ? { gaveDateOptions: false }
        : {}
  }
  if (status === 'WAITLIST') return { waitingList: true }
  if (status === 'NONE') return { invited: true }

  return { status }
}

export const StatusInfiniteScroller = ({ event, status, tab }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageStart, setPageStart] = useState(null)
  const [guests, setGuests] = useState([])
  const activeGuest = useSelector(getActiveGuest)

  useEffect(() => {
    setPageStart(Math.ceil(guests.length / PAGE_SIZE) - 1)
    setHasMore(true)
  }, [])

  const isPinning = event.type === 'PINNING'

  const visible = tab === status

  const loadPage = async (page) => {
    setLoading(true)
    const newGuests = await apiClient.event.getGuests(
      event.id,
      event.code,
      {
        page,
        size: isPinning ? 100 : PAGE_SIZE,
        ...getFilterParams(status, isPinning),
      },
      activeGuest?.code,
    )
    setGuests(page === 0 ? newGuests : [...guests, ...newGuests])
    if (newGuests?.length !== PAGE_SIZE) setHasMore(false)
    setLoading(false)
  }

  const showExtra = status === 'GOING' && !!event.extraAttendees
  const showOthers =
    ['GOING', 'JOINED'].includes(status) &&
    event.statusCounts?.externalGoing > 0 &&
    !hasMore &&
    !loading
  // const showOthers= true

  useEffect(() => {
    if (visible) {
      loadPage(0)
    }
  }, [visible])

  return (
    <div
      className={classes.infiniteScroll}
      style={{ display: visible ? 'block' : 'none', overflowY: 'auto' }}
    >
      {pageStart != null && (
        <InfiniteScroll
          pageStart={pageStart}
          loadMore={loadPage}
          useWindow={false}
          hasMore={!loading && hasMore}
          loader={<CircularProgress key='loader' size={24} />}
        >
          {guests.length === 0 && !showExtra && !loading ? (
            <EmptyState status={status} />
          ) : (
            <GuestList guests={guests} />
          )}
        </InfiniteScroll>
      )}
      {showExtra && (
        <Typography variant='body2' className={classes.extraAttendeesBlock}>
          +{kmFormatter(event.extraAttendees) + ' ' + t('others')}
        </Typography>
      )}

      {false && <MoreUnknownGuestsView className={classes.others} />}
    </div>
  )
}

const GuestModal = ({}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  // const { event } = useContext(appContext)
  const event = useSelector(getCurrentEvent)
  const open = useSelector((state) => state.modals.open.guest)
  const isLoggedIn = useIsLoggedIn()
  const { t } = useTranslation('common')
  const [tab, setTab] = useState(
    event.statusCounts?.requested > 0 && event.host
      ? 'REQUESTED'
      : event.joinOptions?.length > 0
        ? 'JOINED'
        : 'GOING',
  )
  const isPinning = event.type === 'PINNING'
  const tabClass = cn(classes.tab)
  const hasResponded = useSelector(getHasResponded)
  const showRequested =
    event.joinOptions?.some((o) => o.approvalRequired) && event.host
  const locked =
    (!hasResponded &&
      (event.host ?? false) === false &&
      event.privacyType === 'PUBLIC') ||
    (!isLoggedIn && !hasResponded)

  return (
    <div>
      <BottomSheet
        open={open && locked}
        onClose={() => dispatch(closeGuestModal())}
      >
        <div className={classes.lockedContainer}>
          <div className={classes.lockedTop}>
            <Lock className={classes.lockIcon} />
            <Typography
              className={classes.lockedTitle}
              align='center'
              variant='subtitle1'
            >
              {t('lockedDialog.title')}
            </Typography>
          </div>
          <Typography
            className={classes.lockedCaption}
            align='center'
            variant='body2'
          >
            {t('lockedDialog.attendeesDescription')}
          </Typography>

          <div className={classes.lockedDivider} />

          <FlatButton
            onClick={() => dispatch(closeGuestModal())}
            color='primary'
            fullWidth
            className={classes.gotItButton}
          >
            {t('okGotIt')}
          </FlatButton>
        </div>
      </BottomSheet>

      <FullScreenSheet
        title={t('guestList')}
        open={open && !locked}
        alignStart
        nonFullScreenClassName={classes.root}
        scrollContainerClassName={cn(classes.scrollContainer)}
        onClose={() => dispatch(closeGuestModal())}
        className={classes.root}
      >
        <div className={classes.tabsContainer}>
          <Tabs
            variant='fullWidth'
            value={tab}
            indicatorColor='primary'
            textColor='primary'
            onChange={(e, newTab) => setTab(newTab)}
            aria-label='attendees-tabs'
          >
            {isPinning
              ? [
                  <Tab
                    key='goingPinning'
                    value='GOING'
                    label={`${t('responded')} ${
                      event.statusCounts.gaveAvailability
                        ? `(${formatShortNumber(
                            event.statusCounts.gaveAvailability,
                          )})`
                        : ''
                    }`}
                  />,
                  <Tab
                    key='invitedPinning'
                    value='NONE'
                    label={`${t('invited')} ${
                      event.statusCounts.invited
                        ? `(${formatShortNumber(event.statusCounts.invited)})`
                        : ''
                    }`}
                  />,
                ]
              : [
                  ...(showRequested
                    ? [
                        <Tab
                          key='REQUESTED'
                          value='REQUESTED'
                          className={tabClass}
                          label={`${t('requested')} ${
                            event.statusCounts.requested
                              ? `(${formatShortNumber(
                                  event.statusCounts.requested || 0,
                                )})`
                              : ''
                          }`}
                        />,
                      ]
                    : []),
                  ...((event.joinOptions?.length ?? 0) > 0
                    ? [
                        <Tab
                          key='JOINED'
                          value='JOINED'
                          className={tabClass}
                          label={`${t('shared:rsvp.going')} ${
                            event.statusCounts.ticketsSold
                              ? `(${formatShortNumber(
                                  event.statusCounts.ticketsSold,
                                )})`
                              : ''
                          }`}
                        />,
                      ]
                    : []),
                  ,
                  ...((event.joinOptions?.length ?? 0) < 1
                    ? [
                        <Tab
                          key='GOING'
                          value='GOING'
                          className={tabClass}
                          label={`${t('shared:rsvp.going')} ${
                            event.statusCounts.going
                              ? `(${formatShortNumber(
                                  event.statusCounts.going,
                                )})`
                              : ''
                          }`}
                        />,
                      ]
                    : []),
                  ,
                  ...((event.statusCounts?.maybe ?? 0) > 0
                    ? [
                        <Tab
                          key='MAYBE'
                          value='MAYBE'
                          className={tabClass}
                          label={`${t('shared:rsvp.maybe')} ${
                            event.statusCounts.maybe
                              ? `(${formatShortNumber(
                                  event.statusCounts.maybe,
                                )})`
                              : ''
                          }`}
                        />,
                      ]
                    : []),
                  ...((event.statusCounts?.cant ?? 0) > 0
                    ? [
                        <Tab
                          key='CANT'
                          value='CANT'
                          className={tabClass}
                          label={`${t('shared:rsvp.cant')} ${
                            event.statusCounts.cant
                              ? `(${formatShortNumber(
                                  event.statusCounts.cant,
                                )})`
                              : ''
                          }`}
                        />,
                      ]
                    : []),
                  ...((event.statusCounts?.invited ?? 0) > 0
                    ? [
                        <Tab
                          key='NONE'
                          value='NONE'
                          className={tabClass}
                          label={`${t('invited')} ${
                            event.statusCounts.invited
                              ? `(${formatShortNumber(
                                  event.statusCounts.invited,
                                )})`
                              : ''
                          }`}
                        />,
                      ]
                    : []),
                ]}
          </Tabs>
          <Divider />
        </div>

        <div className={classes.contentContainer}>
          <StatusInfiniteScroller
            event={event}
            status={event.joinOptions?.length > 0 ? 'JOINED' : 'GOING'}
            tab={tab}
          />
          <StatusInfiniteScroller event={event} status='MAYBE' tab={tab} />
          <StatusInfiniteScroller event={event} status='CANT' tab={tab} />
          <StatusInfiniteScroller event={event} status='NONE' tab={tab} />
          <StatusInfiniteScroller event={event} status='REQUESTED' tab={tab} />
        </div>
      </FullScreenSheet>
    </div>
  )
}

export default GuestModal
