import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { SvgIcon, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from '../../selectors/event'
import BottomSheet from '../common/BottomSheet'
import ChevronRight from '@material-ui/icons/ChevronRight'
import QuestionAnswersModal from './QuestionAnswersModal'
import FlatButton from '../../shared-components/buttons/FlatButton'
import { mdiExportVariant } from '@mdi/js'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    minHeight: 400,
  },
  topContainer: {
    padding: theme.spacing(2),
    borderBottom: '1px solid ' + theme.palette.gray.main,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    // flexDirection: 'column-reverse'
  },
  scrollContainer: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
    minHeight: '300px',
  },
  bottomContainer: {
    background: 'white',
    padding: theme.spacing(1.5, 2),
    borderTop: '1px solid ' + theme.palette.gray.main,
  },
  bottomBorder: {
    borderBottom: '1px solid ' + theme.palette.gray.main,
  },
  responseCount: {
    marginTop: theme.spacing(1),
  },
  chevron: {
    alignSelf: 'center',
  },
  header: {
    // padding: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  exportButton: {
    marginTop: theme.spacing(2),
  },
  joinOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  joinOptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  questionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid ' + theme.palette.gray.main,
    borderRadius: 8,
  },
  questionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  questionTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
}))

const QuestionsModal = ({ open, onClose }) => {
  const classes = useStyles()
  const event = useSelector(getCurrentEvent)
  const { t } = useTranslation('common')
  const [selectedQuestionId, setSelectedQuestionId] = useState(null)
  const [answersOpen, setAnswersOpen] = useState(false)

  const onQuestionClicked = (question) => {
    setSelectedQuestionId(question.id)
    setAnswersOpen(true)
  }

  const questionText = (question) => {
    if (question == null) return ''
    if (question.type === 'EMAIL_ADDRESS') {
      return t('questions.emailAddress')
    } else if (question.type === 'PHONE_NUMBER') {
      return t('questions.phoneNumberQuestion')
    } else {
      return question.caption
    }
  }

  const onExport = async () => {
    const link = document.createElement('a')
    link.download = `event_responses_${event.id}.csv`
    link.href = `${process.env.NEXT_PUBLIC_API_HOST}/event-forms/responses/events/${event.id}/export`
    link.click()
  }

  return (
    <BottomSheet
      className={classes.root}
      open={open}
      onClose={onClose}
      title={t('questions.answers')}
    >
      {/*<div className={classes.topContainer}>*/}
      {/*  <div className={classes.titleContainer}>*/}
      {/*    <Close onClick={() => onClose()} className={classes.closeIcon} />*/}
      {/*    <Typography variant='h6'>{t('questions.answers')}</Typography>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div id='scrollableDiv' className={classes.scrollContainer}>
        <div className={classes.header}>
          <Typography variant='body2' color='textSecondary'>
            {t('questions.headerText')}
          </Typography>

          <FlatButton
            className={classes.exportButton}
            color='grey'
            onClick={onExport}
            startIcon={
              <SvgIcon className={classes.exportIcon}>
                <path d={mdiExportVariant} />
              </SvgIcon>
            }
          >
            {t('questions.export')}
          </FlatButton>
        </div>

        <div className={classes.joinOptionsContainer}>
        {event.joinOptions.map(jo => {
          if (jo.questions.length === 0) return null
          return (
          <div className={classes.joinOptionContainer}>
            <Typography variant='body2'><b>{jo.name}</b></Typography>
            <div className={classes.questionsContainer}>
            {jo.questions.map((question, index) => {
              return (
                <div
                  className={clsx(classes.questionContainer, index !== jo.questions.length - 1 && classes.bottomBorder)}
                  onClick={() => onQuestionClicked(question)}
                >
                  <div className={classes.questionTextContainer}>
                    <Typography variant='subtitle1'>
                      {questionText(question)}
                    </Typography>
                    <Typography
                      className={classes.responseCount}
                      variant='body2'
                      color='textSecondary'
                    >
                      {question.responseCount === 1
                        ? t('questions.response1')
                        : t('questions.responseCount', {
                            count: question.responseCount,
                          })}
                    </Typography>
                  </div>
                  <ChevronRight className={classes.chevron} />
                </div>
              )
            })}
            </div>
          </div>
          )
        })}
        </div>
      </div>
      <QuestionAnswersModal
        open={answersOpen}
        onClose={() => setAnswersOpen(false)}
        questionId={selectedQuestionId}
      />
    </BottomSheet>
  )
}

export default QuestionsModal
