import { makeStyles, Tab, Tabs } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React, { useState } from 'react'
import { formatShortNumber } from '../../../utils/eventFunctions'
import GuestManagementScroller from './GuestManagementScroller'

const useStyles = makeStyles((theme) => ({
  root: {},
  tab: {
    minWidth: 120,
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.gray[200],
  },
  indicator: {
    backgroundColor: theme.palette.text.primary,
  },
}))

const GuestManagementRequested = ({ sortType }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const [tab, setTab] = useState('REQUESTED')

  return (
    <div className={classes.root}>
      <Tabs
        variant='fullWidth'
        value={tab}
        classes={{
          indicator: classes.indicator,
        }}
        onChange={(e, newTab) => setTab(newTab)}
        aria-label='attendees-tabs'
      >
        <Tab
          key='REQUESTED'
          value='REQUESTED'
          className={classes.tab}
          label={`${t(
            'shared:rsvp.requested',
          )} (${formatShortNumber(event.statusCounts.requested)})`}
        />
        <Tab
          key='REQUEST_DENIED'
          value='REQUEST_DENIED'
          className={classes.tab}
          label={`${t(
            'shared:rsvp.requestDenied',
          )} (${formatShortNumber(event.statusCounts.requestDenied)})`}
        />
      </Tabs>
      <div className={classes.divider} />

      <GuestManagementScroller
        sortType={sortType}
        event={event}
        status={'REQUESTED'}
        tab={tab}
      />
      <GuestManagementScroller
        sortType={sortType}
        event={event}
        status={'REQUEST_DENIED'}
        tab={tab}
      />
    </div>
  )
}

export default GuestManagementRequested
