import React from 'react'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import classNames from 'clsx'

const styles = theme => ({
  root: {
    borderRadius: 1000,
    backgroundColor: 'rgba(0, 0, 0, .5)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(50, 50, 50, .5)'
    }
  }
})

const HalfTransparentButton = ({ classes, className, children, ...other }) => {
  return (
    <Button variant='contained' className={classNames(classes.root, className)} disableElevation {...other}>
      {children}
    </Button>
  )
}

export default withStyles(styles)(HalfTransparentButton)
