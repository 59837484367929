import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '6px 8px',
    letterSpacing: '0.015rem',
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    // textTransform: 'uppercase',
    fontFamily: '-apple-system, "BlinkMacSystemFont", "Roboto", "Arial", sans-serif',
    fontWeight: 400,
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    // width: 'min-content',
    '& > svg': {
      width: 12,
      height: 12,
      marginRight: 6
    }
  },
  bold: {
    fontWeight: 700
  },
  clickAble: {
    cursor: 'pointer'
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main
  },
  secondary: {
    backgroundColor: theme.palette.secondary[200],
    color: theme.palette.secondary[800]
  },
  tertiary: {
    backgroundColor: theme.palette.red[10],
    color: theme.palette.red[95]
  }
}))

const TextBadge = ({ className, children, color = 'secondary', bold = true, ...props }) => {
  const classes = useStyles()

  return (
    <span className={clsx(classes.root, bold && classes.bold, props.onClick && classes.clickAble, className, classes[color])} {...props}>
      {children}
    </span>
  )
}

export default TextBadge
