import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
  updateRoot: {
    position: 'relative',
    display: 'flex',
    borderLeft: `1px solid ${theme.palette.grey[800]}`,
    padding: theme.spacing(1, 1.5),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  updateText: {
    fontWeight: '700',
    color: theme.palette.grey[800],
  },
  old: {
    textDecoration: 'line-through',
    color: theme.palette.grey[400],
  },
  new: {
    fontWeight: '500',
  },
  removeButton: {
    cursor: 'pointer',
    width: 16,
    height: 16,
    background: '#A6A6A6',
    borderRadius: 100,
    zIndex: 10,
    color: 'white',
    padding: 2,
  },
}))

const PostEventUpdates = ({ post, onRemove }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  const getText = (update) => {
    switch (update.type) {
      case 'DESCRIPTION':
        return `${t('eventLogUpdate.EVENT_UPDATE_DESCRIPTION')}`
      case 'LOCATION':
        return `${t('eventLogUpdate.EVENT_UPDATE_LOCATION')}:`
      case 'DATE_ANNOUNCED':
        return `${t('eventLogUpdate.EVENT_UPDATE_DATE_ANNOUNCED')}:`
      case 'START_DATE':
        return `${t('eventLogUpdate.EVENT_UPDATE_START_DATE')}:`
      case 'DATE_OPTIONS':
        return `${t('eventLogUpdate.EVENT_UPDATE_DATE_OPTIONS')}`
      case 'PINNED_DATE':
        return `${t('eventLogUpdate.EVENT_DATE_PINNED')}`
      case 'CANCELLED':
        return `${t('eventLogUpdate.EVENT_CANCELED')}`
      case 'CREATED':
        return `${t('eventLogUpdate.EVENT_CREATED')}`
      case 'STARTED':
        return `${t('eventLogUpdate.EVENT_STARTED')}`
      case 'REMINDER':
        return `${t('eventLogUpdate.EVENT_REMINDER')}`
      case 'TICKET_LINK_ADDED':
        return `${t('eventLogUpdate.TICKET_LINK_ADDED')}`
      case 'UPDATE_QUESTIONNAIRE':
        return `${t('eventLogUpdate.EVENT_UPDATE_QUESTIONNAIRE')}`
      case 'PUBLISHED':
        return `${t('eventLogUpdate.PUBLISHED')}`
      default:
        return null
    }
  }

  const getOld = (update) => {
    switch (update.type) {
      case 'LOCATION':
        return `${update.data.old.location}`
      case 'START_DATE':
        return `${dayjs(update.data.oldDate).format('ddd D MMM HH:mm')}`
      default:
        return null
    }
  }

  const getNew = (update) => {
    switch (update.type) {
      case 'LOCATION':
        return `${update.data.new.location}`
      case 'DATE_ANNOUNCED':
        return `${dayjs(update.data.newDate).format('ddd D MMM HH:mm')}`
      case 'START_DATE':
        return `${dayjs(update.data.newDate).format('ddd D MMM HH:mm')}`
      default:
        return null
    }
  }

  if (!post.eventUpdates || post.eventUpdates.length === 0) return null

  return (
    <div className={classes.root}>
      {post.eventUpdates.map((update, idx) => {
        if (!getText(update)) return null
        return (
          <div key={idx} className={classes.updateRoot}>
            <Typography variant='overline' className={classes.updateText}>
              {getText(update)}
              {getOld(update) && (
                <>
                  {' '}
                  <span className={classes.old}>{getOld(update)}</span>
                </>
              )}
              {getNew(update) && (
                <>
                  {' '}
                  <span className={classes.new}>{getNew(update)}</span>
                </>
              )}
            </Typography>
            {onRemove && (
              <Close
                className={classes.removeButton}
                onClick={() => {
                  onRemove(update.id)
                }}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default PostEventUpdates
