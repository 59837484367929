import React from 'react'

import { Backdrop, Button, Dialog, makeStyles, Slide } from '@material-ui/core'
import cn from 'clsx'
import Typography from '@material-ui/core/Typography'
import useTheme from '@material-ui/core/styles/useTheme'
import FullScreenSheetTransition from '../modals/FullScreenSheetTransition'
import { ChevronLeft, Close } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3, 3, 3, 3),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  paper: {
    transition: 'all 200ms',
    maxHeight: '90vh',
    minWidth: theme.breakpoints.values.container,
    border: theme.palette.background.modalBorder,
    [theme.breakpoints.down(theme.breakpoints.values.container + 1)]: {
      position: 'fixed',
      bottom: 0,
      maxWidth: '100%',
      minWidth: '100%',
      margin: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      paddingBottom: 'env(safe-area-inset-bottom)',
    },
  },
  paperFullScreen: {
    height: '100vh',
    maxHeight: 'none',
  },
  backdropRoot: {
    backgroundColor: theme.palette.background['backdrop'],
    // transition: 'none !important'
  },
  titleContainer: {
    // borderBottom: '1px solid '+ theme.palette.grey[200],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    width: '100%',
    minHeight: 56,
  },
  title: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(0, 5),
  },
  closeButton: {
    minHeight: 0,
    minWidth: 0,
    width: 40,
    height: 40,
    borderRadius: 100,
    position: 'absolute',
    left: theme.spacing(1),
    top: 8,
    // top: '50%',
    // transform: 'translateY(-50%)',
  },
  topRight: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
  },
}))

/**
 * Prevents scrolling of content behind the backdrop.
 */
export class BackDropIOSWorkaround extends React.PureComponent {
  onTouchMove(event) {
    event.preventDefault()
  }

  render() {
    return <Backdrop {...this.props} onTouchMove={this.onTouchMove} />
  }
}

export const SlideTransition = React.forwardRef(
  function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
  },
)

const BottomSheet = ({
  title,
  children,
  open,
  onClose,
  onBack,
  disableBackdropClick,
  className,
  fullScreen,
  topRightContent,
  contentClassName,
  maxWidth,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const onCloseOverride = (e, reason) => {
    if (reason === 'backdropClick' && disableBackdropClick) {
      return
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog
      classes={{
        paper: cn(
          classes.paper,
          className,
          fullScreen && classes.paperFullScreen,
        ),
      }}
      BackdropProps={{
        classes: {
          root: classes.backdropRoot,
        },
      }}
      BackdropComponent={BackDropIOSWorkaround}
      open={open ?? false}
      onClose={onCloseOverride}
      TransitionComponent={FullScreenSheetTransition}
      transitionDuration={150}
      maxWidth={maxWidth}
    >
      {title && (
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant='h6'>
            {title}
          </Typography>
          {onClose && (
            <Button className={classes.closeButton} onClick={onClose}>
              <Close />
            </Button>
          )}
          {onBack && (
            <Button className={classes.closeButton} onClick={onBack}>
              <ChevronLeft />
            </Button>
          )}
          {topRightContent && (
            <div className={classes.topRight}>{topRightContent}</div>
          )}
        </div>
      )}
      <div className={cn(classes.content, contentClassName)}>{children}</div>
    </Dialog>
  )
}

export default BottomSheet
